import { StyleAttributeModel } from "../schema/style-attribute-model";

export class GlobalConstant {
    public static readonly CONTAINER_CLASS_EDITION_VIEW = 'cbox-container';
    public static readonly ATTRIBUTE_ID = "id";
    public static readonly ATTRIBUTE_VALUE = "value";
    public static readonly ATTRIBUTE_CLASS = 'class';
    public static readonly ATTRIBUTE_EMBEDDED_IN = "data-embedded-in";
    public static readonly ATTRIBUTE_IS_VALID = "data-is-valid";
    public static readonly ATTRIBUTE_DATA_TYPE = "data-type";
    public static readonly ATTRIBUTE_SRC = "src";
    public static readonly ATTRIBUTE_DISABLED = "disabled";
    public static readonly ANCHOR = "a";
    public static readonly PARAGRAPH = "p";
    public static readonly LABEL_DIV = "div";
    public static readonly LABEL_SPAN = "span";
    public static readonly LABEL_CITE = 'cite';
    public static readonly LABEL_ANCHOR = 'a';
    public static readonly LABEL_DEFINITION_ELEMENT = 'dfn';
    public static readonly MODEL_PARAGRAPH = 'paragraph';
    public static readonly DESCRIPTION = 'description';
    public static readonly AN = 'AN';
    public static readonly SPAN = 'span';
    public static readonly IMG = 'img';
    public static readonly ADD = 'add';
    public static readonly DELETE = 'delete';
    public static readonly INNER_TEXT = 'innerText';
    public static readonly ELEMENT = 'element';
    public static readonly INPUT = 'input';

    public static readonly UNDEFINED = 'undefined';
    public static readonly PHONE = 'phone';
    public static readonly EMAIL = 'email';
    public static readonly DNI = 'DNI';
    public static readonly NIE = 'NIE';
    public static readonly NIF = 'NIF';
    public static readonly IBAN = 'IBAN';
    public static readonly UPPERCASE = 'uppercase';
    public static readonly LOWERCASE = 'lowercase';
    public static readonly CAMEL = 'camel';
    public static readonly INSERT = 'insert';

    public static readonly CLAUSE = 'clause';
    public static readonly TEMPLATE = 'template';
    public static readonly DOCUMENT = 'document';

    public static readonly STYLE_ATTRIBUTES: StyleAttributeModel[] = [
        { model: 'bold',                dataView:'font-weight: bold; ',             editionView: 'strong'},
        { model: 'italic',              dataView:'font-style: italic; ',            editionView: 'i'},
        { model: 'underline',           dataView:'text-decoration: underline; ',    editionView: 'u'},
        { model: 'strikethrough',       dataView:'text-decoration: line-through; ', editionView: 's'},
        { model: 'code',                dataView:'',                                editionView: 'code'},
        { model: 'subscript',           dataView:'',                                editionView: 'sub'},
        { model: 'superscript',         dataView:'',                                editionView: 'sup'},
        { model: 'fontSize',            dataView:'font-size: {0}; ',                editionView: 'font-size: {0};',         useValue: true},
        { model: 'fontFamily',          dataView:'font-family: {0}; ',              editionView: 'font-family: {0}; ',      useValue: true},
        { model: 'fontColor',           dataView:'color: {0}; ',                    editionView: 'color: {0}; ',            useValue: true},
        { model: 'fontBackgroundColor', dataView:'background-color: {0}; ',         editionView: 'background-color: {0}; ', useValue: true},
    ];
}
