import { Injectable } from "@angular/core";
import { Schema } from "ckeditor5";
import { SignaturePlugin } from "../../plugins/signature/signature-plugin";
import { INHERIT_ALL_FROM_VALUES } from "../schema-values";
import { GlobalConstant } from "../../models/base/global-constant";

@Injectable({
    providedIn: 'root'
})
export class SignatureSchemaService {

    public defineSchema(schema: Schema): void {
        schema.register(SignaturePlugin.MODEL_ENTITIES.class.dataView, {
            inheritAllFrom: INHERIT_ALL_FROM_VALUES.BLOCK_OBJECT,
            allowAttributes: [
                GlobalConstant.ATTRIBUTE_ID,
                SignaturePlugin.MODEL_ENTITIES.attributeRoleIsStored.model,
                GlobalConstant.ATTRIBUTE_EMBEDDED_IN,
            ],
        });
    }
}
