<div class="revision">
    <div class="revision-header">
        <div class="revision-title" i18n="@@ClausulaDocumentoDescripcionTitulo">Indica en qué plantillas y documentos deseas aplicar los cambios y en cuáles mantener la cláusula original</div>
        <div class="revision-data">
            <span class="revision-data-number">{{selection.selected.length}} </span>
            <span class="revision-data-label" i18n="@@DocumentosSeleccionados"> documentos seleccionados</span>
        </div>
    </div>
    <div class="revision-body">
        <table *ngIf="dataSource && dataSource.data.length !== 0" mat-table [dataSource]="dataSource" matSort matSortActive="modificationTimeStamp" matSortDirection="desc" class="table-list">
            <caption i18n="@@DocumenosPendientesTituloPlantillasDocuments">Documentos pendientes</caption>
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="rowHeaderSelectedChange($event)" [checked]="isCheckHeaderChecked()" [indeterminate]="isCheckHeaderIndeterminate()"></mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let element">
                    <mat-checkbox *ngIf="canAcceptChange(element)" (click)="$event.stopPropagation()" (change)="rowSelectedChange($event, element)" [checked]="isRowChecked(element)"></mat-checkbox>
                </td>
            </ng-container>
            <ng-container matColumnDef="icon">
                <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@PlantillaDocumento">Plantilla/Documento</th>
                <td mat-cell *matCellDef="let element">
                    <div class="revision-template" *ngIf="element.document?.documentTypes.code === documentTypeCodes.Template" i18n="@@IconoPlantilla">Plantilla</div>
                    <div class="revision-document" *ngIf="element.document?.documentTypes.code !== documentTypeCodes.Template"  i18n="@@IconoDocumento">Documento</div>
                </td>
            </ng-container>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@ClausulaDocumentoNombreTitulo">Nombre del contrato</th>
                <td mat-cell *matCellDef="let element">
                    <div class="mat-cell-flex">
                        <div class="name">{{element.document.name}}</div>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="modificationTimeStamp">
                <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@ClausulaDocumentoFechaModificacionTitulo">Modificación</th>
                <td mat-cell *matCellDef="let element">{{element.document.modificationTimeStamp | dateFormat}}</td>
            </ng-container>
            <ng-container matColumnDef="lastModificationUser">
                <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@ClausulaDocumentoAutorTitulo">Autor</th>
                <td mat-cell *matCellDef="let element">
                    <ctbox-help *ngIf="element.document.lastModificationUser !== undefined && element.document.lastModificationUser !== null">
                        <ng-container ngProjectAs="titleHelp">
                            {{element.document.lastModificationUser.surname + ', ' + element.document.lastModificationUser.name }}
                        </ng-container>
                        <ng-container ngProjectAs="bodyHelp">
                            <ctbox-help-body [lastModificationUser]="element.document.lastModificationUser.surname + ', ' + element.document.lastModificationUser.name" [creationUser]="element.document.creationUser.surname + ', ' + element.document.creationUser.name"></ctbox-help-body>
                        </ng-container>
                    </ctbox-help>
                </td>
            </ng-container>
            <ng-container matColumnDef="documentStatus">
                <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@Estado">Estado</th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element?.document?.documentTypes?.code === this.documentTypeCodes.Contract">
                        <app-document-state [documentState]="element?.documentStatuses?.code" [modeChangeState]="false"></app-document-state>
                    </ng-container>
                </td>
            </ng-container>
            <ng-container matColumnDef="actionsColumn">
                <th mat-header-cell *matHeaderCellDef i18n="@@ClausulaDocumentoAccionesTitulo">Acciones</th>
                <td mat-cell *matCellDef="let element">
                    <div class="revision-action-column">
                        <ng-container *ngIf="isPending(element)">
                            <ng-container *ngIf="canAcceptChange(element); then thenBlock else elseBlock"></ng-container>
                            <ng-template #thenBlock>
                                <ng-container *ngTemplateOutlet="buttonPreview"></ng-container>
                                <ng-container *ngTemplateOutlet="buttonBlock"></ng-container>
                            </ng-template>
                            <ng-template #elseBlock>
                                <ng-container *ngTemplateOutlet="buttonPreview"></ng-container>
                                <ctbox-help>
                                    <ng-container ngProjectAs="titleHelp">
                                        <ng-container *ngTemplateOutlet="buttonBlock"></ng-container>
                                    </ng-container>
                                    <ng-container ngProjectAs="bodyHelp">
                                        <ctbox-help-body>
                                            <ctbox-notification [state]="notificationState.Forbid" [additionalClass]="'dark'" [content]="forbidEditMessage"></ctbox-notification>
                                        </ctbox-help-body>
                                    </ng-container>
                                </ctbox-help>
                            </ng-template>
                            <ng-template #buttonPreview>
                                <ctbox-button buttonIcon="visibility" buttonColor="info" (buttonClick)="goToPreviewChanges(element)" matTooltip="Visualizar" i18n-matTooltip="@@Visualizar"></ctbox-button>
                            </ng-template>
                            <ng-template #buttonBlock>
                                <div class="button-block">
                                    <ctbox-button buttonType="secondary" buttonText="Mantener original" i18n-buttonText="@@MantenerOriginalClausulaDocumento" [isDisabled]="(isAnySelected() || (!isAnySelected() && !canAcceptChange(element)))" (buttonClick)="rejectChanges(element)"></ctbox-button>
                                    <ctbox-button buttonType="primary" buttonText="Aplicar cambios" i18n-buttonText="@@AplicarCambiosClausulaDocumento" [isDisabled]="(isAnySelected() || (!isAnySelected() && !canAcceptChange(element)))" (buttonClick)="acceptChanges(element)"></ctbox-button>
                                </div>
                            </ng-template>
                        </ng-container>
                        <ng-container *ngIf="isApplied(element) || isKeep(element)">
                            <ctbox-button buttonIcon="visibility" buttonColor="info" customClass="filled" (buttonClick)="goToPreviewChanges(element)" matTooltip="Visualizar" i18n-matTooltip="@@Visualizar"></ctbox-button>
                            <ng-container *ngIf="isKeep(element)">
                                <ctbox-button buttonType="text" buttonIcon="sync_alt" customClass="text-default cursor-default" buttonText="Mantener original" i18n-buttonText="@@MantenerOriginalClausulaDocumento"></ctbox-button>
                            </ng-container>
                            <ng-container *ngIf="isApplied(element)">
                                <ctbox-button buttonType="text" buttonIcon="sync_alt" customClass="text-default cursor-default" buttonText="Aplicar cambios" i18n-buttonText="@@AplicarCambiosClausulaDocumento"></ctbox-button>
                            </ng-container>
                        </ng-container>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"> </tr>
        </table>
    </div>
    <div class="revision-footer">
        <div class="revision-data">
            <span class="revision-data-number">{{pendingDocuments}}/{{totalDocuments}}</span>
            <span class="revision-data-label" i18n="@@DocumentosPendientesListadoPlantillasDocumentos">documentos pendientes</span>
        </div>
        <div class="revision-actions">
            <ctbox-button buttonType="secondary" buttonText="Mantener original" i18n-buttonText="@@MantenerOriginalClausulaDocumento" [isDisabled]="!isAnySelected()" (buttonClick)="rejectAllSelectedChanges()"></ctbox-button>
            <ctbox-button buttonType="primary" buttonColor="accent" buttonText="Aplicar cambios" i18n-buttonText="@@AplicarCambiosClausulaDocumento" [isDisabled]="!isAnySelected()" (buttonClick)="openConfirmationMultipleUpdates()"></ctbox-button>
        </div>
    </div>
</div>
