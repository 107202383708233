import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'ctbox-double-icon',
    templateUrl: './ctbox-double-icon.component.html',
    styleUrls: ['./ctbox-double-icon.component.scss']
})
export class CtboxDoubleIconComponent implements OnInit {

    @Input() doubleIconHeader: boolean;
    @Input() doubleIconType: string;
    @Input() isFirstIcon: boolean;
    @Input() isSecondIcon: boolean;
    @Input() isUserAdmin = true;
    @Input() doubleIconConfiguration = [];

    public iconName: string;
    public iconClass: string;
    public iconTooltip: string;

    public iconHeaderTooltip: string;

    public firstIconName: string;
    public firstIconClass: string;
    public firstIconTooltip: string;

    public secondIconName: string;
    public secondIconClass: string;
    public secondIconTooltip: string;

    public shareIconName: string;
    public shareIconClass: string;
    public shareIconTooltip: string;

    public ngOnInit(): void {

        this.doubleIconConfiguration = [
            {
                id: 'inforce',
                firstIconName: 'hourglass_top',
                firstIconClass: 'true xxs',
                firstIconTooltip: $localize`:@@InForceDocument:Documento vigente`,
                secondIconClass: 'false xxs',
                secondIconName: 'hourglass_bottom',
                secondIconTooltip: $localize`:@@NotInForceDocument:Documento No vigente`,
            }, {
                id: 'owner',
                firstIconName: 'user',
                firstIconClass: 'warning',
                firstIconTooltip: $localize`:@@IconoUsuario:Creado por mí`,
                secondIconName: 'apartment',
                secondIconClass: 'info',
                secondIconTooltip: $localize`:@@DocProperty:Documento de mi corporación`,
                shareIconName: 'share',
                shareIconClass: 'info',
                shareIconTooltip: $localize`:@@CompartidosConmigo:Compartidos conmigo`,
            }, {
                id: 'additional',
                firstIconName: 'copy',
                firstIconClass: 'secondary',
                firstIconTooltip: $localize`:@@DocumentoPrincipal:Documento principal`,
                secondIconName: 'paperclip-vertical',
                secondIconClass: 'secondary',
                secondIconTooltip: $localize`:@@DocumentoAdicional:Documento adicional`,
            },
        ];

        const doubleIconSelected = this.doubleIconConfiguration.find(icon => icon.id === this.doubleIconType);

        this.setDoubleIcon(doubleIconSelected);

    }

    public setDoubleIcon(doubleIcon): void {

        this.firstIconName = doubleIcon.firstIconName;
        this.firstIconClass = doubleIcon.firstIconClass;
        this.firstIconTooltip = doubleIcon.firstIconTooltip;

        this.secondIconName = doubleIcon.secondIconName;
        this.secondIconClass = doubleIcon.secondIconClass;
        this.secondIconTooltip = doubleIcon.secondIconTooltip;

        if (this.isFirstIcon) {
            this.iconName = this.firstIconName;
            this.iconClass = this.firstIconClass;
            this.iconTooltip = this.firstIconTooltip;

        } else if (this.isSecondIcon) {

            if (this.isUserAdmin) {
                this.iconName = this.secondIconName;
                this.iconClass = this.secondIconClass;
                this.iconTooltip = this.secondIconTooltip;
            } else {
                this.iconName = doubleIcon.shareIconName;
                this.iconClass = doubleIcon.shareIconClass;
                this.iconTooltip = doubleIcon.sharedIconTooltip;
            }
        }

        this.iconHeaderTooltip = this.firstIconTooltip + '/' + this.secondIconTooltip;

    }

}
