<div class="c-help">
    <div class="c-help-header">
        <h3 class="c-help-header-title c-help-documents">Todos</h3>
        <p class="c-help-header-text">En Todos tienes una vista global de los documentos creados por ti <mat-icon class="inline warning">person</mat-icon> y los creados por usuarios de tu corporación <mat-icon class="inline info">apartment</mat-icon></p>
    </div>
    <div class="c-help-content">
        <mat-tab-group mat-align-tabs="start" class="c-help documents">
            <mat-tab>
                <ng-template mat-tab-label>Acciones a realizar</ng-template>
                <div class="c-help-tab-body">
                    <div class="c-help-block">
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonType="secondary" buttonIcon="circle" buttonColor="state" customClass="filled no-border review xsmall"></ctbox-button></div>
                                <p><strong>Cambiar estado:</strong> Para cambiar el estado de los documentos creados por ti.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonIconSvg="editable" buttonColor="info"></ctbox-button></div>
                                <p><strong>Editar:</strong> Para editar el contenido de un documento.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonIcon="share" buttonColor="info"></ctbox-button></div>
                                <p><strong>Compartir:</strong> Permite compartir documentos que se quieran revisar o negociar con el control de cambios y podrán realizar comentarios.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonIconSvg="signature" buttonColor="info"></ctbox-button></div>
                                <p><strong>Enviar a firma:</strong> Permite enviar a firmar los documentos o comprobar los datos del envío actual.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonIcon="delete" buttonColor="delete"></ctbox-button></div>
                                <p><strong>Borrar:</strong> Para eliminar documentos.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonIcon="download" buttonColor="info"></ctbox-button></div>
                                <p><strong>Descargar:</strong> Para descargar documentos.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>Organización de documentos</ng-template>
                <div class="c-help-tab-body">
                    <div class="c-help-block">
                        <h4 class="c-help-subtitle">Estructura de carpetas en el apartado Mis documentos</h4>
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><app-tree-contextual-help module="documents"></app-tree-contextual-help></div>
                                <ul class="c-help-list">
                                    <li>
                                        <p><ctbox-button buttonIconSvg="person" buttonColor="info"></ctbox-button> Nuestros documentos</p>
                                        <p>Éste es tu arbol de carpetas personal. Tanto las carpetas como los documentos sólo son visibles para ti. Si quieres que el resto de la compañía vea tus documentos muévelos a "Nuestros documentos".</p>
                                    </li>
                                    <li>
                                        <p><ctbox-button buttonIcon="supervisor_account" buttonColor="info"></ctbox-button> Nuestros documentos</p>
                                        <p>Árbol de carpetas de la compañía. Estos socumentos serán visibles solo para los usuarios que tengan permisos de acceso en estas carpetas.</p>
                                    </li>
                                    <li>
                                        <p><ctbox-button buttonIcon="share" buttonColor="info"></ctbox-button> Compartidos</p>
                                        <p><strong class="c-help-documents">Compartidos por mí:</strong> Documentos compartidos por mí con otras personas de la corporación o con personas externas.</p>
                                        <p><strong class="c-help-documents">Compartidos conmigo:</strong> Documentos compartidos conmigo por otras personas de la corporación o por personas ajenas a la misma.</p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div class="c-help-block">
                        <h4 class="c-help-subtitle">Acciones a realizar con carpetas</h4>
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img">
                                    <ctbox-button buttonIcon="more_vert" buttonColor="secondary"></ctbox-button>
                                    <div class="ctbox-elevation">
                                        <ctbox-button buttonType="mat-menu-item" buttonColor="secondary" buttonText="Nueva Carpeta"></ctbox-button>
                                        <ctbox-button buttonType="mat-menu-item" buttonColor="secondary" buttonText="Renombrar Carpeta"></ctbox-button>
                                        <ctbox-button buttonType="mat-menu-item" buttonColor="secondary" buttonText="Borrar Carpeta"></ctbox-button>
                                    </div>
                                </div>
                                <ul class="c-help-list">
                                    <li><strong>Crear una nueva carpeta:</strong> Permite crear una carpeta dentro de la carpeta seleccionada.</li>
                                    <li><strong>Renombrar una carpeta ya existente:</strong> Permite renombrar la carpeta seleccionada.</li>
                                    <li><strong>Borrar una carpeta ya existente: </strong> Eliminar permanentemente esta carpeta y todas las que contiene.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>Información de documentos</ng-template>
                <div class="c-help-tab-body">
                    <div class="c-help-block">
                        <h4 class="c-help-subtitle">1. Vista general de Mis documentos:</h4>
                        <p class="c-help-summary">En esta vista dispondremos de un listado de todos los documentos incluidos en la aplicación, con la información relativa a los mismos, organizada por columnas que permiten ordenar los documentos en relación a los siguientes parámetros:</p>
                    </div>
                    <div class="c-help-block">
                        <h4 class="c-help-subtitle">Creador del documento</h4>
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow"><mat-icon class="info">apartment</mat-icon> <strong>Mi organización</strong></li>
                            <li class="c-help-list-flexrow"><mat-icon class="warning">person</mat-icon> <strong>Creado por mí</strong></li>
                        </ul>
                    </div>
                    <div class="c-help-block">
                        <h4 class="c-help-subtitle">Tipo de documento</h4>
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow"><mat-icon class="secondary">content_copy</mat-icon> Documento principal: contrato o escrito legal al que acompaña un anexo, adenda etc…</li>
                            <li class="c-help-list-flexrow"><mat-icon class="secondary">attach_file</mat-icon> Documento adicional: anexos, adendas etc.. disponibles en la pestaña Documentos relacionados.</li>
                        </ul>
                    </div>
                    <div class="c-help-block">
                        <h4 class="c-help-subtitle">Nombre</h4>
                        <p>Nombre con el que se haya nombrado el fichero.</p>
                    </div>
                    <div class="c-help-block">
                        <h4 class="c-help-subtitle">Última Acción</h4>
                        <p>Fecha de la última interacción con el documento.</p>
                    </div>
                    <div class="c-help-block">
                        <h4 class="c-help-subtitle">Estados</h4>
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonType="secondary" buttonIcon="circle" buttonColor="state" customClass="filled no-border draft xsmall" buttonText="Borrador"></ctbox-button></div>
                                <p>Documentos en elaboración.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonType="secondary" buttonIcon="circle" buttonColor="state" customClass="filled no-border review xsmall" buttonText="Revisión"></ctbox-button></div>
                                <p>Documentos en negociación o revisión.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonType="secondary" buttonIcon="circle" buttonColor="state" customClass="filled no-border completed xsmall" buttonText="Completado"></ctbox-button></div>
                                <p>Documentos con redacción finalizada.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonType="secondary" buttonIcon="circle" buttonColor="state" customClass="filled no-border signing xsmall" buttonText="Enviados a firma"></ctbox-button></div>
                                <p>Documentos pendientes de firmar.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonType="secondary" buttonIcon="circle" buttonColor="state" customClass="filled no-border signed xsmall" buttonText="Firmado"></ctbox-button></div>
                                <p>Documentos firmados.</p>
                            </li>
                        </ul>
                    </div>
                    <div class="c-help-block">
                        <h4 class="c-help-subtitle">Alertas</h4>
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonIcon="warning" buttonColor="warning" customClass="filled warning"></ctbox-button></div>
                                <p><strong>Para consultar las alertas de un documento.</strong></p>
                            </li>
                        </ul>
                    </div>
                    <div class="c-help-block">
                        <h4 class="c-help-subtitle">2. Acciones a realizar en un documento:</h4>
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonIconSvg="editable" buttonColor="info"></ctbox-button></div>
                                <p><strong>Editar:</strong> Para editar el contenido de un documento.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonIcon="share" buttonColor="info"></ctbox-button></div>
                                <p><strong>Compartir:</strong> Permite compartir documentos que se quieran revisar o negociar con el control de cambios y podrán realizar comentarios.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonIconSvg="signature" buttonColor="info"></ctbox-button></div>
                                <p><strong>Enviar a firma:</strong> Permite enviar a firmar los documentos o comprobar los datos del envío actual.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonIcon="delete" buttonColor="delete"></ctbox-button></div>
                                <p><strong>Borrar:</strong> Para eliminar documentos.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonIcon="download" buttonColor="info"></ctbox-button></div>
                                <p><strong>Descargar:</strong> Para descargar documentos.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonType="secondary" buttonIcon="circle" buttonColor="state" customClass="filled no-border review xsmall"></ctbox-button></div>
                                <p><strong>Cambiar estado:</strong> Para cambiar el estado de los documentos creados por ti.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>Nuevo documento</ng-template>
                <div class="c-help-tab-body">
                    <div class="c-help-block">
                        <p class="c-help-summary"> En este módulo podrás crear un documento de ContractBOX aprovechando todas las ventajas que te ofrece la plataforma como son las plantillas y cláusulas y su exclusivo módulo de alertas Al DÍA y de gestión, negociación y revisión de documentos GESTIONA. </p>
                    </div>
                    <div class="c-help-block">
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img">
                                    <div class="ctbox-cards-group">
                                        <div class="ctbox-card"><a class="ctbox-card-link from-tdc documents help"></a></div>
                                    </div>
                                </div>
                                <p><strong>Partir de una plantilla:</strong> Utiliza una de las plantillas de tu corporación o alguna de las de laleydigital.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img">
                                    <div class="ctbox-cards-group">
                                        <div class="ctbox-card"><a class="ctbox-card-link from-file documents help"></a></div>
                                    </div>
                                </div>
                                <p><strong>Subir un documento:</strong> Sube tu propio documento.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img">
                                    <div class="ctbox-cards-group">
                                        <div class="ctbox-card"><a class="ctbox-card-link from-new documents help"></a></div>
                                    </div>
                                </div>
                                <p><strong>Redactar un documento:</strong> Empezarás con un documento en blanco.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
