import { Directive, OnDestroy } from "@angular/core";
import { ClickObserver, ContextualBalloon, Dialog, Editor, Node, View, ViewDocumentClickEvent, ViewElement, Widget } from "ckeditor5";
import {SignatureFormValidatorCallback} from "../../ui/signature/signature-balloon-form-view.directive";
import { SignatureModel } from "../../models/signature/signature-model";
import { SignatureUtilsService } from "../../utils/signature/signature-utils.service";
import SignatureBalloonFormView from "../../ui/signature/signature-balloon-form-view.directive";
import { BasePlugin } from "../base/base-plugin";
import { SignatureSchemaService } from "../../schema/signature/signature-schema.service";
import { UserInterfaceService } from "../../ui/user-interface.service";
import { PluginUtilsService } from "../../../utils/plugin-utils.service";
import { SignatureDataViewToModelConverterService } from "../../converters/signature/signature-data-view-to-model-converter.service";
import { SignatureModelToDataViewConverterService } from "../../converters/signature/signature-model-to-data-view-converter.service";
import { SignatureModelToEditorViewConverterService } from "../../converters/signature/signature-model-to-editor-view-converter.service";
import { ToolbarButtonModel } from "../../models/base/toolbar-button-model";
import { UI_CLASSES } from "../../ui/styles/styles-constants";
import { ContextEditorTypes } from "../../models/base/context-editor-types";
import SignatureBalloonView from "../../ui/signature/signature-balloon-view.directive";
import DeleteSignatureCommand from "../../commands/signature/delete-signature-command";
import { GlobalConstant } from "../../models/base/global-constant";
import EditSignatureCommand from "../../commands/signature/edit-signature-command";
import AddSignatureCommand from "../../commands/signature/add-signature-command";

@Directive({
    selector: "signature-plugin",
})
export class SignaturePlugin extends BasePlugin implements OnDestroy {

    public static readonly MODEL_ENTITIES = {
        'class'                    :   { model: "signature-in-editor",      dataView: "signature",                  editionView: "signature-in-editor"   },
        'role'                     :   { model: "Firmante ",                dataView: "Firmante ",                  editionView: "Firmante "   },
        'signatureSignPlacement'   :   { model: "",                         dataView: "signature-sign-placement",   editionView: "signature-sign-placement" },
        'signatureSignRol'         :   { model: "",                         dataView: "signature-sign-rol",         editionView: "signature-sign-rol" },
        'signatureContent'         :   { model: "",                         dataView: "signature-content",          editionView: "signature-content" },
        'attributeRoleIsStored'    :   { model: "signature-title",          dataView: "signature-title",            editionView: "signature-title" },
    };

    public static get pluginName(): string { return SignaturePlugin.PLUGIN_NAME; }
    public static get requires() { return [Widget, ContextualBalloon, Dialog]; }

    public static readonly PLUGIN_NAME: string = "Signature";
    public static readonly TOOLBAR_TOOLTIP = $localize`:@@InsertarFirmaEtiquetaBotonBotonera:Inserta hueco de firma`;
    public static readonly SIGNATURE_PREFIX = "signature_";
    public static readonly ENABLE_DISABLE_LOCKID = 'c97e6413-3869-4aaa-9bce-658817a9acbe';
    public static readonly EDIT_SIGNATURE_COMMAND = 'edit-signature';


    public static get pluginToolbarElementName(): string { return SignaturePlugin.MODEL_ENTITIES.class.dataView; }

    private readonly ADD_SIGNATURE_COMMAND_NAME = 'add-signature';
    private readonly EDIT_SIGNATURE_COMMAND_NAME = SignaturePlugin.EDIT_SIGNATURE_COMMAND;
    private readonly DELETE_SIGNATURE_COMMAND_NAME = 'delete-signature';
    private readonly TOOLBAR_NAME_STRING = "Firma";

    protected commands = {
        [this.ADD_SIGNATURE_COMMAND_NAME]: AddSignatureCommand,
        [this.EDIT_SIGNATURE_COMMAND_NAME]: EditSignatureCommand,
        [this.DELETE_SIGNATURE_COMMAND_NAME]: DeleteSignatureCommand
    };

    protected mappers  = [
        SignaturePlugin.MODEL_ENTITIES.class.editionView
    ];

    protected toolbarButton: ToolbarButtonModel = {
        icon: UI_CLASSES.SVG_ICONS.SIGNATURE,
        pluginToolbarElementName: SignaturePlugin.pluginToolbarElementName,
        buttonText: this.TOOLBAR_NAME_STRING,
        tooltip: SignaturePlugin.TOOLBAR_TOOLTIP,
        hasTooltip: true,
        hasText: true
    };

    protected pluginUtils: PluginUtilsService;

    private readonly selectRolMessage = $localize`:@@PluginFirmasTituloModal:Selecciona el rol del firmante`;
    private readonly emptyRoleMessage = $localize`:@@PluginFirmasValidacionRolVacioMensaje:El firmante no puede estar vacío`;
    private readonly roleRepeatedMessage = $localize`:@@PluginFirmasValidacionRolenUsoMensaje:Este firmante ya está en el documento. Introduce otro.`;
    private readonly ID_SIGNATURE_PREFIX = 'ck-signature-id-';

    private formView: SignatureBalloonFormView;
    private signatureBalloonFormView: SignatureBalloonView;

    private utilsService: SignatureUtilsService;
    private userInterfaceService: UserInterfaceService;
    private signatureUtilsService: SignatureUtilsService;

    private signatureSchemaService: SignatureSchemaService;
    private signatureDataViewToModelConverterService: SignatureDataViewToModelConverterService;
    private signatureModelToDataViewConverterService: SignatureModelToDataViewConverterService;
    private signatureModelToEditorViewConverterService: SignatureModelToEditorViewConverterService;

    constructor(editor: Editor) {
        super(editor);
        this.utilsService = new SignatureUtilsService();
        this.pluginUtils = new PluginUtilsService();
        this.signatureSchemaService = new SignatureSchemaService();
        this.userInterfaceService = new UserInterfaceService();
        this.signatureDataViewToModelConverterService = new SignatureDataViewToModelConverterService();
        this.signatureModelToDataViewConverterService = new SignatureModelToDataViewConverterService();
        this.signatureModelToEditorViewConverterService = new SignatureModelToEditorViewConverterService();
        this.signatureUtilsService = new SignatureUtilsService();
    }

    public afterInit() {
        if(this.editor.plugins.has('RestrictedEditingModeEditing' )) {
            this.editor.plugins.get( 'RestrictedEditingModeEditing' ).enableCommand( this.EDIT_SIGNATURE_COMMAND_NAME);
        }
    }

    protected override toolbarExecuteOperation(): void {
        this.openSignatureDialog();
    }

    protected override getPluginDataViewClass(): string {
        return SignaturePlugin.MODEL_ENTITIES.class.editionView;
    }

    protected override processElementsPaste(elements: Node[]): void {
        const view = this.editor.editing.view;
        const roleCounters: { [role: string]: number } = {};

        view.change(writer => {
            elements.forEach(element => {
                if (element instanceof ViewElement && this.signatureUtilsService.isSignature(element)) {
                    const newId = this.pluginUtils.generateId(this.ID_SIGNATURE_PREFIX);
                    writer.setAttribute(GlobalConstant.ATTRIBUTE_ID, newId, element);

                    const signatureTitle = (element as ViewElement).getAttribute(SignaturePlugin.MODEL_ENTITIES.attributeRoleIsStored.model);
                    const role = signatureTitle ? signatureTitle.split(' ')[0] : '';
                    roleCounters[role] = (roleCounters[role] || 0) + 1;
                    const roleValue = role + ' ' + (this.utilsService.getMaxRoleNumber(this.editor, signatureTitle.trim()) + roleCounters[role]).toString();

                    writer.setAttribute(SignaturePlugin.MODEL_ENTITIES.attributeRoleIsStored.editionView, roleValue, element);
                }
            });
        });
    }

    protected defineSchema(): void {
        this.signatureSchemaService.defineSchema(this.schema);
    }

    protected editorInteractions(): void {
        super.setupEditorObserver(ClickObserver);
        this.enableBalloonActivators();
        this.editor.plugins.get('ClipboardPipeline').on('inputTransformation', this.handlePasteEvent.bind(this), { priority: 'high' });
        this.disableToolbarButton(this.ADD_SIGNATURE_COMMAND_NAME);
    }

    protected defineConverters(): void {
        this.signatureDataViewToModelConverterService.configureConverters(this.editor);
        this.signatureModelToDataViewConverterService.configureConverters(this.editor);
        this.signatureModelToEditorViewConverterService.configureConverters(this.editor);
    }

    private enableBalloonActivators(): void {
        const viewDocument = this.editor.editing.view.document;
        this.listenTo<ViewDocumentClickEvent>(viewDocument, 'click', this.handleClickEvent.bind(this), {priority: 'high'});
    }

    private handleClickEvent(): void {
        const parentSignature = this.utilsService.getSelectedSignatureElement(this.editor);

        if (!parentSignature) {
            return;
        }

        if (!this.pluginUtils.isViewElementInteractableInTargetEditor(parentSignature, SignaturePlugin.contextEditor, ContextEditorTypes.CLAUSE)) {
            return;
        }

        const role = this.utilsService.getRole(parentSignature);
        const signatureId = this.utilsService.getSignatureId(parentSignature);

        if (BasePlugin.contextEditor === "document") {
            this.showEditSignatureDialog(signatureId, role);
        } else {
            this.showSignatureBalloon(signatureId, role);
        }
    }

    private showSignatureBalloon(signatureId: string, role: string): void {

        const signatureElement = this.utilsService.getSelectedSignatureElement(this.editor);

        if (!signatureElement) {
            this.showFakeVisualSelection();
        }

        this.addSignatureBallonFormView(signatureId, role);

        this.startUpdatingUI();
    }

    private showFakeVisualSelection(): void {
        const model = this.editor.model;

        model.change((writer) => {
            const range = model.document.selection.getFirstRange()!;
            const markerName = SignaturePlugin.MODEL_ENTITIES.class.dataView;

            if (model.markers.has(markerName)) {
                writer.updateMarker(markerName, { range });
                return;
            }

            const startPosition = range.start.isAtEnd
                ? range.start.getLastMatchingPosition(
                    ({ item }) => !model.schema.isContent(item),
                    { boundaries: range }
                )
                : range.start;

            writer.addMarker(markerName, {
                usingOperation: false,
                affectsData: false,
                range: writer.createRange(startPosition, range.end),
            });
        });
    }

    private hideFakeVisualSelection(): void {
        this.pluginUtils.hideFakeVisualSelection(this.editor, SignaturePlugin.MODEL_ENTITIES.class.dataView);
    }

    private addSignatureBallonFormView(signatureId: string, role: string): void {
        if (!this.signatureBalloonFormView) {
            this.createSignatureBalloonView(signatureId, role);
        }

        const isSameSignatureEditing = this.signatureBalloonFormView.id === signatureId;
        if (!isSameSignatureEditing) {
            this.signatureBalloonFormView.id = signatureId;
            this.signatureBalloonFormView.signatureRole = role;
        }

        if (!this.isViewInPanel(this.signatureBalloonFormView, this.balloon)) {
            this.balloon.add({
                view: this.signatureBalloonFormView,
                position: this.getBalloonPositionData()
            });
        }
    }

    private isViewInPanel(view: View, balloon: ContextualBalloon): boolean {
        return this.userInterfaceService.isBalloonInPanel(balloon, view);
    }

    private getBalloonPositionData() {
        return this.pluginUtils.getBalloonPositionData(this.editor, SignaturePlugin.MODEL_ENTITIES.class.editionView, SignaturePlugin.MODEL_ENTITIES.class.dataView);
    }

    private startUpdatingUI(): void {
        const editor = this.editor;
        const viewDocument = editor.editing.view.document;

        let prevSelectedSignature = this.utilsService.getSelectedSignatureElement(this.editor);
        let prevSelectionParent = getSelectionParent();

        const update = () => {
            const selectedSignature = this.utilsService.getSelectedSignatureElement(this.editor);
            const selectionParent = getSelectionParent();

            if ((prevSelectedSignature && !selectedSignature) ||
                (!prevSelectedSignature && selectionParent !== prevSelectionParent)) {
                    this.userInterfaceService.hideUI(this.editor, this.balloon, this.formView, SignaturePlugin.MODEL_ENTITIES.class.editionView, this);
            }
            else if (this.userInterfaceService.areActionsVisible(this.balloon, this.formView)) {
                this.balloon.updatePosition(
                    this.getBalloonPositionData()
                );
            }

            prevSelectedSignature = selectedSignature;
            prevSelectionParent = selectionParent;
        };

        function getSelectionParent() {
            return viewDocument.selection.focus!.getAncestors()
                .reverse()
                .find( ( node ): node is ViewElement => node.is( 'element' ) );
        }

        this.listenTo(editor.ui, 'update', update);
        this.listenTo(this.balloon, 'change:visibleView', update);
    }

    private createSignatureBalloonView(signatureId?: string, role?: string): void {
        const editor = this.editor;
        this.signatureBalloonFormView = new SignatureBalloonView(editor.locale, signatureId, role);

        this.signatureBalloonFormView.deleteButtonView.on('execute', () => {
            editor.execute(this.DELETE_SIGNATURE_COMMAND_NAME, this.signatureBalloonFormView.id, this.signatureBalloonFormView.signatureRole);
            this.hideUI();
        });

        this.signatureBalloonFormView.editOptionsButtonView.on('execute', () => {
            this.showEditSignatureDialog(this.signatureBalloonFormView.id, this.signatureBalloonFormView.signatureRole);
        });

        this.userInterfaceService.enableUserBalloonInteractions(this.editor, this.balloon, this.signatureBalloonFormView, SignaturePlugin.MODEL_ENTITIES.class.dataView, this);
    }

    private showEditSignatureDialog(signatureId?: string, role?: string): void {
        this.removeBalloonView(this.signatureBalloonFormView);

        const signatureElement = this.utilsService.getSelectedSignatureElement(this.editor);

        if (!signatureElement) {
            this.showFakeVisualSelection();
        }

        this.openSignatureDialog(signatureId, role);

        this.startUpdatingUI();
    }

    private openSignatureDialog(signatureId?: string, role?: string): void {

        const dialog = this.editor.plugins.get("Dialog");

        if (this.closeDialogIfButtonIsOn(dialog)) {
            return;
        }

       const isEdition = !!signatureId;

        this.userInterfaceService.hideUI(this.editor, this.balloon, this.formView, SignaturePlugin.MODEL_ENTITIES.class.editionView, this);
        const context = this;
        this.button.isOn = true;

        const defaultRole = SignaturePlugin.MODEL_ENTITIES.role.editionView;
        const defaultValue = defaultRole + (this.utilsService.getMaxRoleNumber(this.editor, defaultRole.trim()) + 1).toString();
        const signatureRole = role ? role : defaultValue;

        this.formView = this.createFormView(signatureRole, signatureId);

        this.handleFormEvents(dialog, defaultValue, isEdition);
        this.showDialog(dialog, context);
    }

    private removeBalloonView(balloonView: SignatureBalloonView): void {
        if (!this.isViewInPanel(balloonView, this.balloon)) {
            return;
        }

        this.balloon.remove(balloonView!);
        this.editor.editing.view.focus();
        this.hideFakeVisualSelection();
    }

    private hideUI(): void {
        if (!this.isUIInPanel) {
            return;
        }

        this.userInterfaceService.removeBalloonObservers(this.editor, this.balloon, this);

        this.editor.editing.view.focus();

        this.removeBalloonView(this.signatureBalloonFormView);

        this.hideFakeVisualSelection();
    }

    private isUIInPanel(): boolean {
        return this.isViewInPanel(this.signatureBalloonFormView, this.balloon) ||
        this.areActionsVisible();
    }

    private areActionsVisible(): boolean {
        return this.userInterfaceService.areActionsVisible(this.balloon, this.signatureBalloonFormView);
    }

    private closeDialogIfButtonIsOn(dialog: Dialog): boolean {
        if (this.button.isOn) {
            this.closeDialog(dialog);
            return true;
        }
        return false;
    }

    private createFormView(defaultValue?: string, defaultSignatureId?: string): SignatureBalloonFormView {
        const editor = this.editor;
        const validators = this.getFormValidators(editor);
        return new SignatureBalloonFormView(validators, editor.locale, defaultValue, defaultSignatureId);
    }

    private getFormValidators(editor: Editor): Array<SignatureFormValidatorCallback> {
        const t = editor.t;

        const isRoleEmpty = (form: any): string | null => { return !form.role || !form.role.length ? t(this.emptyRoleMessage) : null; };

        const isRoleInUse = (form: any): string | null => {
            return this.utilsService.hasRoleInDocument(this.editor, form.role, form.signatureId) ? t(this.roleRepeatedMessage): null;
        };

        return [ (form) => { return isRoleEmpty(form) || isRoleInUse(form) || t(""); } ];
    }

    private handleFormEvents(dialog: Dialog, defaultValue: string, isEdition: boolean): void {
        this.listenTo(this.formView, "submit", () => {
            const roleFieldView = this.formView.roleInputView;
            const role = this.formView.role;
            const id = this.formView.signatureId;

            if (!this.formView.isValid() || !role) {
                return;
            }

            if (defaultValue !== role) {
                if (!this.formView.isValid()) {
                    return;
                }
            }

            roleFieldView.errorText = "";

            const signature: SignatureModel = { id, role };
            if(isEdition) {
                this.editor.execute(this.EDIT_SIGNATURE_COMMAND_NAME, signature);
            } else {
                this.editor.execute(this.ADD_SIGNATURE_COMMAND_NAME, signature);
            }
            this.closeDialog(dialog);
        });

        this.listenTo(this.formView, "cancel", () => {
            this.userInterfaceService.hideUI(this.editor, this.balloon, this.formView, SignaturePlugin.MODEL_ENTITIES.class.editionView, this);
            this.closeDialog(dialog);
        });
    }

    private showDialog(dialog: any, context: this): void {
        dialog.show({
            isModal: true,
            content: this.formView,
            title: this.selectRolMessage,
            onHide: () => {
                context.button.isOn = false;
            },
            id: "",
        });
    }

    private closeDialog(dialog: Dialog): void {
        dialog.hide();
    }
}
