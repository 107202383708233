import { Directive, OnDestroy } from "@angular/core";
import { ClickObserver, ContextualBalloon, Dialog, DomEventData, DowncastWriter, Editor, Element, EventInfo, ViewDocument, ViewDocumentClickEvent, Writer } from "ckeditor5";
import { BasePlugin } from "../base/base-plugin";
import { ToolbarButtonModel } from "../../models/base/toolbar-button-model";
import { UI_CLASSES } from "../../ui/styles/styles-constants";
import AddLinkedFieldCommand from "../../commands/linked-field/add-linked-field-command";
import LinkedFieldDialogView from "../../ui/linked-field/linked-field-dialog-view.directive";
import LinkedFieldBalloonView from "../../ui/linked-field/linked-field-balloon-view.directive";
import { UserInterfaceService } from "../../ui/user-interface.service";
import { InputPlugin } from "../input/input-plugin";
import { LinkedFieldUtilsService } from "../../utils/linked-field/linked-field-utils.service";
import { BaseInputModel } from "../../models/input/base-input-model";
import DeleteLinkedFieldCommand from "../../commands/linked-field/delete-linked-field-command";
import NavigateLinkedFieldCommand from "../../commands/linked-field/navigate-linked-field-command";
import { InputUtilsService } from "../../utils/input/input-utils.service";
import DoubleClickObserver, { ViewDocumentDoubleClickEvent } from "../../../utils/double-click-observer";
import { ShortTextInput } from "../../models/input/short-text-input-model";
import { GlobalConstant } from "../../models/base/global-constant";
import { InputEditionViewUtilsService } from "../../utils/input/input-edition-view-utils.service";
import EditLinkedFieldCommand from "../../commands/linked-field/edit-linked-field-command";
import { ContextEditorTypes } from "../../models/base/context-editor-types";

@Directive({
    selector: 'linked-field-plugin',
})
export class LinkedFieldPlugin extends BasePlugin implements OnDestroy {

    public static readonly PLUGIN_NAME = 'LinkedField';
    public static readonly PLUGIN_MODEL = "linked-field";
    public static readonly PLUGIN_TOOLBAR_BUTTON_NAME = "text-LinkedField";

    public static readonly ATTRIBUTE_INPUT_ALIAS_IS_STORED = "data-alias";

    public static readonly ADD_COMMAND_NAME = "add-linked-field";
    public static readonly EDIT_COMMAND_NAME = "edit-linked-field";
    public static readonly DELETE_COMMAND_NAME = "delete-linked-field";
    public static readonly NAVIGATE_COMMAND_NAME = "navigate-linked-field";

    protected mappers  = [];

    protected commands = {
        [LinkedFieldPlugin.ADD_COMMAND_NAME]: AddLinkedFieldCommand,
        [LinkedFieldPlugin.EDIT_COMMAND_NAME]: EditLinkedFieldCommand,
        [LinkedFieldPlugin.DELETE_COMMAND_NAME]: DeleteLinkedFieldCommand,
        [LinkedFieldPlugin.NAVIGATE_COMMAND_NAME]: NavigateLinkedFieldCommand,
    };

    protected readonly insertOptionsMessage = $localize`:@@InsertaCampoVinculadoMensaje:Insertar campo vinculado`;
    protected toolbarButton: ToolbarButtonModel = {
        icon: UI_CLASSES.SVG_ICONS.ICON_LONG_TEXT,
        pluginToolbarElementName: LinkedFieldPlugin.pluginToolbarElementName,
        tooltip: this.insertOptionsMessage,
        hasTooltip: true,
        hasText: true
    };

    private linkedFieldDialogView: LinkedFieldDialogView;
    private linkedFieldBalloonView: LinkedFieldBalloonView;

    private userInterfaceService: UserInterfaceService;
    private linkedFieldUtils: LinkedFieldUtilsService;
    private inputUtilsService: InputUtilsService;
    private inputEditionViewUtils: InputEditionViewUtilsService;

    private readonly linkedFieldMessage = $localize`:@@CampoVinculadoMensaje:Campo vinculado`;

    constructor(editor: Editor) {
        super(editor);

        this.userInterfaceService = new UserInterfaceService();
        this.linkedFieldUtils = new LinkedFieldUtilsService();
        this.inputUtilsService = new InputUtilsService();
        this.inputEditionViewUtils = new InputEditionViewUtilsService();
    }

    public static get pluginName(): string { return LinkedFieldPlugin.PLUGIN_NAME; }
    public static get pluginModelName(): string { return LinkedFieldPlugin.PLUGIN_MODEL; }
    public static get pluginToolbarElementName(): string { return LinkedFieldPlugin.PLUGIN_MODEL; }
    public static get mainToolbarButtonName() { return LinkedFieldPlugin.PLUGIN_MODEL; }
    public static get linkedFieldTextToolbarButtonName() { return LinkedFieldPlugin.PLUGIN_TOOLBAR_BUTTON_NAME; }

    public init(): void {
        super.init();
        this.defineCustomListeners();
    }

    public override toolbarExecuteOperation(): void {
        const dialog = this.editor.plugins.get("Dialog");

        const model: BaseInputModel = { id: '', alias: '' }

        this.addEditLinkedFieldDialogView(model);

        dialog.show({
            isModal: true,
            content: this.linkedFieldDialogView,
            title: this.linkedFieldMessage,
            id: "",
        });
    }

    protected defineSchema(): void {}

    protected defineConverters(): void {}

    protected editorInteractions(): void {
        this.defineObservers();
        this.balloon = this.editor.plugins.get(ContextualBalloon);
        this.enableBalloonActivators();
    }

    private defineCustomListeners(): void {
        const viewDocument = this.editor.editing.view.document;

        this.listenTo(viewDocument, 'blur', this.handleBlurEvent.bind(this));
    }

    private handleBlurEvent(): void {
        const contentElement = this.pluginUtils.getSelectedElementWithClass(this.editor, InputPlugin.MODEL_ENTITIES.container.editionView);

        const alias = contentElement?.getAttribute(InputPlugin.MODEL_ENTITIES.alias.editionView);
        const isLinked = contentElement?.getAttribute(InputPlugin.MODEL_ENTITIES.isLinked.editionView);
        if (alias && isLinked && isLinked === "true")  {
            const inputsAndLinkedFieldsModel = this.inputUtilsService.getInputAndLinkedFieldsElementModelWithAlias(this.editor, alias);
            this.editor.model.change((writer: Writer)  => {
                const id = contentElement?.getAttribute(GlobalConstant.ATTRIBUTE_ID);
                const linkedFieldModel = this.pluginUtils.getElementModelWithId(this.editor, id);
                const valueLinkedField = this.inputUtilsService.getInputValue(linkedFieldModel.item as Element);

                inputsAndLinkedFieldsModel.forEach(input => {
                    this.inputUtilsService.editInputValue(writer, input, valueLinkedField);
                });
            });

            inputsAndLinkedFieldsModel.forEach(input => {
                const inputEditingModel = this.pluginUtils.getElementView(this.editor, input.item.getAttribute('id').toString());
                const editingView = this.editor.editing.view;
                editingView.change((writer: DowncastWriter) => {
                    this.inputEditionViewUtils.applyValidationToInputEditingView(writer, inputEditingModel);
                });
            });
        }
    }

    private defineObservers(): void {
        this.editor.editing.view.addObserver(ClickObserver);
        this.editor.editing.view.addObserver(DoubleClickObserver);
    }

    private enableBalloonActivators(): void {
        const editor = this.editor;
        const viewDocument = editor.editing.view.document;
        this.showBalloonOnClick(viewDocument);
    }

    private showBalloonOnClick(viewDocument: ViewDocument): void {
        this.listenTo<ViewDocumentClickEvent>(viewDocument, 'click', this.handleClickEvent.bind(this), { priority: 'high' });
        this.listenTo<ViewDocumentDoubleClickEvent>(viewDocument, 'dblclick', this.handleDblClickEvent.bind(this), { priority: 'high' });
    }

    private handleClickEvent(evt: EventInfo, data: DomEventData): void {
        if (this.isReadOnlyOrRestrictedMode()) {
            return;
        }

        const parentElement = this.pluginUtils.getSelectedContainerWithClass(this.editor, InputPlugin.MODEL_ENTITIES.container.editionView);

        if (!parentElement || parentElement.getAttribute('data-is-linked') === "false") {
            return;
        }

        if (!this.pluginUtils.isViewElementInteractableInTargetEditor(parentElement, InputPlugin.contextEditor, ContextEditorTypes.CLAUSE)) {
            return;
        }

        const linkedFieldModel = this.linkedFieldUtils.getInputModelWithElementView(parentElement);

        this.showLinkedFieldBalloon(linkedFieldModel);
        this.finishEventPropagation(data, evt);
    }

    private handleDblClickEvent(evt: EventInfo, data: DomEventData): void {
        if (this.isReadOnlyOrRestrictedMode()) {
            return;
        }

        const inputModel: ShortTextInput = this.linkedFieldUtils.getInputModelWithDataDom(this.editor, data);

        if (!inputModel || inputModel.isLinked === false) {
            return;
        }

        this.showEditLinkedFieldDialog(this.linkedFieldBalloonView.inputModelToEdit);
        this.finishEventPropagation(data, evt);
    }

    private showLinkedFieldBalloon(linkedFieldModel?: BaseInputModel, forceVisible: boolean = false): void {
        const linkedFieldElement = this.pluginUtils.getSelectedElementWithClass(this.editor, InputPlugin.MODEL_ENTITIES.container.editionView);

        if (!linkedFieldElement) {
            this.pluginUtils.showFakeVisualSelection(this.editor, InputPlugin.VISUAL_SELECTION_MARKER_NAME);
        }

        this.addLinkedFieldBalloonView(linkedFieldModel);

        if (forceVisible) {
            this.balloon.showStack("main");
        }

        this.startUpdatingUI();
    }

    private startUpdatingUI(): void {
        const editor = this.editor;

        let previousSelectedLinkedField = this.pluginUtils.getSelectedElementWithClass(editor, InputPlugin.MODEL_ENTITIES.container.editionView);
        let previousSelectionParent = this.getSelectionParent();

        const update = () => {
            const selectedLinkedField = this.pluginUtils.getSelectedElementWithClass(editor, InputPlugin.MODEL_ENTITIES.container.editionView);
            const selectionParent = this.getSelectionParent();

            if ((previousSelectedLinkedField && !selectedLinkedField) ||
                (!previousSelectedLinkedField && selectionParent !== previousSelectionParent)) {
                this.hideUI();
            } else if (this.isUIVisible()) {
                this.balloon.updatePosition( this.pluginUtils.getBalloonPositionData(this.editor, InputPlugin.MODEL_ENTITIES.container.editionView, InputPlugin.VISUAL_SELECTION_MARKER_NAME));
            }

            previousSelectedLinkedField = selectedLinkedField;
            previousSelectionParent = selectionParent;
        };

        this.listenTo(editor.ui, 'update', update);
        this.listenTo(this.balloon, 'change:visibleView', update);
    }

    private addLinkedFieldBalloonView(linkedFieldModel?: BaseInputModel): void {
        if (!this.linkedFieldBalloonView) {
            this.createLinkedFieldBalloonView();
        }

        if (this.userInterfaceService.isBalloonInPanel(this.balloon, this.linkedFieldBalloonView)) {
            const isSameLinkedFieldEditing = this.linkedFieldBalloonView.id === linkedFieldModel?.id;
            if (!isSameLinkedFieldEditing) {
                this.linkedFieldBalloonView.InputModel = linkedFieldModel!;
            }
            return;
        }

        this.linkedFieldBalloonView!.resetFormStatus();
        this.linkedFieldBalloonView.InputModel = linkedFieldModel!;

        this.balloon.add({
            view: this.linkedFieldBalloonView!,
            position:  this.pluginUtils.getBalloonPositionData(this.editor, InputPlugin.MODEL_ENTITIES.container.editionView, InputPlugin.VISUAL_SELECTION_MARKER_NAME)
        });
    }

    private addEditLinkedFieldDialogView(linkedFieldModel?: BaseInputModel): void {
        if (!this.linkedFieldDialogView) {
            this.linkedFieldDialogView = this.createLinkedFieldDialogView(linkedFieldModel);
        } else {
            this.linkedFieldDialogView.resetFormStatus();
            this.linkedFieldDialogView.model = linkedFieldModel!;
        }

        const dialog = this.editor.plugins.get("Dialog");
        dialog.show({
            isModal: true,
            content: this.linkedFieldDialogView,
            title: this.linkedFieldMessage,
            onHide() {},
            id: "",
        });
    }

    private createLinkedFieldBalloonView(): void {
        const editor = this.editor;
        this.linkedFieldBalloonView = new LinkedFieldBalloonView(
            editor.locale
        );

        this.linkedFieldBalloonView.navigateButtonView.on('execute', () => {
            this.navigateInputParent(this.editor, this.linkedFieldBalloonView.inputModelToEdit.alias);
        });

        this.linkedFieldBalloonView.editButtonView.on('execute', () => {
            this.showEditLinkedFieldDialog(this.linkedFieldBalloonView.inputModelToEdit);
        });

        this.linkedFieldBalloonView.deleteButtonView.on('execute', () => {
            editor.execute(LinkedFieldPlugin.DELETE_COMMAND_NAME, this.linkedFieldBalloonView.inputModelToEdit.id);
        });

        this.userInterfaceService.enableUserBalloonInteractions(this.editor, this.balloon, this.linkedFieldBalloonView, InputPlugin.VISUAL_SELECTION_MARKER_NAME, this);
    }

    private navigateInputParent(editor: Editor, alias: string): void {
        const idInputParent = this.inputUtilsService.getInputParentElementModelWithAlias(editor, alias);
        editor.execute(LinkedFieldPlugin.NAVIGATE_COMMAND_NAME, idInputParent.item.getAttribute('id').toString());
        this.balloon.remove(this.linkedFieldBalloonView!);
    }

    private createLinkedFieldDialogView(linkedFieldModel?: BaseInputModel): LinkedFieldDialogView {
        const editor = this.editor;

        this.linkedFieldDialogView = new LinkedFieldDialogView(editor.locale, linkedFieldModel);
        this.handlerSubmitCancelInForm();

        return this.linkedFieldDialogView;
    }

    private handlerSubmitCancelInForm(): void {
        const dialog = this.editor.plugins.get("Dialog");
        this.listenTo(this.linkedFieldDialogView, "submit", () => {
            const model = this.linkedFieldDialogView.model;
            if (this.linkedFieldDialogView.isInCreation()) {
                this.editor.execute(LinkedFieldPlugin.ADD_COMMAND_NAME, model.alias);
            } else {
                this.editor.execute(LinkedFieldPlugin.EDIT_COMMAND_NAME, model);
            }

            this.closeDialog(dialog);
            this.linkedFieldDialogView.resetFormStatus();
        });

        this.listenTo(this.linkedFieldDialogView, "cancel", () => {
            this.hideUI();
            this.closeDialog(dialog);
            this.linkedFieldDialogView.resetFormStatus();
        });
    }

    private closeDialog(dialog: Dialog): void {
        dialog.hide();
    }

    private showEditLinkedFieldDialog(linkedFieldModel?: BaseInputModel, forceVisible: boolean = false): void {
        this.removeLinkedFieldBalloonView();

        const linkedFieldElement = this.pluginUtils.getSelectedElementWithClass(this.editor, InputPlugin.MODEL_ENTITIES.container.editionView);
        if (!linkedFieldElement) {
            this.pluginUtils.showFakeVisualSelection(this.editor, InputPlugin.VISUAL_SELECTION_MARKER_NAME);
        }

        this.addEditLinkedFieldDialogView(linkedFieldModel);

        if (forceVisible) {
            this.balloon.showStack('main');
        }

        this.startUpdatingUI();
    }

    private hideUI(): void {
        if (!this.isUIInPanel()) {
            return;
        }

        const editor = this.editor;
        this.userInterfaceService.removeBalloonObservers(this.editor, this.balloon, this);

        editor.editing.view.focus();

        this.removeLinkedFieldBalloonView();
        this.removeLinkedFieldEditFormView();

        this.pluginUtils.hideFakeVisualSelection(this.editor, InputPlugin.VISUAL_SELECTION_MARKER_NAME);
    }

    private isUIInPanel(): boolean {
        return this.userInterfaceService.isBalloonInPanel(this.balloon, this.linkedFieldBalloonView) ||
               this.userInterfaceService.isBalloonInPanel(this.balloon, this.linkedFieldDialogView);
    }

    private isUIVisible(): boolean {
        return this.userInterfaceService.areActionsVisible(this.balloon, this.linkedFieldDialogView) ||
               this.userInterfaceService.areActionsVisible(this.balloon, this.linkedFieldBalloonView);
    }

    private removeLinkedFieldEditFormView(): void {
        this.editor.editing.view.focus();
    }

    private removeLinkedFieldBalloonView(): void {
        if (!this.userInterfaceService.isBalloonInPanel(this.balloon, this.linkedFieldBalloonView)) {
            return;
        }

        this.balloon.remove(this.linkedFieldBalloonView!);

        this.editor.editing.view.focus();
        this.pluginUtils.hideFakeVisualSelection(this.editor, InputPlugin.VISUAL_SELECTION_MARKER_NAME);
    }
}
