import { DocumentSelection, Editor, TextProxy, Writer } from "ckeditor5";
import { BaseInputModel } from "../../models/input/base-input-model";
import { InputDataViewUtilsService } from "../../utils/input/input-data-view-utils.service";
import { InputUtilsService } from "../../utils/input/input-utils.service";
import { InputBaseCommand } from "./input-base-command";

export default class AddInputCommand extends InputBaseCommand {

    private utilsService: InputUtilsService;
    private dataUtilsService: InputDataViewUtilsService;

    constructor(editor: Editor) {
        super(editor);
        this.utilsService = new InputUtilsService();
        this.dataUtilsService = new InputDataViewUtilsService();
    }

    public override execute(model: BaseInputModel): void {
        const editor = this.editor;

        editor.model.change((writer: Writer)  => {
            const inputType = model.type;

            if (!this.utilsService.isTypeValid(inputType)) {
                return null;
            }

            const elementModel = this.dataUtilsService.createInputStructureModel(writer, inputType, model);

            const selection = editor.model.document.selection;

            const previousElement = this.findPreviousElement(selection);

            if (previousElement) {
                const attributes = previousElement.getAttributes();
                for (const [key, value] of attributes) {
                    writer.setAttribute(key, value, elementModel);
                }
            }

            editor.model.insertObject(elementModel, null, null, {setSelection: 'after'});
        } );
    }

    private findPreviousElement(selection: DocumentSelection): any {
        const position = selection.getFirstPosition();
        let previousElement = position.nodeBefore;

        if (!previousElement && position.parent.is('element', 'paragraph')) {
            const parent = position.parent;
            const offset = position.offset;

            let charCount = 0;

            for (const child of parent.getChildren()) {
                if (child.is('element', 'span')) {
                    let textLength = 0;
                    for (const textNode of child.getChildren()) {
                        if (textNode.is('$text') || textNode.is('$textProxy')) {
                            textLength += (textNode as TextProxy).data.length;
                        }
                    }
                    charCount += textLength;
                }
                else if (child.is('$text')) {
                    charCount += child.data.length;
                }

                if (charCount >= offset) {
                    previousElement = child;
                    break;
                }
            }
        }

        return previousElement;
    }
}
