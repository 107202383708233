import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { PublicLinksService } from 'src/app/core/shared/services/public-links/public-links.service';
import { CustomizeConfiguration } from 'src/config/customisation';

export enum LogoTypes {
    branding = 'branding',
    product = 'product'
}

@Component({
    selector: 'ctbox-logo',
    templateUrl: './ctbox-logo.component.html',
    styleUrls: ['./ctbox-logo.component.scss']
})
export class CtboxLogoComponent implements OnInit {

    @Input() currentScreenSize: string;
    @Input() isLandingPage: boolean;
    @Input() isPositiveLogo = true;
    @Input() logoType: string;
    @Input() logoClass: string;
    @Input() customClass: string;

    @HostBinding('className') componentClass: string;

    public logoSrc: string;
    public logoAlt: string;

    public imgLogo: string;
    public customizeIdName: string;
    public customizeName: string;
    public customizeLogoName: string;
    public customizeProductName: string;
    public customizeProductLogoName: string;

    constructor(
        public publicLinks: PublicLinksService,
        private readonly customisation: CustomizeConfiguration) {
        this.imgLogo = 'assets/images/logos/';
        this.customizeIdName = this.customisation.customizeIdName;
        this.customizeName = this.customisation.customizeName;
        this.customizeLogoName = this.imgLogo + this.customisation.customizeLogoName;
        this.customizeProductName = this.customisation.customizeProductName;
        this.customizeProductLogoName = this.imgLogo + this.customisation.customizeProductLogoName;
    }

    public ngOnInit(): void {
        this.componentClass = 'ctbox-block';

        switch (this.isLandingPage) {
            case false:
                this.logoClass = 'logo-' + this.customizeIdName;
                this.logoSrc = this.customizeLogoName;
                this.logoAlt = this.customizeName;
                break;
            case true:
                this.logoClass = 'logo-' + this.customizeProductName;
                this.logoSrc = this.customizeProductLogoName;
                this.logoAlt = this.customizeProductName;
                break;
            default:
                this.logoClass = 'logo-' + this.customizeIdName;
                this.logoSrc = this.customizeLogoName;
                this.logoAlt = this.customizeName;
                break;
        }
        if (!this.isPositiveLogo) {
            this.logoClass = this.logoClass + ' negative';
        }
    }
}
