import { Injectable } from '@angular/core';
import { DowncastWriter, Element, ViewContainerElement, ViewElement, Writer } from 'ckeditor5';
import { RadioPlugin } from '../../plugins/radio/radio-plugin';
import { GlobalConstant } from '../../models/base/global-constant';

@Injectable({
    providedIn: 'root'
})
export class RadioDataViewUtilsService {

    constructor() { }

    public createModelFromDataView(viewElement: ViewElement, modelWriter: Writer): Element {
        let id = viewElement.getAttribute('id');
        let optionsInViewCount = 0;
        if (!id) {
            for (const child of viewElement.getChildren()) {
                if (child.is('view:element') && (child as unknown as ViewElement).hasClass(RadioPlugin.MODEL_ENTITIES.option.dataView)) {
                    id = this.getIdFromOptionView(id, child);
                    optionsInViewCount++;
                }
            }
        }

        let optionsCount = viewElement.getAttribute(RadioPlugin.MODEL_ENTITIES.optionCount.dataView);
        if (!optionsCount || optionsCount === '0') {
            optionsCount = optionsInViewCount.toString();
        }

        const embeddedIn = viewElement.getAttribute(GlobalConstant.ATTRIBUTE_EMBEDDED_IN) ? viewElement.getAttribute(GlobalConstant.ATTRIBUTE_EMBEDDED_IN) : "undefined";

        const modelElement = modelWriter.createElement(RadioPlugin.MODEL_ENTITIES.container.model, {
            id: id,
            'optionsCount': optionsCount,
            'data-embedded-in': embeddedIn
        });

        return modelElement;
    }

    public getPositionFromOptionView(optionView: ViewElement): string {

        for (const child of optionView.getChildren()) {
            if (child.is('view:element') && (child as unknown as ViewElement).hasClass(RadioPlugin.MODEL_ENTITIES.input.dataView)) {
                const name = child.getAttribute(RadioPlugin.MODEL_ENTITIES.groupName.dataView);
                const id = child.getAttribute('id');
                const stringToIgnore = name + '-';
                const positionIndex = id?.indexOf(stringToIgnore)!;
                return id?.substring(positionIndex + stringToIgnore.length)!;
            }
        }

        return '0';
    }

    public createRadioModelFromDataView(viewElement: ViewElement, modelWriter: Writer): Element {
        const nameGroup = viewElement.getAttribute('name');
        const checked = viewElement.getAttribute(RadioPlugin.MODEL_ENTITIES.checked.model);

        if ( checked !== undefined && checked === "true" ) {
            return modelWriter.createElement(RadioPlugin.MODEL_ENTITIES.input.model, {
                'dataName': nameGroup,
                'checked': true
            });
        } else {
            return modelWriter.createElement(RadioPlugin.MODEL_ENTITIES.input.model, {
                'dataName': nameGroup,
                'checked': false
            });
        }
    }

    public createDescriptionModelFromDataView(viewElement: ViewElement, modelWriter: Writer): Element {
        const element = modelWriter.createElement(RadioPlugin.MODEL_ENTITIES.description.model, {
        });

        return element;
    }

    public createRadiusDataView(modelItem: any, viewWriter: DowncastWriter): ViewContainerElement {
        const optionsCount = modelItem.getAttribute(RadioPlugin.MODEL_ENTITIES.optionCount.model);
        const id = modelItem.getAttribute("id");

        const mainContainerRadius = viewWriter.createContainerElement(
            "div",
            {
                class: [RadioPlugin.MODEL_ENTITIES.container.dataView],
                id,
                'data-options-count': optionsCount
            },
        );

        return mainContainerRadius;
    }

    public createOptionDataView(modelItem: any, viewWriter: DowncastWriter): ViewContainerElement {

        const dataPosition = modelItem.getAttribute(RadioPlugin.MODEL_ENTITIES.optionPosition.model);

        let isOptionSelected = false;
        const childInput = modelItem.getChild(0);

        if (childInput.is('element') && childInput.name === RadioPlugin.MODEL_ENTITIES.input.model) {
            isOptionSelected = childInput.getAttribute(RadioPlugin.MODEL_ENTITIES.checked.model);
        }

        let classForOption: string = RadioPlugin.MODEL_ENTITIES.option.dataView;

        if (isOptionSelected) {
            classForOption =  classForOption + " " + RadioPlugin.OPTION_SELECTED_ADDITIONAL_CLASS_DATA_VIEW;
        }

        const optionElement = viewWriter.createContainerElement("div",
        {
            class: classForOption,
            'data-position': dataPosition
        });

        return optionElement;
    }

    public createRadiusElementDataView(modelItem: Element, viewWriter: DowncastWriter): ViewContainerElement {

        const nameGroup = modelItem.getAttribute(RadioPlugin.MODEL_ENTITIES.groupName.model);
        const position = modelItem.parent!.is('element') ? modelItem.parent!.getAttribute(RadioPlugin.MODEL_ENTITIES.optionPosition.model) : '0';
        const checked = modelItem.getAttribute(RadioPlugin.MODEL_ENTITIES.checked.model);

        if(checked && checked === true) {
            return viewWriter.createContainerElement("input",
                {
                    class: [RadioPlugin.MODEL_ENTITIES.input.dataView],
                    'type': 'radio',
                    'name': nameGroup,
                    'id': nameGroup + '-' + position,
                    'value': nameGroup + '-' + position,
                    'checked': true
                });

        } else {
            return viewWriter.createContainerElement("input",
            {
                class: [RadioPlugin.MODEL_ENTITIES.input.dataView],
                'type': 'radio',
                'name': nameGroup,
                'id': nameGroup + '-' + position,
                'value': nameGroup + '-' + position
            });
        }
    }

    public createDescriptionElementDataView(viewWriter: DowncastWriter): ViewContainerElement {

        const optionElement = viewWriter.createEditableElement("div",
            {
                class: [RadioPlugin.MODEL_ENTITIES.description.dataView],
            });

        return optionElement;
    }

    public isRadio(viewItem: ViewElement): boolean {
        return viewItem.hasAttribute(RadioPlugin.MODEL_ENTITIES.optionCount.dataView) && (parseInt(viewItem.getAttribute(RadioPlugin.MODEL_ENTITIES.optionCount.dataView)) === viewItem.childCount);
    }

    private getIdFromOptionView(id: string | undefined, optionView: ViewElement) {
        if (id) {
            return id;
        }

        for (const child of optionView.getChildren()) {
            if (child.is('view:element') && (child as unknown as ViewElement).hasClass(RadioPlugin.MODEL_ENTITIES.input.dataView)) {
                id = child.getAttribute(RadioPlugin.MODEL_ENTITIES.groupName.dataView);
            }
        }

        return id;
    }
}
