import { Injectable } from "@angular/core";
import { IHtmlEditorInputProcessor } from "./html-editor-input-processor.interface";


@Injectable({
    providedIn: "root"
})
export class HtmlEditorInputProcessor extends IHtmlEditorInputProcessor
    {
        private readonly restrictedModeClass= 'restricted-editing-exception';
        private readonly restrictedModeElementLabel= 'span';

    public applyRestrictedMode(documentToApply: string): string {
        const domParser = new DOMParser();
        const document = domParser.parseFromString(
            documentToApply,
            "text/html"
        );

        this.applyRestrictedModeToTextInputs(document);
        this.applyRestrictedModeToSelect(document);
        this.fixNestedRestrictedModeSpans(document);

        return document.querySelector("html").innerHTML;
    }

    private applyRestrictedModeToTextInputs(document: Document): void {

        const textInputs = document.querySelectorAll('input[type="text"]');
        textInputs.forEach((input: Element) =>
            this.applyRestrictedModeToElement(input)
        );
    }

    private applyRestrictedModeToSelect(document: Document): void {

        const selects = document.querySelectorAll('select');
        selects.forEach((select: Element) =>
            this.applyRestrictedModeToElement(select)
        );
    }

    private fixNestedRestrictedModeSpans(document: Document) {
        const restrictedSpans = document.querySelectorAll(
            `${this.restrictedModeElementLabel}.${this.restrictedModeClass} ${this.restrictedModeElementLabel}.${this.restrictedModeClass}`
        );

        restrictedSpans.forEach(innerSpan => {
            const parentSpan = innerSpan.parentElement;
            if (parentSpan && parentSpan.classList.contains(this.restrictedModeClass)) {
                this.unwrapElement(innerSpan);
            }
        });
    }

    private unwrapElement(element: Element) {
        const parentElement = element.parentElement;
        while (element.firstChild) {
            parentElement.insertBefore(element.firstChild, element);
        }
        parentElement.removeChild(element);
    }

    private applyRestrictedModeToElement(input: Element): void {
        if (input.parentElement &&
            input.parentElement.classList.contains(this.restrictedModeClass)) {
            return;
        }

        const span = document.createElement(this.restrictedModeElementLabel);
        span.classList.add(this.restrictedModeClass);

        input.parentNode?.insertBefore(span, input);
        span.appendChild(input);
    }

}
