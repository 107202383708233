import { Command, Editor, Position, Range, TreeWalkerValue, Writer, Element } from 'ckeditor5';
import { GlobalConstant } from '../models/base/global-constant';
import { ElementPositionOffset } from '../models/base/element-position-offset';

export abstract class BaseCommand extends Command {

    public abstract labelDataModel: string;

    public override refresh(): void {
        const model = this.editor.model;
        const selection = model.document.selection;
        const position: Position | null = selection.getFirstPosition();
        if (!position) {
            return;
        }
        const allowedIn = model.schema.findAllowedParent(position, this.labelDataModel);
        this.isEnabled = allowedIn !== null;
    }

    protected getElementWithId(range: Range, id: string): TreeWalkerValue | null {
        for (const value of range.getWalker()) {
            if (value.item.hasAttribute(GlobalConstant.ATTRIBUTE_ID) && value.item.getAttribute(GlobalConstant.ATTRIBUTE_ID) === id) {
                return value;
            }
        }
        return null;
    }

    protected disableCommandIfCursorInside(contentModel: string): void {
        const model = this.editor.model;
        const selection = model.document.selection;
        const position = selection.getFirstPosition();
        const element = position.parent;
        if (!element || !element.is('element')) {
            this.isEnabled = true;
            return;
        }

        this.isEnabled = position.parent?.name !== contentModel;
    }

    protected setCursorPointerOnElement(editor: Editor, writer: Writer, element: Element, position: ElementPositionOffset): void {
        const contentPosition = writer.createPositionAt(element, position);
        writer.setSelection(contentPosition);
        editor.editing.view.focus();
    }
}
