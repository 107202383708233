import { Directive, ViewChild } from '@angular/core';
import { ClausesPlugin } from '../../wysiwyg-editor/custom-plugins/plugins/clause/clauses-plugin';
import { SignaturePlugin } from '../../wysiwyg-editor/custom-plugins/plugins/signature/signature-plugin';
import { EditorPermissionConfig } from '../../wysiwyg-editor/models/editor-permission-config';
import { EditorPluginConfig, PluginConfig } from '../../wysiwyg-editor/models/editor-plugin-config';
import { WysiwygEditorComponent } from '../../wysiwyg-editor/wysiwyg-editor.component';
import { ContextEditorTypes } from '../../wysiwyg-editor/custom-plugins/models/base/context-editor-types';
import { CommentThreadDataJSON, SuggestionJSON, User } from 'ckeditor5-premium-features';
import { UserInfoDTO } from 'src/app/api';

@Directive()
export abstract class BaseWithEditorDirective {

    public permissionConfig: EditorPermissionConfig = new EditorPermissionConfig();
    public editorPluginConfig: EditorPluginConfig = new EditorPluginConfig();
    public isReadOnly = false;
    public resetUnsavedChangesNotifier = false;
    public pluginsDisabled = [];

     @ViewChild('editor') public editor: WysiwygEditorComponent;

    constructor() {
        this.editorPluginConfig.pluginsConfig = new Array<PluginConfig>();
    }

    protected configureEditionWithPermission(permissions: any, isDocumentManager?: boolean) {
        if (permissions.canEdit) {
            this.enableEdition();
        } else {
            this.disableEdition();
        }

        this.permissionConfig.canComment = permissions.canComment;
        this.permissionConfig.hasCommentManagement = !!isDocumentManager;
        this.permissionConfig.canFill = permissions?.canOnlyFillTextInputs ? permissions?.canOnlyFillTextInputs : false;
        this.isReadOnly = !permissions.canEdit && !permissions.canOnlyFillTextInputs && !permissions.canComment;
    }

    protected enableEdition() {
        this.permissionConfig.canEdit = true;
    }

    protected disableEdition() {
        this.permissionConfig.canEdit = false;
    }

    protected enableReadOnly() {
        this.isReadOnly = true;
    }

    protected disableReadOnly() {
        this.isReadOnly = false;
    }

    protected notifyResetUnsavedChanges(resetUnsavedChanges: boolean) {
        this.resetUnsavedChangesNotifier = resetUnsavedChanges;
    }

    protected configurePluginsDocumentEditor(permissions: any): void {
        this.editorPluginConfig.contextEditor = ContextEditorTypes.DOCUMENT;
        this.disableTrackChangesPlugins();
        this.editorPluginConfig.pluginsConfig.push(this.getClausePluginEditorConfig(permissions.canAddClauses));
        this.editorPluginConfig.pluginsConfig.push(this.getSignaturePluginEditorConfig(permissions.canEdit));
    }

    protected configurePluginsTemplateEditor(permissions: any): void {
        this.editorPluginConfig.contextEditor = ContextEditorTypes.TEMPLATE;
        this.disableCommentsPlugin();
        this.disableTrackChangesPlugins();
        this.editorPluginConfig.pluginsConfig.push(this.getClausePluginEditorConfig(permissions.canAddClauses));
        this.editorPluginConfig.pluginsConfig.push(this.getSignaturePluginEditorConfig(permissions.canEdit));
    }

    protected configurePluginsClauseEditor(): void {
        this.editorPluginConfig.contextEditor = ContextEditorTypes.CLAUSE;
        const clauseAndSignaturePluginsEnabled = false;
        this.disableCommentsPlugin();
        this.disableTrackChangesPlugins();
        this.editorPluginConfig.pluginsConfig.push(this.getClausePluginEditorConfig(clauseAndSignaturePluginsEnabled));
        this.editorPluginConfig.pluginsConfig.push(this.getSignaturePluginEditorConfig(clauseAndSignaturePluginsEnabled));
    }

    protected getClausePluginEditorConfig(isEnabled: boolean): PluginConfig {
        return {
            isEnabled: isEnabled,
            pluginName: ClausesPlugin.pluginName,
            toolbarTooltip: ClausesPlugin.TOOLBAR_TOOLTIP,
            lockId: ClausesPlugin.ENABLE_DISABLE_LOCKID,
        };
    }

    protected getSignaturePluginEditorConfig(isEnabled: boolean): PluginConfig {
        return {
            isEnabled: isEnabled,
            pluginName: SignaturePlugin.PLUGIN_NAME,
            toolbarTooltip: SignaturePlugin.TOOLBAR_TOOLTIP,
            lockId: SignaturePlugin.ENABLE_DISABLE_LOCKID
        };
    }

    protected hasUnsavedChanges(): boolean {
        return this.editor ? this.editor.hasUnsavedChanges() : false;
    }

    protected getComments(): string {
        const users = this.editor.getUsers();

        const comments = !!this.editor ? this.editor?.getComments() : '';
        const suggestion = !!this.editor ? this.editor?.getSuggestions() : '';
        return this.setUsersInComments(comments, users, suggestion);
    }

    protected separateCommentsAndUsers(jsonString: string) {
        if (!jsonString) {
            return [[], []];
        }

        const jsonData: [CommentThreadDataJSON[], User[]] = JSON.parse(jsonString);
        return jsonData;
    }

    protected getUser(userInfo: UserInfoDTO): User {
        const user: any =
        {
            id: userInfo.guid,
            name: `${userInfo.name} ${userInfo.surname}`
        };

        return user
    }

    protected getUsersInComments(comments: string): User[] {
        const [_commentsWithoutUsers, users, _suggestions] = this.separateProperties(comments);
        return users;
    }

    protected getCommentsWithoutUsers(comments: string): string {
        const [commentsWithoutUsers, _users, _suggestions] = this.separateProperties(comments);
        if (!commentsWithoutUsers || commentsWithoutUsers?.length < 1) {
            return "[]";
        }
        return JSON.stringify(commentsWithoutUsers);
    }

    protected setUsersInComments(comments: string, users: User[], suggestions: string): string {
        const commentsString = comments ? JSON.parse(comments) : "[]";
        const suggestionsString = suggestions ? JSON.parse(suggestions) : "[]";
        const stringToJson = [commentsString, users, suggestionsString];
        return JSON.stringify(stringToJson);
    }

    protected getSuggestions(comments: string): string {
        const [_commentsWithoutUsers, _users, suggestions] = this.separateProperties(comments);
        if (!suggestions || suggestions?.length < 1) {
            return "[]";
        }
        return JSON.stringify(suggestions);
    }

    protected separateProperties(jsonString: string) {
        if (!jsonString) {
            return [[], [], []];
        }

        const jsonData: [CommentThreadDataJSON[], User[], SuggestionJSON[]] = JSON.parse(jsonString);
        return jsonData;
    }

    protected disableCommentsPlugin() {
        this.pluginsDisabled.push('Comments');
        this.pluginsDisabled.push('CommentsOnly');
        if(!this.pluginsDisabled.includes('TrackChanges')) {
            this.pluginsDisabled.push('TrackChanges'); // Track Changes requires Comments.
        }
    }

    protected disableTrackChangesPlugins() {
        if(!this.pluginsDisabled.includes('TrackChanges')) {
            this.pluginsDisabled.push('TrackChanges');
        }

        this.pluginsDisabled.push('TrackChangesData');
    }
}
