import { Writer } from "ckeditor5";
import { BaseCommand } from "../base-command";
import { SignaturePlugin } from '../../plugins/signature/signature-plugin';

export default class DeleteSignatureCommand extends BaseCommand {

    public labelDataModel = SignaturePlugin.MODEL_ENTITIES.class.dataView;

    public override execute(id: string): void {
        const editor = this.editor;

        editor.model.change((writer: Writer) => {
            const range = writer.createRangeIn(editor.model.document.getRoot()!);
            const signatureModel = this.getElementWithId(range, id);
            if (signatureModel) {
                writer.remove(signatureModel.item);
            }
		});
    }
}
