import { FormatString } from 'src/app/core/shared/utils/FormatString';
import { StyleAttributeModel } from '../../models/schema/style-attribute-model';
import { Editor, Node, Element, Conversion, ModelConsumable, DowncastConversionApi } from 'ckeditor5';
import { Injectable } from "@angular/core";
import { InputPlugin } from '../../plugins/input/input-plugin';
import { GlobalConstant } from '../../models/base/global-constant';
import { PluginUtilsService } from '../../../utils/plugin-utils.service';

@Injectable({
    providedIn: "root",
})
export class InputModelToDataViewConverterService {

    private utilsService: PluginUtilsService;

    private attributesMapping = {
        isValid: {
            model: InputPlugin.MODEL_ENTITIES.isValid.model,
            dataView: InputPlugin.MODEL_ENTITIES.isValid.dataView,
        },
        embeddedIn: {
            model: GlobalConstant.ATTRIBUTE_EMBEDDED_IN,
            dataView: GlobalConstant.ATTRIBUTE_EMBEDDED_IN,
        }
    };

    constructor() {
        this.utilsService = new PluginUtilsService();
    }

    public configureConverter(editor: Editor): void {
        const conversion = editor.conversion;

        this.inputConversion(editor);
        this.attributeConversion(conversion);
        this.mapPosition(editor);
    }

    private attributeConversion(conversion: Conversion): void {
        for (const key in this.attributesMapping) {
            if (this.attributesMapping.hasOwnProperty(key)) {
                const { model, dataView } = this.attributesMapping[key];
                conversion.for('dataDowncast').attributeToAttribute({
                    model: model,
                    view: dataView,
                });
            }
        }
    }

    private inputConversion(editor: Editor): Conversion {
        const conversion = editor.conversion;
        conversion.for('dataDowncast').elementToElement({
            model: InputPlugin.MODEL_ENTITIES.container.model,
            view: (containerModelElement: Element, conversionApi: DowncastConversionApi) => {
                const { writer, consumable, mapper } = conversionApi;

                const contentElement = this.getContentElement(editor, containerModelElement, conversionApi);
                if(!contentElement) {
                    return;
                }

                consumable.consume(contentElement, 'insert');
                const attributes = this.getInputAttributes(containerModelElement,(contentElement as Element), editor, consumable);
                this.consumeAllElementsInElement(containerModelElement, editor, consumable);

                const containerElement = writer.createContainerElement('input', attributes);
                mapper.bindElements((contentElement as Element), containerElement);
                mapper.bindElements(containerModelElement, containerElement);

                return containerElement;
            }
        });

        return conversion;
    }

    private mapPosition(editor: Editor) {
        editor.data.mapper.on('modelToViewPosition', (evt, data)=> {
            const positionParent = data.modelPosition?.parent;
	        if ( positionParent?.name != InputPlugin.MODEL_ENTITIES.container.model &&
                    positionParent?.name != InputPlugin.MODEL_ENTITIES.content.model &&
                    positionParent?.parent?.name  != InputPlugin.MODEL_ENTITIES.content.model) {
                return;
            }

            let viewElement = null;
            if(positionParent.name === InputPlugin.MODEL_ENTITIES.container.model) {
                 viewElement = data.mapper.toViewElement( positionParent );
            } else if(positionParent.name === InputPlugin.MODEL_ENTITIES.content.model) {
                viewElement = data.mapper.toViewElement( positionParent.parent );
            } else if(positionParent.parent?.name  === InputPlugin.MODEL_ENTITIES.content.model) {
                viewElement = data.mapper.toViewElement( positionParent.parent?.parent );
            }

            if(!viewElement) {
                return;
            }

            data.viewPosition = editor.data.mapper.findPositionIn(viewElement, 0);
            evt.stop();
        }, { priority: 'high'});
    }

    private getInputAttributes(containerModelElement: Element, contentElement: Element, editor: Editor, consumable: ModelConsumable): any {
        const stylesAttribute = this.convertAttributesInContent(editor, containerModelElement, consumable);
        const valueInInput = this.getValueInContentElement(contentElement);
        const isValid = containerModelElement.getAttribute(InputPlugin.MODEL_ENTITIES.isValid.model) === true;
        const id = containerModelElement.getAttribute(GlobalConstant.ATTRIBUTE_ID);
        const alias = containerModelElement.getAttribute(InputPlugin.MODEL_ENTITIES.alias.model);
        const helpNote = containerModelElement.getAttribute(InputPlugin.MODEL_ENTITIES.help.model);
        const pattern = containerModelElement.getAttribute(InputPlugin.MODEL_ENTITIES.pattern.model);
        const transform = containerModelElement.getAttribute(InputPlugin.MODEL_ENTITIES.transform.model);
        const isLinked = containerModelElement.getAttribute(InputPlugin.MODEL_ENTITIES.isLinked.model) === true;
        const embeddedIn = containerModelElement.getAttribute(GlobalConstant.ATTRIBUTE_EMBEDDED_IN);

        const attributes = {
            [InputPlugin.MODEL_ENTITIES.type.dataView]: containerModelElement.getAttribute(InputPlugin.MODEL_ENTITIES.type.model),
            value: valueInInput,
            [InputPlugin.MODEL_ENTITIES.isValid.dataView]: isValid,
            [GlobalConstant.ATTRIBUTE_ID]: id,
            [InputPlugin.MODEL_ENTITIES.name.dataView]: id,
            'class': InputPlugin.MODEL_ENTITIES.container.dataView ,
            [InputPlugin.MODEL_ENTITIES.alias.dataView]: alias,
            [InputPlugin.MODEL_ENTITIES.help.dataView]: helpNote,
            [InputPlugin.MODEL_ENTITIES.pattern.dataView]: pattern,
            [InputPlugin.MODEL_ENTITIES.transform.dataView]: transform,
            [InputPlugin.MODEL_ENTITIES.isLinked.dataView]: isLinked,
            [GlobalConstant.ATTRIBUTE_EMBEDDED_IN]: embeddedIn,
            'style': stylesAttribute.join(' ')
        };

        return attributes;
    }

    private getContentElement(editor: Editor, containerElement: Element, conversionApi: DowncastConversionApi) : Node | null {
        for (const { item } of editor.model.createRangeIn(containerElement)) {
            if (item.is('element', InputPlugin.MODEL_ENTITIES.content.model)) {
                //conversionApi.convertItem(item);
                return containerElement.getChild(0)!;
            }
        }

        return null;
    }

    private consumeAllElementsInElement(containerElement: Element, editor: Editor, consumable: ModelConsumable) {
        this.consumeAttributesInTextNodes(containerElement, editor, consumable);

        for (const { item } of editor.model.createRangeIn(containerElement)) {
            consumable.consume(item, 'insert');
        }
    }

    private convertAttributesInContent(editor: Editor,containerElement: Element, consumable: ModelConsumable) : string[] {
        for (const { item } of editor.model.createRangeIn(containerElement)) {

            if (item.is('element', InputPlugin.MODEL_ENTITIES.content.model)) {
                continue;
            }

            const nodeStyles = this.convertAttributesInTextNode(item as Element);
            if(nodeStyles.length > 0) {
                return nodeStyles;
            }
        }

        return [];
    }

    private convertAttributesInTextNode(element: Element): string[] {
        const styleField: string[] = [];
        Array.from(element.getAttributeKeys()).forEach((attributeKey: string) => {
            const attributeWithStyle = GlobalConstant.STYLE_ATTRIBUTES.find((attri: StyleAttributeModel) => attri.model === attributeKey);
            if(!attributeWithStyle) {
                return;
            }

            const style = attributeWithStyle.useValue ?
                FormatString(attributeWithStyle.dataView, element.getAttribute(attributeKey).toString()) :
                attributeWithStyle.dataView;

            styleField.push(style);
        });

        return styleField;
    }

    private consumeAttributesInTextNodes(containerElement: Element, editor: Editor, consumable: ModelConsumable): void {
        for (const { item } of editor.model.createRangeIn(containerElement)) {
            if (item.is('element', InputPlugin.MODEL_ENTITIES.content.model)) {
                continue;
            }

            const element = item as Element;
            this.consumeTextAttributes(element, consumable);
        }
    }

    private consumeTextAttributes(element: Element, consumable: ModelConsumable) {
        Array.from(element.getAttributeKeys()).forEach((attributeKey: string) => {
            const consumeAttributeName = `attribute:${attributeKey}:$text`;
            consumable.consume(element, consumeAttributeName);
        });
    }

    private getValueInContentElement(contentElement: Element): string {
        let valueInContent = '';
        if(!contentElement.is('element')) {
            return valueInContent;
        }

        Array.from(contentElement.getChildren()).forEach((child: Element) => {
            valueInContent = valueInContent + this.utilsService.getTextContent(child);
        });

        return valueInContent;
    }
}
