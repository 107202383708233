<form [formGroup]="searchForm" class="ctbox-search-box" [ngClass]="(currentScreenSize$ | async)">
    <mat-form-field class="ctbox-search-form {{customClass}}" [ngClass]="{'double' : !showFindButton && showClearButton(),'buttonsShow': showPreviousNextButton(),'totalResultHigh': totalResultOrdered > 99}">
        <input #searchTermInput matInput name="searchTermInput" formControlName="searchTermInput" (keydown)="$event.stopPropagation()" (keyup)="onSearchUpdated($event.target.value)" (keydown.enter)="onEnterKey($event)" autocomplete="off">
        <div class="ctbox-search-actions" matSuffix [ngClass]="{'double' : !showFindButton}" >
            <div class="show-results" *ngIf="showPreviousNextButton()" >
                <span>{{indexResultOrdered + 1}}/{{totalResultOrdered}}</span>
            </div>
            <ctbox-button *ngIf="showPreviousNextButton()" buttonIcon="expand_less" [customClass]="'small'" buttonColor="secondary" (buttonClick)="onPreviousResultButtonClick()"> </ctbox-button>
            <ctbox-button *ngIf="showPreviousNextButton()" buttonIcon="expand_more" [customClass]="'small'" buttonColor="secondary" (buttonClick)="onNextResultButtonClick()"> </ctbox-button>
            <ctbox-button *ngIf="showClearButton()" buttonIcon="close" buttonColor="secondary" [customClass]="'small'" (buttonClick)="onClearButtonClick()"> </ctbox-button>
            <ctbox-button *ngIf="!showFindButton" buttonType="primary" buttonIcon="search" buttonColor="secondary" class="ctbox-search-actions-search" (buttonClick)="onSearchButtonClick()"></ctbox-button>
        </div>
        <mat-error *ngIf="notMatchingElements && searchForm.get('searchTermInput').pristine && !searchForm.invalid">
            No hay documentos en tu Biblioteca que cumplan tus criterios de búsqueda. Te aconsejamos que los revises y realices una nueva búsqueda para encontrar la plantilla o modelo que necesitas.
        </mat-error>
        <mat-error *ngIf="searchForm.get('searchTermInput').invalid && searchForm.get('searchTermInput').pristine && !notMatchingElements && existsErrors">
            <ng-container *ngIf="searchForm.get('searchTermInput').errors?.maxlength">
                La cantidad de caracteres introducidos excede el límite
            </ng-container>
        </mat-error>
    </mat-form-field>
    <ctbox-button *ngIf="showFindButton" buttonType="primary" buttonIcon="search" customClass="ctbox-search-button" buttonText="Encontrar" buttonColor="santander" i18n-buttonText="@@Encontrar" (buttonClick)="onSearchButtonClick()"></ctbox-button>
</form>
