import { Injectable } from '@angular/core';
import { DowncastWriter, ViewContainerElement, ViewElement } from 'ckeditor5';
import { GlobalConstant } from '../../models/base/global-constant';
import { RepeatableFragmentPlugin } from '../../plugins/repeatable-fragment/repeatable-fragment-plugin';

@Injectable({
    providedIn: 'root'
})
export class RepeatableFragmentDataViewUtilsService {

    private readonly REQUIRED_CHILD_COUNT = 4;

    private readonly CHILD_DELETE_ACTION_POSITION = 0;
    private readonly CHILD_ADD_ACTION_POSITION = 1;
    private readonly CHILD_SPAN_POSITION = 2;
    private readonly REPEATABLE_FRAGMENT_CONTAINER_POSITION = 3;

    constructor() { }

    public createEmptyLinkDataView(id: string, viewWriter: DowncastWriter): ViewContainerElement {
        return viewWriter.createEmptyElement(
            GlobalConstant.ANCHOR,
             {
                id: id,
                class: [GlobalConstant.AN]
            }
        );
    }

    public createRepeatableFragmentContainerDataView(viewElement: any, viewWriter: DowncastWriter): ViewContainerElement {
        const id = viewElement.getAttribute(GlobalConstant.ATTRIBUTE_ID);
        const isValid = viewElement.getAttribute(GlobalConstant.ATTRIBUTE_IS_VALID);
        return viewWriter.createContainerElement(
            GlobalConstant.LABEL_DIV,
            {
                id: id,
                class: [RepeatableFragmentPlugin.MODEL_ENTITIES.container.dataView],
                [GlobalConstant.ATTRIBUTE_IS_VALID]: isValid
            }
        );
    }

    public createButtonMinusDataView(id: string, viewWriter: DowncastWriter): ViewContainerElement {
        const buttonMinusDataView = viewWriter.createContainerElement(GlobalConstant.ANCHOR, {
            onclick: `javascript:return supp('${id}');`,
            shape: 'rect',
            title: 'Eliminar un campo del formulario'
        });

        viewWriter.insert(
            viewWriter.createPositionAt(buttonMinusDataView, 0),
            viewWriter.createText('-')
        );

        return buttonMinusDataView;
    }

    public createButtonPlusDataView(id: string, viewWriter: DowncastWriter): ViewContainerElement {
        const buttonPlusDataView = viewWriter.createContainerElement(GlobalConstant.ANCHOR, {
            onclick: `javascript:return add('${id}');`,
            shape: 'rect',
            title: 'Añadir un campo del formulario'
        });

        viewWriter.insert(
            viewWriter.createPositionAt(buttonPlusDataView, 0),
            viewWriter.createText('+')
        );

        return buttonPlusDataView;
    }

    public createSpanActionDataView(viewWriter: DowncastWriter): ViewContainerElement {
        return viewWriter.createContainerElement(GlobalConstant.SPAN, { class: 'plusminusgroup-text'});
    }

    public createRepeatableFragmentDataView(id: string, viewWriter: DowncastWriter): ViewContainerElement {
        return viewWriter.createContainerElement(GlobalConstant.LABEL_DIV, { id: `${id}_0`} );
    }

    public createRepeatableFragmentDescriptionDataView(viewWriter: DowncastWriter): ViewContainerElement {
        return viewWriter.createContainerElement(GlobalConstant.LABEL_DIV, { class: RepeatableFragmentPlugin.MODEL_ENTITIES.description.dataView } );
    }

    public createRepeatableFragmentContentDataView(id: string, position: string, viewWriter: DowncastWriter): ViewContainerElement {
        return viewWriter.createContainerElement(GlobalConstant.LABEL_DIV, { id: `${id}_${position}`} );
    }

    public isRepeatableFragment(viewItem: ViewElement): boolean {
        if (viewItem.childCount < this.REQUIRED_CHILD_COUNT) {
            return false;
        }

        if (!this.checkRequiredLinks(viewItem)) {
            return false;
        }

        if (!this.checkRequiredSpan(viewItem)) {
            return false;
        }

        if (!this.checkRepeatableFragmentDiv(viewItem)) {
            return false;
        }

        return true;
    }

    private checkRequiredLinks(viewItem: ViewElement): boolean {
        const childDelete = viewItem.getChild(this.CHILD_DELETE_ACTION_POSITION);
        const childAdd = viewItem.getChild(this.CHILD_ADD_ACTION_POSITION);
        return !!childDelete && !!childAdd && childDelete.is(GlobalConstant.ELEMENT, GlobalConstant.ANCHOR) && childAdd.is(GlobalConstant.ELEMENT, GlobalConstant.ANCHOR);
    }

    private checkRequiredSpan(viewItem: ViewElement): boolean {
        const childSpan = viewItem.getChild(this.CHILD_SPAN_POSITION);
        return !!childSpan && childSpan.is(GlobalConstant.ELEMENT, GlobalConstant.SPAN) && childSpan.hasClass('plusminusgroup-text');
    }

    private checkRepeatableFragmentDiv(viewItem: ViewElement): boolean {
        const childDiv = viewItem.getChild(this.REPEATABLE_FRAGMENT_CONTAINER_POSITION);
        if (!childDiv || !childDiv.is(GlobalConstant.ELEMENT, GlobalConstant.LABEL_DIV) || !childDiv.hasAttribute(GlobalConstant.ATTRIBUTE_ID)) {
            return false;
        }

        for (const child of (childDiv as ViewElement).getChildren()) {
            if (child.is(GlobalConstant.ELEMENT, GlobalConstant.LABEL_DIV) && child.hasAttribute(GlobalConstant.ATTRIBUTE_ID)) {
                return true;
            }
        }

        return false;
    }
}

