import { Editor, Writer, Item } from "ckeditor5";
import { HelpNotePlugin } from "../../plugins/help-note/help-note-plugin";
import { BaseCommand } from "../base-command";

export default class DeleteHelpNoteCommand extends BaseCommand {

    labelDataModel = HelpNotePlugin.MODEL_ENTITIES.container.model;

    constructor(editor: Editor) {
        super(editor);
    }

    public override execute(id: string): void {
        const { editor } = this;;

        editor.model.change((writer: Writer) => {
            const range = writer.createRangeIn(editor.model.document.getRoot()!);

            const inputModel: Item = this.getElementWithId(range, id)?.item;

            writer.remove(inputModel);
        });
    }
}
