import { GlobalConstant } from './../../models/base/global-constant';
import { SignatureModel } from '../../models/signature/signature-model';
import { BaseCommand } from '../base-command';
import { SignaturePlugin } from '../../plugins/signature/signature-plugin';
import { InputPlugin } from '../../plugins/input/input-plugin';
import { Writer } from 'ckeditor5';

export default class AddSignatureCommand extends BaseCommand {

    public labelDataModel = SignaturePlugin.MODEL_ENTITIES.class.dataView;

    public override execute(value: SignatureModel): void {
        const editor = this.editor;

        editor.model.change((writer: Writer) => {
            const signature = writer.createElement('signature', {
                [SignaturePlugin.MODEL_ENTITIES.attributeRoleIsStored.model]: value.role,
                [GlobalConstant.ATTRIBUTE_ID]: value.id
            });

            editor.model.insertObject( signature, null, null, {setSelection: 'after'} );
        } );
    }

    public override refresh(): void {
        super.refresh();

        if (!this.isEnabled) {
            this.disableCommandIfCursorInside(InputPlugin.MODEL_ENTITIES.content.model);
        }
    }
}
