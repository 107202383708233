import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input } from '@angular/core';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { ErrorWmlToHtmlOperationDTO, WmlToHtmlConverterService } from 'src/app/api';

@Component({
    selector: 'app-drag-and-drop',
    templateUrl: './drag-and-drop.component.html',
    styleUrls: ['./drag-and-drop.component.scss']
})
export class DragAndDropComponent implements OnInit {

    @Output() fileDroppedDoc = new EventEmitter<File>();

    @Input() maximunFileSize: number = 10 * 1024 * 1024;
    @Input() maximunNumberFiles = 10;
    @Input() allowedExtensions: Array<string> = ['docx'];
    @Input() description: string;

    @ViewChild('wrongExtensionMessage') wrongExtensionMessage: ElementRef;

    public message: string;
    public files: any[] = [];

    constructor(public genericDialogService: GenericDialogService,
        private uploadService: WmlToHtmlConverterService) { }

    public ngOnInit(): void {
        this.description = this.description ? this.description : $localize`:@@CrearContratoSubirDocumentoDescripcion:Subir un documento de tu ordenador a ContractBOX`;
    }

    public onFileDropped(files: FileList) {
        if (files.length > this.maximunNumberFiles) {
            this.maximunNumberFilesWarning();
            return;
        }
        this.importFile(files[0]);
    }

    public fileBrowseHandler(files: File[]) {
        if (files.length > this.maximunNumberFiles) {
            this.maximunNumberFilesWarning();
            return;
        }
        this.importFile(files[0]);
    }

    public maximunNumberFilesWarning() {
        this.message = $localize`:@@MultipleImportedFilesMensaje:Estás arrastrando más de un fichero. Selecciona un documento y vuelve a intentarlo.`;
        this.genericDialogService.showMessage(this.message);
    }

    public deleteFile(index: number) {
        this.files.splice(index, 1);
    }

    public updateFileProgress(progress: number) {
        this.files[0].progress = progress;
    }

    public removeLastFile() {
        this.deleteFile(0);
    }

    public importFile(file: File) {
        if (!this.allowedExtensions.includes(file.name.split('.').pop())) {
            this.message = $localize`:@@ExtensionFallidaMensaje:El formato del documento no es válido. Selecciona otro documento y vuelve a intentarlo.`;
            this.genericDialogService.showMessage(this.message);
            return;
        }

        if (file.size > this.maximunFileSize) {
            this.message = $localize`:@@FicheroMuyPesadoMensaje:El documento tiene un tamaño superior al permitido (10 MB)`;
            this.genericDialogService.showMessage(this.message);
            return;
        }

        this.files.push(file);
        this.fileDroppedDoc.emit(file);
    }

    public formatBytes(bytes, decimals) {
        if (bytes === 0) {
            return '0 Bytes';
        }
        const k = 1024;
        const dm = decimals <= 0 ? 0 : decimals || 2;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    public manageUploadFileError(error: any) {
        this.uploadService.wmlToHtmlConverterErrorDescriptionGet().subscribe((errorDescriptions: ErrorWmlToHtmlOperationDTO) => {
            let message = $localize`:@@ErrorAlConvertirMensaje:No se ha podido realizar la subida de este documento. Inténtalo de nuevo en unos minutos.`;
            if (error.error?.File && error.error.File.length > 0) {
                const errorDescription = error.error.File[0];
                if (errorDescription === errorDescriptions.hasConversionErrorMessage) {
                    message = $localize`:@@ErrorFalloConversorMensaje:El contenido del documento no se puede convertir.`;
                } else if (errorDescription === errorDescriptions.hasTimeoutOperationMessage) {
                    message = $localize`:@@ErrorTimeoutAlConvertirMensaje:El contenido del documento ha excedido el tiempo de procesamiento y la operación ha sido cancelada. Inténtalo de nuevo en unos minutos.`;
                } else if (errorDescription === errorDescriptions.isDocumentToConvertIsEmptyMessage) {
                    message = $localize`:@@ErrorDocumentoVacioAlConvertirMensaje:El contenido del documento que intenta subir en ContractBox está vacio.`;
                }
            }
            this.genericDialogService.showMessage(message);
        }, () => {
            const message = $localize`:@@ErrorAlConvertirMensaje:No se ha podido realizar la subida de este documento. Inténtalo de nuevo en unos minutos.`;
            this.genericDialogService.showMessage(message);
        });
    }

}
