import { GlobalConstant } from './../../models/base/global-constant';
import { Injectable } from '@angular/core';
import { ViewElement } from 'ckeditor5';

@Injectable({
    providedIn: 'root'
})
export class OptionalFragmentCheckDataViewUtilsService {

    private readonly REQUIRED_CHILD_COUNT = 3;

    private readonly CHILD_INPUT_POSITION = 0;
    private readonly CHILD_DESCRIPTION_POSITION = 1;
    private readonly CHILD_CONTENT_POSITION = 2;

    constructor() { }

    public isOptionalFragment(viewItem: ViewElement): boolean {
        if (viewItem.childCount < this.REQUIRED_CHILD_COUNT) {
            return false;
        }

        if (!this.checkInput(viewItem)) {
            return false;
        }

        if (!this.checkDescription(viewItem)) {
            return false;
        }

        if (!this.checkContentParagraph(viewItem)) {
            return false;
        }

        return true;
    }

    private checkInput(viewItem: ViewElement): boolean {
        const childInput = viewItem.getChild(this.CHILD_INPUT_POSITION);
        return !!childInput && childInput.is(GlobalConstant.ELEMENT, GlobalConstant.INPUT);
    }

    private checkDescription(viewItem: ViewElement): boolean {
        const childDescription = viewItem.getChild(this.CHILD_DESCRIPTION_POSITION);
        return !!childDescription && childDescription.is(GlobalConstant.ELEMENT, GlobalConstant.LABEL_DIV) && childDescription.hasClass('hDesc');
    }

    private checkContentParagraph(viewItem: ViewElement): boolean {
        const childContent = viewItem.getChild(this.CHILD_CONTENT_POSITION);
        return !!childContent && childContent.is(GlobalConstant.ELEMENT, GlobalConstant.PARAGRAPH);
    }
}
