<div class="account">
    <ng-container *ngIf="!hasUserInfoValue() && showAccessButton">
        <ctbox-button buttonType="primary" buttonText="Acceder" i18n-buttonText="@@Acceder" (buttonClick)="openLoginForm()"></ctbox-button>
    </ng-container>
    <div class="user-data" *ngIf="hasUserInfoValue()">
        <ctbox-button buttonType="text" buttonIconSvg="person" buttonText="Mi Cuenta" i18n-buttonText="@@MiCuenta" customClass="text-default" [matMenuTriggerFor]="menuUser"></ctbox-button>
        <mat-menu #menuUser="matMenu" class="user-data">
            <div class="user-data-details">
                <p class="full-name">
                    <span class="surname">{{(loginStatusUserInfo | async)?.surname}}</span>
                    <span>,&nbsp;</span>
                    <span class="name">{{(loginStatusUserInfo | async)?.name}}</span>
                </p>
                <p class="email">{{(loginStatusUserInfo | async)?.email}}</p>
                <ctbox-button *ngIf="showAccessButton" mat-menu-item buttonType="mat-menu-item" buttonColor="account" customClass="mat-account-login" buttonText="Acceder a mi Cuenta" i18n-buttonText="@@AccederAMiCuenta" (buttonClick)="openMyAccount()"></ctbox-button>
                <ctbox-button *ngIf="isAdmin && canManageLicenses" buttonType="mat-menu-item" buttonColor="account" customClass="mat-account-admin" buttonText="Administrar licencias" i18n-buttonText="@@AdministrarLicencias" (buttonClick)="openMyLicenses()"></ctbox-button>
                <ctbox-button buttonType="mat-menu-item" buttonColor="account" customClass="mat-account-logout" buttonText="Salir" i18n-buttonText="@@Salir" (buttonClick)="closeSession()"></ctbox-button>
            </div>
        </mat-menu>
    </div>
</div>
