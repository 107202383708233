import { Injectable } from '@angular/core';
import { Conversion, DowncastWriter, Element, toWidget, ViewContainerElement } from 'ckeditor5';
import { HelpNotePlugin } from '../../plugins/help-note/help-note-plugin';
import { HelpNoteModel } from '../../models/help-note/help-note-model';
import { GlobalConstant } from '../../models/base/global-constant';
import { CkeditorConstant } from '../../models/base/ckeditor-constant';

@Injectable({
    providedIn: 'root'
})
export class HelpNoteModelToEditorViewConverterService {

    public configureConverter(conversion: Conversion): void {
        conversion.for("editingDowncast").elementToElement({
            model: HelpNotePlugin.MODEL_ENTITIES.container.model,
            view: (modelItem: Element, { writer: viewWriter }) => {
                const helpNoteModel = this.getModel(modelItem);
                const viewElement = this.createViewContainer(viewWriter, helpNoteModel);
                return toWidget(viewElement, viewWriter);
            },
        });
    }

    private getModel(modelItem: Element): HelpNoteModel {
        return {
            id: modelItem.getAttribute(GlobalConstant.ATTRIBUTE_ID) as string,
            content: modelItem.getAttribute(HelpNotePlugin.MODEL_ENTITIES.content.model) as string,
            embeddedIn: modelItem.getAttribute(GlobalConstant.ATTRIBUTE_EMBEDDED_IN) as string
        };
    }

    private createViewContainer(writer: DowncastWriter, helpNoteModel: HelpNoteModel): ViewContainerElement {
        return writer.createContainerElement(
            GlobalConstant.LABEL_SPAN,
            {
                [GlobalConstant.ATTRIBUTE_ID]: helpNoteModel.id,
                [GlobalConstant.ATTRIBUTE_CLASS]: [GlobalConstant.CONTAINER_CLASS_EDITION_VIEW, HelpNotePlugin.MODEL_ENTITIES.container.editionView, HelpNotePlugin.MODEL_ENTITIES.textClass.editionView].join(" "),
                [HelpNotePlugin.MODEL_ENTITIES.content.editionView]: helpNoteModel.content,
                [HelpNotePlugin.TOOLTIP_TEXT_CLASS]: HelpNotePlugin.MODEL_ENTITIES.textClass.editionView,
                [HelpNotePlugin.MODEL_ENTITIES.position.editionView]: CkeditorConstant.POSITION_TOOLTIP.north,
                [GlobalConstant.ATTRIBUTE_EMBEDDED_IN]: helpNoteModel.embeddedIn
            }
        );
    }
}
