import { Component, Input, Output, EventEmitter, OnInit, OnChanges, SimpleChanges, OnDestroy, ViewEncapsulation } from '@angular/core';
import { DocumentStatusCodes, DocumentStatusTextValues, DocumentStatusValues } from 'src/app/core/standard/enums/documents/document-status.enum';
import { UserPermissionsOnDocumentDTO, MasterData, DocumentDTO } from 'src/app/api';
import { TypeUserService, TypeUserValues } from 'src/app/core/shared/services/type-user/type-user.service';
import { IDocumentStateService } from './service/document-state.service.interface';
import { Subscription } from 'rxjs';
import { IDocumentEditionService } from 'src/app/pages/standard/contracts-library/services/document-edition/document-edition.service.interface';

@Component({
    selector: 'app-document-state',
    templateUrl: './document-state.component.html',
    styleUrls: ['./document-state.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class DocumentStateComponent implements OnInit, OnChanges, OnDestroy {

    @Input() document: DocumentDTO;
    @Input() documentState: DocumentStatusCodes;
    @Input() allowSignedInCreation: boolean;
    @Input() allowSignedInEdition: boolean;
    @Input() hasWorkflow = false;
    @Input() modeChangeState = true;
    @Input() isListAction = false;
    @Input() userPermissionsDocument: UserPermissionsOnDocumentDTO;

    @Output() documentStateChanged = new EventEmitter<MasterData>();
    @Output() stateChange = new EventEmitter<{ document: DocumentDTO, state: DocumentStatusCodes }>();

    public selectedState: MasterData;
    public get documentStatusCodes(): typeof DocumentStatusCodes {
        return DocumentStatusCodes;
    }

    public stateTooltipAction: string = $localize`:@@ContratoCambiarEstadoHint:Cambiar estado`;
    public stateText: string = DocumentStatusTextValues[DocumentStatusCodes.Draft];
    public stateTooltip: string;
    public stateClass: string = DocumentStatusValues[DocumentStatusCodes.Draft];
    public statusIconClass = ' filled xsmall state ';
    public isDisabled: boolean;
    public documentStatuses: Array<MasterData>;

    private documentSubscription: Subscription;
    private StatusesSubscription: Subscription;

    constructor(public typeUserService: TypeUserService,
                private documentStateService: IDocumentStateService,
                private documentEditionService: IDocumentEditionService,) {
        this.documentSubscription = this.documentEditionService.getDocumentSubscription().subscribe((document: DocumentDTO) => {
            this.document = document;
            if (document?.documentStatus) {
                this.setDocumentState(document?.documentStatus.code);
            }
        });
    }

    public ngOnInit() {

        if (this.document?.documentStatus) {
            this.documentState = this.document.documentStatus.code;
        } else if (this.document === undefined || this.documentState === undefined ||
                (this.document !== null &&  !this.document.documentStatus)) {
            this.documentState = DocumentStatusCodes.Draft;
        }

        this.setDocumentState(this.documentState);

        this.stateTooltip = this.canChangeState() ? this.stateTooltipAction : (this.isListAction ? DocumentStatusTextValues[this.documentState] : '');

        this.isDisabled = this.userPermissionsDocument ? !this.userPermissionsDocument.canChangeState : true;

        this.StatusesSubscription = this.documentStateService.getCurrentStates().subscribe((allStatuses: MasterData[]) => {
            if (allStatuses.length < 1) {
                return;
            }

            this.documentStatuses = allStatuses;
            this.setDocumentState(this.documentState);
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.document && this.document) {
            this.documentState = this.document.documentStatus.code;
            this.setDocumentState(this.documentState);
        }
        if (changes.documentState || changes.documentStatuses) {
            this.setDocumentState(this.documentState);
        }
    }

    public ngOnDestroy(): void {
        if (this.StatusesSubscription) {
            this.StatusesSubscription.unsubscribe();
        }

        if (this.documentSubscription) {
            this.documentSubscription.unsubscribe();
        }
    }

    public setDocumentState(state: DocumentStatusCodes) {
        this.documentState = state;
        this.stateText = DocumentStatusTextValues[state];
        let stateClass = this.modeChangeState ? ' change ' : ' no-border ';

        if (TypeUserValues.GUEST_USER && state === DocumentStatusCodes.ForSignature) {
            this.stateText = $localize`:@@PendienteDeFirmas:Pendiente de firmas`;
        }

        if (this.isListAction) {
            this.stateText = '';
            stateClass = ' no-border ';
        }

        this.stateClass = this.statusIconClass + DocumentStatusValues[state] + stateClass;

        if (this.documentStatuses) {
            this.documentStateChanged.emit(this.documentStatuses.find(ds => ds.code === state));
        }

        if (this.document?.documentStatus) {
            this.stateChange.emit({ document: this.document, state });
            this.document.documentStatus.code = state;
        }
    }

    public isStateChangeable(state: DocumentStatusCodes): boolean {
        if (state === DocumentStatusCodes.ForSignature || this.hasWorkflow ||
            state === this.documentState) {
            return false;
        }

        if (state === DocumentStatusCodes.Draft && this.documentState !== DocumentStatusCodes.Draft) {
            return false;
        }

        if (state == DocumentStatusCodes.Signed && !this.canSeeSigned()){
            return false;
        }

        return true;
    }

    public canChangeState(): boolean {
        return (TypeUserValues.REGISTERED_USER && this.documentState !== this.documentStatusCodes.ForSignature &&
            this.userPermissionsDocument?.canChangeState &&
            (this.documentState !== this.documentStatusCodes.Signed || this.canSeeSigned())) &&
            this.modeChangeState && !this.hasWorkflow;
    }

    public canSeeSigned(): boolean {
        return this.allowSignedInCreation || this.allowSignedInEdition;
    }

    public setDocumentStatusText(state: DocumentStatusCodes): string | void {
        const text = DocumentStatusTextValues[state];
        return text;
    }

    public setDocumentStatusClass(state: DocumentStatusCodes): string | void {
        const customclass = ' mat-menu-item no-border' + this.statusIconClass + DocumentStatusValues[state];
        return customclass;
    }
}
