import { Injectable } from '@angular/core';
import { Conversion, DowncastWriter, Element, ViewContainerElement } from 'ckeditor5';
import { DataViewModel } from '../../models/base/data-view-model';
import { GlobalConstant } from '../../models/base/global-constant';
import { HelpNoteModel } from '../../models/help-note/help-note-model';
import { HelpNotePlugin } from '../../plugins/help-note/help-note-plugin';

@Injectable({
    providedIn: 'root'
})
export class HelpNoteModelToDataViewConverterService {

    public configureConverter(conversion: Conversion): void {
        conversion.for("dataDowncast").elementToStructure({
            model: HelpNotePlugin.MODEL_ENTITIES.container.model,
            view: (modelItem: Element, { writer: viewWriter }) => {
                const model = this.getModel(modelItem);
                const dataViewModel = this.createDataViewModel(modelItem);
                const viewElement = this.createView(dataViewModel, model, viewWriter);
                return viewElement;
            }
        });
    }

    private getModel(element: Element): HelpNoteModel {
        const getAttribute = this.getAttributteFromElement(element);
        return {
            id: getAttribute(GlobalConstant.ATTRIBUTE_ID),
            content: getAttribute(HelpNotePlugin.MODEL_ENTITIES.content.model),
            embeddedIn: getAttribute(GlobalConstant.ATTRIBUTE_EMBEDDED_IN)
        }
    }

    private createDataViewModel(modelItem: Element): DataViewModel {
        const getAttribute = this.getAttributteFromElement(modelItem);
        return {
            id: getAttribute(GlobalConstant.ATTRIBUTE_ID),
            class: HelpNotePlugin.MODEL_ENTITIES.cite.dataView,
            container: GlobalConstant.LABEL_CITE,
        };
    }

    private createView(dataViewModel: DataViewModel, modelElement: HelpNoteModel, viewWriter: DowncastWriter): ViewContainerElement {
        return viewWriter.createContainerElement(dataViewModel.container,
            {
                [GlobalConstant.ATTRIBUTE_ID]: modelElement.id,
                [GlobalConstant.ATTRIBUTE_CLASS]: dataViewModel.class,
                [GlobalConstant.ATTRIBUTE_EMBEDDED_IN]: modelElement.embeddedIn
            },[
                viewWriter.createText(modelElement.content.toString())
            ]);
    }

    private getAttributteFromElement(element: Element) {
        return (attr: string) => element.getAttribute(attr)?.toString() ?? '';
    }
}
