import { Injectable } from "@angular/core";
import { ViewContainerElement } from "ckeditor5";
import { HelpNoteModel } from "../../models/help-note/help-note-model";
import { GlobalConstant } from "../../models/base/global-constant";
import { HelpNotePlugin } from "../../plugins/help-note/help-note-plugin";

@Injectable({
    providedIn: "root",
})
export class HelpNoteEditionViewUtilsService {
    
    public getModel(containerElement: ViewContainerElement): HelpNoteModel {
        const getAttribute = (attr: string) => containerElement.getAttribute(attr) ?? '';
        return {
            id: getAttribute(GlobalConstant.ATTRIBUTE_ID),
            content: getAttribute(HelpNotePlugin.MODEL_ENTITIES.content.editionView)
        }
    }
}