import { Element, Writer } from 'ckeditor5';
import { RepeatableFragmentCommand } from './repeatable-fragment-command';
import { RepeatableFragmentPlugin } from '../../plugins/repeatable-fragment/repeatable-fragment-plugin';

export default class DeleteRepeatableFragmentCommand extends RepeatableFragmentCommand {

    public override execute(id: string): void {
        const editor = this.editor;

        editor.model.change((writer: Writer) => {
            const range = writer.createRangeIn(editor.model.document.getRoot()!);

            const elementToDelete: Element = this.getElementWithId(range, id).item as Element;

            let contentFragmentToMove: Element;
            for (const child of elementToDelete.getChildren()) {
                const childElement = child as Element;
                if (childElement.name === RepeatableFragmentPlugin.MODEL_ENTITIES.fragment.model) {
                    contentFragmentToMove = childElement;
                    break;
                }
            }

            if (contentFragmentToMove && contentFragmentToMove.childCount > 0) {
                this.moveContentAfterDeleteComponent(writer, contentFragmentToMove, elementToDelete);
            }

            writer.remove(elementToDelete);
        });
    }

    private moveContentAfterDeleteComponent(writer: Writer, contentFragmentToMove: Element, containerModel: Element): void {
        const contentChild = Array.from(contentFragmentToMove.getChildren()).find(child => (child as Element).name === RepeatableFragmentPlugin.MODEL_ENTITIES.content.model);
        writer.insert(contentChild, containerModel, 'after');
        this.removeContentDivAndExtractChildrenToParent(writer, contentChild as Element);

    }

    private removeContentDivAndExtractChildrenToParent(writer: Writer, contentToMove: Element): void {
        writer.unwrap(contentToMove);
    }
}
