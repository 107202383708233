import { Injectable } from "@angular/core";
import { Schema } from "ckeditor5";
import { HelpNotePlugin } from "../../plugins/help-note/help-note-plugin";
import { GlobalConstant } from "../../models/base/global-constant";
import { INHERIT_ALL_FROM_VALUES } from "../schema-values";

@Injectable({
    providedIn: 'root'
})
export class HelpNoteSchemaService {

    public defineSchema(schema: Schema): void {
        schema.register(HelpNotePlugin.MODEL_ENTITIES.container.model, {
            inheritAllFrom: INHERIT_ALL_FROM_VALUES.INLINE_OBJECT,
            allowAttributes: [
                GlobalConstant.ATTRIBUTE_ID,
                HelpNotePlugin.MODEL_ENTITIES.content.model,
                GlobalConstant.ATTRIBUTE_EMBEDDED_IN
            ]
        });
    }
}
