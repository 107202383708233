import { DocumentFragment, Editor, Element, Writer } from "ckeditor5";
import { GlobalConstant } from "../../models/base/global-constant";
import { HelpNoteModel } from "../../models/help-note/help-note-model";
import { HelpNotePlugin } from "../../plugins/help-note/help-note-plugin";
import { InputPlugin } from "../../plugins/input/input-plugin";
import { BaseCommand } from "../base-command";

export default class AddEditHelpNoteCommand extends BaseCommand {

    labelDataModel = HelpNotePlugin.MODEL_ENTITIES.container.model;

    constructor(editor: Editor) {
        super(editor);
    }

    public override execute(value: HelpNoteModel): void {
        const editor = this.editor;

        editor.model.change((writer: Writer) => {
            const helpNoteElement = this.createHelpNoteElement(writer, value);
            const helpNoteModelFragment = this.createHelpNoteModelFragment(editor, value);

            editor.model.insertObject(helpNoteElement, null, null, { setSelection: 'after' });
            editor.model.insertContent(helpNoteModelFragment, writer.createPositionAt(helpNoteElement, 0));
        });
    }

    public override refresh(): void {
        super.refresh();

        if (!this.isEnabled) {
            this.disableCommandIfCursorInside(InputPlugin.MODEL_ENTITIES.content.model);
        }
    }

    private createHelpNoteElement(writer: Writer, model: HelpNoteModel): Element {
        return writer.createElement(HelpNotePlugin.MODEL_ENTITIES.container.model, {
            [GlobalConstant.ATTRIBUTE_ID]: model.id ? model.id: '',
            [HelpNotePlugin.MODEL_ENTITIES.content.model]: model.content ? model.content : '',
        });
    }

    private createHelpNoteModelFragment(editor: Editor, model: HelpNoteModel): DocumentFragment {
        const content = model.content;
        const viewFragment = editor.data.processor.toView(content);
        return editor.data.toModel(viewFragment);
    }
}
