import { Injectable } from '@angular/core';
import { Conversion, Element, UpcastDispatcher, ViewConsumable, ViewElement, Writer } from 'ckeditor5';
import { RepeatableFragmentPlugin } from '../../plugins/repeatable-fragment/repeatable-fragment-plugin';
import { GlobalConstant } from '../../models/base/global-constant';

@Injectable({
    providedIn: 'root'
})
export class RepeatableFragmentDataViewToModelConverterService {

    private readonly CHILD_DELETE_ACTION_POSITION = 0;
    private readonly CHILD_ADD_ACTION_POSITION = 1;
    private readonly CHILD_SPAN_POSITION = 2;
    private readonly REPEATABLE_FRAGMENT_CONTAINER_POSITION = 3;
    private readonly REQUIRED_CHILD_COUNT = 4;

    private readonly POSIBLE_CHILD_DESCRIPTION_POSITION = 0;
    private readonly NON_REPEATABLE_FRAGMENT_CONTAINER_CHILD_COUNT = 1;

    private readonly addParagrahpsMessage = $localize`:@@AñadirMasParrafosIguales:Añadir más párrafos iguales`;

    constructor() {}

    public configureConverters(conversion: Conversion): void {
        this.containerAndContentConversion(conversion);
    }

    private containerAndContentConversion(conversion: Conversion): void {
        conversion.for("upcast").add((dispatcher: UpcastDispatcher) => {
            dispatcher.on('element:div', (_evt, data, conversionApi) => {
                const {
                    consumable, writer, safeInsert, convertChildren, updateConversionResult
                } = conversionApi;

                const viewItem = data.viewItem;

                if (!this.isValidRepeatableFragmentElement(viewItem, consumable)) {
                    return;
                }

                const containerModelElement = this.createContainerModelElement(writer, viewItem);

                if (!safeInsert(containerModelElement, data.modelCursor)) {
                    return;
                }

                consumable.consume(viewItem, containerModelElement);

                this.convertChildItems(writer, convertChildren, viewItem, containerModelElement, consumable);

                updateConversionResult(containerModelElement, data);

            }, { priority: 'high' });
        });
    }

    private isValidRepeatableFragmentElement(viewItem: ViewElement, consumable: ViewConsumable): boolean {
        if (!this.isValidContainer(viewItem, consumable))
            return false;

        if(!this.areValidMandatoryChildItems(viewItem, consumable))
            return false;

        return true;
    }

    private isValidContainer(viewItem: ViewElement, consumable: ViewConsumable): boolean {
        const containerToConvert = { name: true, classes: RepeatableFragmentPlugin.MODEL_ENTITIES.container.dataView };
        return consumable.test(viewItem, containerToConvert)!
    }

    private areValidMandatoryChildItems(viewItem: ViewElement, consumable: ViewConsumable): boolean {
        if (viewItem.childCount < this.REQUIRED_CHILD_COUNT)
            return false;

        if (!this.checkRequiredLinks(viewItem, consumable))
            return false;

        if (!this.checkRequiredSpan(viewItem, consumable))
            return false;

        if (!this.checkRepeatableFragmentDiv(viewItem, consumable))
            return false;

        return true;
    }

    private checkRequiredLinks(viewItem: ViewElement, consumable: ViewConsumable): boolean {
        const checkLinkElement = { name: true, type: 'a' };

        const childDeleteItem = viewItem.getChild(this.CHILD_DELETE_ACTION_POSITION);
        const childAddItem = viewItem.getChild(this.CHILD_ADD_ACTION_POSITION);

        if (!childDeleteItem || !childAddItem)
            return false;

        let testResult = consumable.test(childDeleteItem, checkLinkElement);

        if (testResult == null && !testResult)
            return false;

        testResult = consumable.test(childAddItem, checkLinkElement);

        if (testResult == null && !testResult)
            return false;

        return true;
    }

    private checkRequiredSpan(viewItem: ViewElement, consumable: ViewConsumable): boolean {
        const childSpanItem = viewItem.getChild(this.CHILD_SPAN_POSITION);

        if (!childSpanItem)
            return false;

        const checkSpanViewElement = { name: true, classes: 'plusminusgroup-text', type: 'span' };
        const testResult = consumable.test(childSpanItem, checkSpanViewElement);

        if (testResult == null && !testResult)
            return false;

        return true;
    }

    private checkRepeatableFragmentDiv(viewItem: ViewElement, consumable: ViewConsumable): boolean {
        const childDivItem = viewItem.getChild(this.REPEATABLE_FRAGMENT_CONTAINER_POSITION);

        if (!childDivItem)
            return false;

        const checkDivViewElement = { name: true, type: GlobalConstant.LABEL_DIV, attributes: [GlobalConstant.ATTRIBUTE_ID] };

        if (!consumable.test(childDivItem, checkDivViewElement))
            return false;

        for (const child of (childDivItem as ViewElement).getChildren()) {
            if (child.is(GlobalConstant.ELEMENT, GlobalConstant.LABEL_DIV) && child.hasAttribute(GlobalConstant.ATTRIBUTE_ID)) {
                if (consumable.test(child, checkDivViewElement)) {
                    return true;
                }
            }
        }

        return false;
    }

    private checkDivIsDescription(divViewItem: ViewElement, consumable: ViewConsumable): boolean {
        const checkDivIsDescription = { name: true, classes: RepeatableFragmentPlugin.MODEL_ENTITIES.description.dataView, type: GlobalConstant.LABEL_DIV };
        return consumable.test(divViewItem, checkDivIsDescription) ?? false;
    }

    private createContainerModelElement(writer: Writer, viewItem: ViewElement): any {
        const repeatableFragmentContainerChildItem = viewItem.getChild(this.REPEATABLE_FRAGMENT_CONTAINER_POSITION) as ViewElement;

        const id = repeatableFragmentContainerChildItem.getAttribute(GlobalConstant.ATTRIBUTE_ID);
        const cleanId = id ? id.split('_')[0] : '';
        const embeddedIn = viewItem.getAttribute(GlobalConstant.ATTRIBUTE_EMBEDDED_IN) ? viewItem.getAttribute(GlobalConstant.ATTRIBUTE_EMBEDDED_IN) : "undefined";
        const isValid = viewItem.getAttribute(GlobalConstant.ATTRIBUTE_IS_VALID) ? viewItem.getAttribute(GlobalConstant.ATTRIBUTE_IS_VALID) : true;

        let repeatableFragmentCount = repeatableFragmentContainerChildItem.childCount;
        if ((repeatableFragmentContainerChildItem.getChild(this.POSIBLE_CHILD_DESCRIPTION_POSITION) as ViewElement).hasClass(RepeatableFragmentPlugin.MODEL_ENTITIES.description.dataView)) {
            repeatableFragmentCount = repeatableFragmentCount - this.NON_REPEATABLE_FRAGMENT_CONTAINER_CHILD_COUNT;
        }

        return writer.createElement(RepeatableFragmentPlugin.MODEL_ENTITIES.container.model, {
            [GlobalConstant.ATTRIBUTE_ID]: cleanId,
            [GlobalConstant.ATTRIBUTE_EMBEDDED_IN]: embeddedIn,
            [RepeatableFragmentPlugin.MODEL_ENTITIES.fragmentCount.model]: repeatableFragmentCount,
            [GlobalConstant.ATTRIBUTE_IS_VALID]: isValid
        });
    }

    private convertChildItems(writer: Writer, convertChildren: (viewItem: ViewElement, modelElement: Element) => void, viewItem: ViewElement, containerModelElement: Element, consumable: ViewConsumable): void {
        const match = { name: true };

        this.handleDeleteActionChild(viewItem, writer, containerModelElement, consumable, match);

        this.handleAddActionChild(viewItem, writer, containerModelElement, consumable, match);

        this.handleDescriptionActionChild(viewItem, writer, containerModelElement, consumable, match);

        this.handleRepeatableFragmentContainerChild(viewItem, writer, containerModelElement, consumable, convertChildren, match);
    }

    private handleDeleteActionChild(viewItem: ViewElement, writer: Writer, containerModelElement: any, consumable: ViewConsumable, match: any): void {
        const fragmentCount = containerModelElement.getAttribute(RepeatableFragmentPlugin.MODEL_ENTITIES.fragmentCount.model);
        const isDisabled = fragmentCount === 1 ? 'true' : 'false';
        const deleteActionChildItem = viewItem.getChild(this.CHILD_DELETE_ACTION_POSITION);
        const deleteActionElement = writer.createElement(RepeatableFragmentPlugin.MODEL_ENTITIES.action.model, {
            [RepeatableFragmentPlugin.MODEL_ENTITIES.dataType.model]: 'delete', 'disabled': isDisabled
        });

        writer.append(deleteActionElement, containerModelElement);
        consumable.consume(deleteActionChildItem, match);
    }

    private handleAddActionChild(viewItem: ViewElement, writer: Writer, containerModelElement: any, consumable: ViewConsumable, match: any): void {
        const addActionChildItem = viewItem.getChild(this.CHILD_ADD_ACTION_POSITION);
        const addActionElement = writer.createElement(RepeatableFragmentPlugin.MODEL_ENTITIES.action.model, {
            [RepeatableFragmentPlugin.MODEL_ENTITIES.dataType.model]: 'add', 'disabled': 'false'
        });

        writer.append(addActionElement, containerModelElement);
        consumable.consume(addActionChildItem, match);
    }

    private handleDescriptionActionChild(viewItem: ViewElement, writer: Writer, containerModelElement: any, consumable: ViewConsumable, match: any): void {
        const descriptionActionChildItem = viewItem.getChild(this.CHILD_SPAN_POSITION);
        const descriptionElement = writer.createElement(RepeatableFragmentPlugin.MODEL_ENTITIES.descriptionAction.model, {
            [RepeatableFragmentPlugin.MODEL_ENTITIES.dataType.model]: 'description'
        });

        const descriptionText = writer.createText(this.addParagrahpsMessage);

        writer.append(descriptionText, descriptionElement);

        writer.append(descriptionElement, containerModelElement);

        consumable.consume(descriptionActionChildItem, match);
    }

    private handleRepeatableFragmentContainerChild(viewItem: ViewElement, writer: Writer, containerModelElement: any, consumable: ViewConsumable,
        convertChildren: (viewItem: ViewElement, modelElement: Element) => void,match: any): void {

        const repeatableFragmentContainerChildItem = viewItem.getChild(this.REPEATABLE_FRAGMENT_CONTAINER_POSITION);

        const fragmentModelElement = writer.createElement(RepeatableFragmentPlugin.MODEL_ENTITIES.fragment.model);
        let position = 1;

        consumable.consume(repeatableFragmentContainerChildItem, match);

        if (repeatableFragmentContainerChildItem.is('element')) {
            for (const childItem of Array.from(repeatableFragmentContainerChildItem.getChildren())) {

                if (this.checkDivIsDescription(childItem as ViewElement, consumable)) {

                    const descriptionModelElement = writer.createElement(RepeatableFragmentPlugin.MODEL_ENTITIES.description.model);

                    convertChildren(childItem as ViewElement, descriptionModelElement);
                    writer.append(descriptionModelElement, fragmentModelElement);

                    consumable.consume(childItem, match);
                    continue;
                }

                const contentModelElement = writer.createElement(RepeatableFragmentPlugin.MODEL_ENTITIES.content.model, {
                    [RepeatableFragmentPlugin.MODEL_ENTITIES.fragmentPosition.model]: position
                });

                position++;

                convertChildren(childItem as ViewElement, contentModelElement);
                writer.append(contentModelElement, fragmentModelElement);

                consumable.consume(childItem, match);
            }
        }

        writer.append(fragmentModelElement, containerModelElement);
    }
}
