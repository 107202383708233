<div class="title">
    <ctbox-button buttonType="primary" buttonIcon="chevron_left" customClass="mat-stroked-button title-button-back" (buttonClick)="goBack()" *ngIf="hasButtonBack"></ctbox-button>
    <div class="title-content">
        <ng-container *ngIf="!isEditMode || !userPermissions || !userPermissions.canEditName">
            <ctbox-button buttonIcon="attach_file" buttonColor="secondary" *ngIf="isAnnexed" i18n-title="@@DocumentoAdicional" title="Documento adicional"></ctbox-button>
            <ctbox-button buttonIcon="content_copy" buttonColor="secondary" *ngIf="isPrincipal" i18n-title="@@DocumentoPrincipal" title="Documento principal"></ctbox-button>
            <div class="title-name" [innerHTML]="name | decodeHtml">
            </div>
        </ng-container>
        <ng-container *ngIf="canEditName">
            <input class="title-edit" #nameInput type="text" (keyup.enter)="finishEdit($event)" (focusout)="finishEdit($event)" *ngIf="isEditMode && (userPermissions && userPermissions.canEditName)" value="{{name}}">
            <ctbox-button buttonIcon="edit" buttonColor="secondary" (buttonClick)="editMode($event)"  *ngIf="(name !== '') && userPermissions && userPermissions.canEditName && !hideEditionButton"></ctbox-button>
        </ng-container>
    </div>
</div>
