import { DocumentFragment, Editor, Element, Writer } from "ckeditor5";
import { ClauseModel } from "../../models/clause/clause-model";
import { ClausesPlugin } from "../../plugins/clause/clauses-plugin";
import { InputPlugin } from "../../plugins/input/input-plugin";
import { LinkedFieldUtilsService } from "../../utils/linked-field/linked-field-utils.service";
import { BaseCommand } from "../base-command";
import { ElementPositionOffset } from "../../models/base/element-position-offset";

export default class ClauseAddCommand extends BaseCommand {
    private readonly linkedFieldPluginUtils: LinkedFieldUtilsService;
    labelDataModel = ClausesPlugin.MODEL_ENTITIES.class.model;

    constructor(editor: Editor) {
        super(editor);
        this.linkedFieldPluginUtils = new LinkedFieldUtilsService();
    }

    public override execute( value: ClauseModel ): void {
        const editor = this.editor;

        editor.model.change( (writer:Writer) => {

            value.description = this.linkedFieldPluginUtils.updateAliasAttributes(value.description, 'clauseAlias-');

            const clause = this.createClauseElement(writer, value);
            const modelFragement = this.createModelFragment(editor, value);

            editor.model.insertObject(clause, null, null, { setSelection: 'after' });
            editor.model.insertContent(modelFragement, writer.createPositionAt(clause, 0));

            this.setCursorPointerOnElement(editor, writer, clause.parent as Element, ElementPositionOffset.END);
        });
    }

    public override refresh(): void {
        super.refresh();

        if (!this.isEnabled) {
            this.disableCommandIfCursorInside(InputPlugin.MODEL_ENTITIES.content.model);
        }
    }

    private createClauseElement(writer: Writer, value: ClauseModel): Element {
        return writer.createElement(ClausesPlugin.MODEL_ENTITIES.class.model, {
            'id': 'id_' + value.id,
            'description': value.description
        });
    }

    private createModelFragment(editor: Editor, value: ClauseModel): DocumentFragment  {
        const content = value.description;
        const viewFragment = editor.data.processor.toView(content!);
        return editor.data.toModel(viewFragment);
    }
}
