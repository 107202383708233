import { Injectable } from "@angular/core";
import { Writer } from "ckeditor5";
import { InputPlugin } from "../../plugins/input/input-plugin";
import { ShortTextInput } from "../../models/input/short-text-input-model";
import { PluginUtilsService } from "../../../utils/plugin-utils.service";

@Injectable({
    providedIn: "root",
})
export class InputDataViewUtilsService {

    private pluginUtils: PluginUtilsService;

    constructor() {
        this.pluginUtils = new PluginUtilsService();
    }

    public createInputStructureModel(writer: Writer, typeInput: string, inputModel: ShortTextInput) {
        const modelElement = this.createInputElementModel(writer, typeInput, inputModel);
        const nameModelElement = this.createInputContentElementModel(writer, inputModel);
        writer.insert(nameModelElement, modelElement, 0);

        return modelElement;
    }

    public getValue(value: string | Date | number): string {
        return value?.toLocaleString();
    }

    private createInputElementModel(writer: Writer, typeInput: string, inputModel: ShortTextInput) {
        const name = inputModel.id;
        return writer.createElement(InputPlugin.MODEL_ENTITIES.container.model, {
            'alias': inputModel.alias,
            'input-type': typeInput,
            'is-valid': inputModel.isValid === undefined ? inputModel.isValid : true,
            'id': !!inputModel.id ? inputModel.id : this.pluginUtils.generateId(InputPlugin.ID_INPUT_PREFFIX),
            'input-help': inputModel.help,
            'value': inputModel.value,
            'pattern': !!inputModel.pattern ? inputModel.pattern : "undefined",
            'transform': !!inputModel.transform ? inputModel.transform : "undefined",
            'name': name,
            'is-linked': inputModel.isLinked,
            'data-embedded-in': inputModel.embeddedIn ? inputModel.embeddedIn : "undefined",
        });
    }

    private createInputContentElementModel(writer: Writer, inputModel: ShortTextInput) {
        const nameModelElement = writer.createElement(InputPlugin.MODEL_ENTITIES.content.model);
        writer.insertText(this.getValue(inputModel.value) ? this.getValue(inputModel.value): '', nameModelElement, 0);

        return nameModelElement;
    }
}
