import { Injectable } from '@angular/core';
import { Conversion } from 'ckeditor5';
import { RepeatableFragmentDataViewUtilsService } from '../../utils/repeatable-fragment/repeatable-fragment-data-view-utils.service';
import { RepeatableFragmentPlugin } from '../../plugins/repeatable-fragment/repeatable-fragment-plugin';
import { GlobalConstant } from '../../models/base/global-constant';

@Injectable({
    providedIn: 'root'
})
export class RepeatableFragmentModelToDataViewConverterService {

    private dataUtilsService: RepeatableFragmentDataViewUtilsService;

    constructor() {
        this.dataUtilsService = new RepeatableFragmentDataViewUtilsService();
    }

    public configureConverters(conversion: Conversion): void {
        this.containerConversion(conversion);

        this.buttonActionConversion(conversion);
        this.spanActionConversion(conversion);

        this.fragmentConversion(conversion);
        this.fragmentDescriptionConversion(conversion);
        this.fragmentContentConversion(conversion);
    }

    private containerConversion(conversion: Conversion): void {
        conversion.for("dataDowncast").add(downcastDispatcher => {
            downcastDispatcher.on(`insert:${RepeatableFragmentPlugin.MODEL_ENTITIES.container.model}`, (_evt, data, conversionApi) => {
                if (!conversionApi.consumable.consume(data.item, GlobalConstant.INSERT)) {
                    return;
                }

                const modelItem = data.item;

                const containerDataView = this.dataUtilsService.createRepeatableFragmentContainerDataView(modelItem, conversionApi.writer);

                conversionApi.mapper.bindElements(data.item, containerDataView);

                const viewPosition = conversionApi.mapper.toViewPosition(data.range.start);

                conversionApi.writer.insert(viewPosition, containerDataView);
            });
        });
    }

    private buttonActionConversion(conversion: Conversion): void {
        conversion.for("dataDowncast").elementToElement({
            model: {
                name: RepeatableFragmentPlugin.MODEL_ENTITIES.action.model,
                attributes: [RepeatableFragmentPlugin.MODEL_ENTITIES.dataType.model]
            },
            view: (modelItem, { writer }) => {
                const id = (modelItem.parent as unknown as Element).getAttribute(GlobalConstant.ATTRIBUTE_ID);

                const dataType = modelItem.getAttribute(RepeatableFragmentPlugin.MODEL_ENTITIES.dataType.model);

                if (dataType === GlobalConstant.DELETE)
                    return this.dataUtilsService.createButtonMinusDataView(id, writer);

                return this.dataUtilsService.createButtonPlusDataView(id, writer);
            }
        });
    }

    private spanActionConversion(conversion: Conversion): void {
        conversion.for("dataDowncast").elementToElement({
            model: RepeatableFragmentPlugin.MODEL_ENTITIES.descriptionAction.model,
            view: (_modelItem, { writer }) => {
                return this.dataUtilsService.createSpanActionDataView(writer);
            }
        });
    }

    private fragmentConversion(conversion: Conversion): void {
        conversion.for("dataDowncast").elementToElement({
            model: {
                name: RepeatableFragmentPlugin.MODEL_ENTITIES.fragment.model,
                attributes: [RepeatableFragmentPlugin.MODEL_ENTITIES.fragmentPosition.model]
            },
            view: (modelItem, { writer }) => {
                const id = (modelItem.parent as unknown as Element).getAttribute(GlobalConstant.ATTRIBUTE_ID);
                return this.dataUtilsService.createRepeatableFragmentDataView(id, writer);
            }
        });
    }

    private fragmentDescriptionConversion(conversion: Conversion): void {
        conversion.for("dataDowncast").elementToElement({
            model: {
                name: RepeatableFragmentPlugin.MODEL_ENTITIES.description.model,
                attributes: [RepeatableFragmentPlugin.MODEL_ENTITIES.fragmentPosition.model]
            },
            view: (_modelItem, { writer }) => {
                return this.dataUtilsService.createRepeatableFragmentDescriptionDataView(writer);
            }
        });
    }

    private fragmentContentConversion(conversion: Conversion): void {
        conversion.for("dataDowncast").elementToElement({
            model: {
                name: RepeatableFragmentPlugin.MODEL_ENTITIES.content.model,
                attributes: [RepeatableFragmentPlugin.MODEL_ENTITIES.fragmentPosition.model]
            },
            view: (modelItem, { writer }) => {
                const id = ((modelItem.parent.parent as unknown) as Element).getAttribute(GlobalConstant.ATTRIBUTE_ID);

                const position = modelItem.getAttribute(RepeatableFragmentPlugin.MODEL_ENTITIES.fragmentPosition.model).toString();

                return this.dataUtilsService.createRepeatableFragmentContentDataView(id, position, writer);
            }
        });
    }
}
