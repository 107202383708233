<div class="tree-node {{setClassNode(node)}}">
    <div class="tree-node-icon" (click)="iconClick(node)" [attr.aria-label]="'toggle ' + node?.value" [ngClass]="{'no-child' : !node.expandable}">
        <mat-icon class="expand-icon mat-icon-rtl-mirror" [ngClass]="{'check' : node.type !== NodeTreeNodeType.Folder}">{{ !node.expandable ? '' : treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}</mat-icon>
    </div>
    <div class="{{setClassNode(node)}} tree-node-content" ctboxNodeDraggable [canDropFromOutside]="true" [labelToMarkIsDroppable]="labelToMarkIsDroppable" [attributeToReadInDropElement]="attributeToReadInDropElement" [node]="node" (dropFromOutsideWithId)="fileNodeDroppedWithId($event)">

        <mat-checkbox *ngIf="forceShowCheck || (!forceShowCheck && hasNodeTypeWithCheck(node))" class="checklist-leaf-node" [checked]="node.isChecked" (change)="checkToggle(node)" [disabled]="!forceShowCheck"></mat-checkbox>

        <div class="tree-node-icon {{setClassNode(node)}}" (click)="selectNode(node)">
            <ng-container *ngIf="node.type == NodeTreeNodeType.Folder">
                <mat-icon class="folder-icon mat-icon-rtl-mirror">{{ !node.expandable ? 'folder' : treeControl.isExpanded(node) ? 'folder_open' : 'folder'}}</mat-icon>
            </ng-container>
            <ng-container *ngIf="node.type !== NodeTreeNodeType.Folder">
                <mat-icon class="file-icon {{ setClassFileIcon(node) }}" matTooltip="{{setIconToolTip(node)}}" svgIcon="{{ setIconFile(node) }}"></mat-icon>                
            </ng-container>
        </div>
        <div class="tree-node-text {{setClassNode(node)}}" (click)="selectNode(node)">
            <ng-container *ngIf="editable; then editableBlock else noEditableBlock"></ng-container>
            <ng-template #editableBlock>
                <form [formGroup]="nodeForm" (keydown.escape)="resetName()">
                    <app-ctbox-validate-name #nodeNameInput [parentGroup]="nodeForm" [controlName]="'name'" [showCancel]="true" [classInput]="'ctbox-tree-node'" [showLabel]="false" (keydown.enter)="editKeyEnter($event)" (onBlur)="blurEdition($event)" (onClickCancel)="resetName()"></app-ctbox-validate-name>
                </form>
            </ng-template>
            <ng-template #noEditableBlock>
                <span [innerHTML]="valueToUse()"></span>
            </ng-template>
        </div>
    </div>
</div>