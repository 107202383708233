<div class="document-state" [matTooltip]="stateTooltip" [matTooltipShowDelay]="1" [matTooltipHideDelay]="1">
    <div class="document-state" [matMenuTriggerFor]="menuStates" [ngClass]="{ 'disabled' : !canChangeState()}">
        <ctbox-button buttonType="secondary" buttonIcon="circle" buttonColor="state" [buttonText]="stateText" [customClass]="stateClass">
            <ctbox-button *ngIf="canChangeState() && !this.isListAction" buttonIcon="expand_more" buttonColor="state--right"></ctbox-button>
        </ctbox-button>
    </div>
    <mat-menu #menuStates="matMenu" [overlapTrigger]="false" class="document-state">
        <ng-container *ngFor="let state of documentStatuses">
            <ctbox-button *ngIf="isStateChangeable(state.code)" buttonType="secondary" buttonIcon="circle" buttonColor="state" [buttonText]="setDocumentStatusText(state.code)" [customClass]="setDocumentStatusClass(state.code)" (buttonClick)="setDocumentState(state.code)"></ctbox-button>
        </ng-container>
    </mat-menu>
</div>
