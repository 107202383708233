import { Injectable } from '@angular/core';
import { Conversion, DowncastWriter, Element, toWidget, toWidgetEditable, ViewEditableElement, ViewElement } from 'ckeditor5';
import { PluginUtilsService } from '../../../utils/plugin-utils.service';
import { ContextEditorTypes } from '../../models/base/context-editor-types';
import { RepeatableFragmentPlugin } from '../../plugins/repeatable-fragment/repeatable-fragment-plugin';
import { RepeatableFragmentEditionViewUtilsService } from '../../utils/repeatable-fragment/repeatable-fragment-edition-view-utils.service';
import { GlobalConstant } from '../../models/base/global-constant';

@Injectable({
    providedIn: 'root'
})
export class RepeatableFragmentModelToEditorViewConverterService {

    private editorUtilsService: RepeatableFragmentEditionViewUtilsService;
    protected pluginUtils: PluginUtilsService;

    constructor() {
        this.editorUtilsService = new RepeatableFragmentEditionViewUtilsService();
        this.pluginUtils = new PluginUtilsService();
    }

    public configureConverters(conversion: Conversion) {
        this.containerConversion(conversion);
        this.actionContainerConversion(conversion);
        this.actionConversion(conversion);
        this.descriptionActionConversion(conversion);
        this.descriptionConversion(conversion);
        this.fragmentConversion(conversion);
        this.contentConversion(conversion);

        this.optionCountAttributeConversion(conversion);
        this.isValidAttributeConversion(conversion);
    }

    private containerConversion(conversion: Conversion): void {
        conversion.for("editingDowncast").elementToElement({
            model: RepeatableFragmentPlugin.MODEL_ENTITIES.container.model,
            view: (modelItem: Element, { writer: viewWriter }) => {
                const widgetElement = this.editorUtilsService.createRepeatableFragmentEditorView(modelItem, viewWriter);

                return toWidget(widgetElement, viewWriter);
            },
        });
    }

    private isValidAttributeConversion(conversion: Conversion): void {
        conversion.for('editingDowncast').attributeToAttribute({
            model: GlobalConstant.ATTRIBUTE_IS_VALID,
            view: GlobalConstant.ATTRIBUTE_IS_VALID,
        });
    }

    private actionContainerConversion(conversion: Conversion): void {
        conversion.for("editingDowncast").elementToElement({
            model: RepeatableFragmentPlugin.MODEL_ENTITIES.actionContainer.model,
            view: (_modelItem: Element, { writer: viewWriter }) => {
                const widgetElement = this.editorUtilsService.createActionContainerEditionView(viewWriter);

                return toWidget(widgetElement, viewWriter);
            },
        });
    }

    private actionConversion(conversion: Conversion): void {
        conversion.for("editingDowncast").elementToElement({
            model: RepeatableFragmentPlugin.MODEL_ENTITIES.action.model,
            view: (modelItem: Element, { writer: viewWriter }) => {
                const widgetElement = this.editorUtilsService.createActionEditionView(modelItem, viewWriter);

                return toWidget(widgetElement, viewWriter);
            },
        });
    }

    private descriptionActionConversion(conversion: Conversion): void {
        conversion.for("editingDowncast").elementToElement({
            model: RepeatableFragmentPlugin.MODEL_ENTITIES.descriptionAction.model,
            view: (_modelItem: Element, { writer: viewWriter }) => {
                const widgetElement = this.editorUtilsService.createDescriptionActionEditionView(viewWriter);

                return toWidget(widgetElement, viewWriter);
            },
        });
    }

    private optionCountAttributeConversion(conversion: Conversion): void {
        conversion.for('editingDowncast').attributeToAttribute({
             model: RepeatableFragmentPlugin.MODEL_ENTITIES.fragmentCount.model,
             view: RepeatableFragmentPlugin.MODEL_ENTITIES.fragmentCount.editionView
        });
    }

    private fragmentConversion(conversion: Conversion): void {
        conversion.for("editingDowncast").elementToElement({
            model: RepeatableFragmentPlugin.MODEL_ENTITIES.fragment.model,
            view: (_modelItem: Element, { writer: viewWriter }) => {
                const widgetElement = this.editorUtilsService.createFragmentEditionView(viewWriter);

                return toWidget(widgetElement, viewWriter);
            },
        });
    }

    private contentConversion(conversion: Conversion): void {
        const radioPluginContext = RepeatableFragmentPlugin.contextEditor;
        const contextEditorTypeClause = ContextEditorTypes.CLAUSE;
        conversion.for("editingDowncast").elementToElement({
            model: RepeatableFragmentPlugin.MODEL_ENTITIES.content.model,
            view: (modelItem: Element, { writer: viewWriter }) => {
                const widgetElement = this.editorUtilsService.createContentElementEditionView(modelItem, viewWriter);

                return this.createWidgetElement(widgetElement, viewWriter, modelItem, radioPluginContext, contextEditorTypeClause);
            },
        });
    }

    private descriptionConversion(conversion: Conversion): void {
        const radioPluginContext = RepeatableFragmentPlugin.contextEditor;
        const contextEditorTypeClause = ContextEditorTypes.CLAUSE;
        conversion.for("editingDowncast").elementToElement({
            model: RepeatableFragmentPlugin.MODEL_ENTITIES.description.model,
            view: (modelItem: Element, { writer: viewWriter }) => {
                const widgetElement = this.editorUtilsService.createDescriptionElementEditionView(viewWriter);

                return this.createWidgetElement(widgetElement, viewWriter, modelItem, radioPluginContext, contextEditorTypeClause);
            },
        });
    }

    private createWidgetElement(
        widgetElement: ViewEditableElement,
        viewWriter: DowncastWriter,
        modelItem: Element,
        radioPluginContext: string,
        contextEditorTypeClause: ContextEditorTypes
    ): ViewElement {
        const parentElement = modelItem.parent.parent as Element;
        const dataEmbeddedIn = parentElement.getAttribute(GlobalConstant.ATTRIBUTE_EMBEDDED_IN)?.toString() ?? GlobalConstant.UNDEFINED;
        const isEditable = this.pluginUtils.isElementInteractableInTargetEditor(dataEmbeddedIn, radioPluginContext, contextEditorTypeClause);
        if (isEditable) {
            return toWidgetEditable(widgetElement, viewWriter);
        } else {
            return toWidget(widgetElement, viewWriter);
        }
    }

}
