import { Injectable } from "@angular/core";
import { Schema } from "ckeditor5";
import { GlobalConstant } from "../../models/base/global-constant";
import { ALLOW_CONTENT_OF_VALUES, DISALLOW_CHILDREN, INHERIT_ALL_FROM_VALUES } from "../schema-values";
import { RepeatableFragmentPlugin } from "../../plugins/repeatable-fragment/repeatable-fragment-plugin";

@Injectable({
    providedIn: 'root'
})
export class RepeatableFragmentSchemaService {

    constructor() {}

    public defineSchema(schema: Schema): void {
        this.registerContainer(schema);
        this.registerAction(schema);
        this.registerDescriptionAction(schema);
        this.registerDescription(schema);
        this.registerFragment(schema);
        this.registerContent(schema);
    }

    private registerContainer(schema: Schema): void {
        schema.register(RepeatableFragmentPlugin.MODEL_ENTITIES.container.model, {
            inheritAllFrom: INHERIT_ALL_FROM_VALUES.CONTAINER,
            allowAttributes: [
                GlobalConstant.ATTRIBUTE_ID,
                RepeatableFragmentPlugin.MODEL_ENTITIES.fragmentCount.model,
                GlobalConstant.ATTRIBUTE_EMBEDDED_IN,
                GlobalConstant.ATTRIBUTE_IS_VALID
            ],
            allowContentOf: ALLOW_CONTENT_OF_VALUES.ROOT,
            isObject: true
        });
    }

    private registerAction(schema: Schema): void {
        schema.register(RepeatableFragmentPlugin.MODEL_ENTITIES.action.model, {
            inheritAllFrom: INHERIT_ALL_FROM_VALUES.CONTAINER,
            allowAttributes: [GlobalConstant.ATTRIBUTE_DATA_TYPE, GlobalConstant.ATTRIBUTE_DISABLED]
        });
    }

    private registerDescriptionAction(schema: Schema): void {
        schema.register(RepeatableFragmentPlugin.MODEL_ENTITIES.descriptionAction.model, {
            inheritAllFrom: INHERIT_ALL_FROM_VALUES.CONTAINER,
            allowContentOf: ALLOW_CONTENT_OF_VALUES.TEXT
        });
    }

    private registerDescription(schema: Schema): void {
        schema.register(RepeatableFragmentPlugin.MODEL_ENTITIES.description.model, {
            inheritAllFrom: INHERIT_ALL_FROM_VALUES.BLOCK_OBJECT,
            allowContentOf: ALLOW_CONTENT_OF_VALUES.ROOT,
            disallowChildren: [DISALLOW_CHILDREN.IMAGE_BLOCK, DISALLOW_CHILDREN.TABLE]
        });
    }

    private registerFragment(schema: Schema): void {
        schema.register(RepeatableFragmentPlugin.MODEL_ENTITIES.fragment.model, {
            inheritAllFrom: INHERIT_ALL_FROM_VALUES.CONTAINER,
            allowContentOf: ALLOW_CONTENT_OF_VALUES.ROOT,
            isObject: true
        });
    }

    private registerContent(schema: Schema): void {
        schema.register(RepeatableFragmentPlugin.MODEL_ENTITIES.content.model, {
            inheritAllFrom: INHERIT_ALL_FROM_VALUES.BLOCK_OBJECT,
            allowAttributes: [RepeatableFragmentPlugin.MODEL_ENTITIES.fragmentPosition.model],
            allowContentOf: ALLOW_CONTENT_OF_VALUES.ROOT
        });
    }
}
