<div class="dropzone">
    <div class="dropzone-description">{{description}}</div>
    <div class="dropzone-content">
        <div class="dropzone-content-wrapper">
            <div class="dropzone-input" appDragAndDrop (fileDropped)="onFileDropped($event)">
                <input class="dropzone-ref" type="file" #fileDropRef id="fileDropRef" (change)="fileBrowseHandler($event.target.files)" accept=".docx, .pdf" hidden />
                <div class="dropzone-text" i18n="@@SubirFicheroDrapAndDropTitulo">Arrastra un fichero aquí o </div>
                <label class="dropzone-label" for="fileDropRef" i18n="@@BusqueEnSuOrdenadorMensaje">busca en tu ordenador</label>
            </div>
            <div class="dropzone-list">
                <div class="dropzone-file" *ngFor="let file of files; let i = index">
                    <div class="dropzone-file-icon">
                        <mat-icon class="big">description</mat-icon>
                    </div>
                    <div class="dropzone-file-info">
                        <div class="dropzone-file-name">{{ file?.name }}</div>
                        <div class="dropzone-file-size">{{ formatBytes(file?.size, 2) }}</div>
                        <mat-progress-bar class="dropzone-progress-bar" [value]="file?.progress"></mat-progress-bar>
                    </div>
                    <ctbox-button buttonIcon="delete" buttonColor="delete" (buttonClick)="deleteFile(i)"></ctbox-button>
                </div>
            </div>
        </div>
    </div>
</div>
