import { Editor, Node, Element, toWidget, toWidgetEditable, Conversion, DowncastWriter, ViewContainerElement } from 'ckeditor5';
import { Injectable } from "@angular/core";
import { InputPlugin } from '../../plugins/input/input-plugin';
import { GlobalConstant } from '../../models/base/global-constant';
import { ShortTextInput } from '../../models/input/short-text-input-model';
import { PluginUtilsService } from '../../../utils/plugin-utils.service';

@Injectable({
    providedIn: "root",
})
export class InputModelToEditorViewConverterService {
    private utilsService: PluginUtilsService;

    private attributesMapping = {
        alias: {
            model: InputPlugin.MODEL_ENTITIES.alias.model,
            editionView: InputPlugin.MODEL_ENTITIES.alias.editionView,
        },
        help: {
            model: InputPlugin.MODEL_ENTITIES.help.model,
            editionView: InputPlugin.MODEL_ENTITIES.help.editionView,
        },
        pattern: {
            model: InputPlugin.MODEL_ENTITIES.pattern.model,
            editionView: InputPlugin.MODEL_ENTITIES.pattern.editionView,
        },
        transform: {
            model: InputPlugin.MODEL_ENTITIES.transform.model,
            editionView: InputPlugin.MODEL_ENTITIES.transform.editionView,
        },
        isValid: {
            model: InputPlugin.MODEL_ENTITIES.isValid.model,
            editionView: InputPlugin.MODEL_ENTITIES.isValid.editionView,
        },
        isLinked: {
            model: InputPlugin.MODEL_ENTITIES.isLinked.model,
            editionView: InputPlugin.MODEL_ENTITIES.isLinked.editionView,
        },
        embeddedIn: {
            model: GlobalConstant.ATTRIBUTE_EMBEDDED_IN,
            editionView: GlobalConstant.ATTRIBUTE_EMBEDDED_IN,
        }
    };

    constructor() {
        this.utilsService = new PluginUtilsService();
    }

    public configureConverter(editor: Editor): void {
        const conversion = editor.conversion;
        this.containerConversionStructure(conversion, editor);
        this.attributeConversion(conversion);
    }

    private attributeConversion(conversion: Conversion): void {
        for (const key in this.attributesMapping) {
            if (this.attributesMapping.hasOwnProperty(key)) {
                const { model, editionView } = this.attributesMapping[key];
                conversion.for('editingDowncast').attributeToAttribute({
                    model: model,
                    view: editionView,
                });
            }
        }
    }

    private containerConversionStructure(conversion: Conversion, editor: Editor): void {
        conversion.for('editingDowncast').elementToElement({
            model: InputPlugin.MODEL_ENTITIES.container.model,
            view: (modelElement: Element, { writer }) => {

                const nameModelElement = this.getNameModelElement(editor, modelElement);
                const child = !!nameModelElement && (nameModelElement as Element).getChild(0) ? (nameModelElement as Element).getChild(0) : null;

                const isValid = modelElement.getAttribute(InputPlugin.MODEL_ENTITIES.isValid.model) === true;
                const isValidClass = isValid ? InputPlugin.ATTRIBUTE_INPUT_IS_VALID_CLASS_EDITION_VIEW : InputPlugin.ATTRIBUTE_INPUT_IS_NOT_VALID_CLASS_EDITION_VIEW;

                const inputModel = this.getModel(child, modelElement);
                const viewElement = this.createViewElement(writer, inputModel);

                writer.addClass([InputPlugin.MODEL_ENTITIES.container.editionView, isValidClass, GlobalConstant.CONTAINER_CLASS_EDITION_VIEW], viewElement);
                return toWidget(viewElement, writer);
            }
        }).elementToElement({
            model: InputPlugin.MODEL_ENTITIES.content.model,
            view: (modelElement: Element, { writer }) => {
                const inputModel = this.getModel(modelElement, modelElement.parent as Element);
                const viewElement = writer.createEditableElement('span', {
                    "data-input-help": inputModel.help
                });
                writer.addClass([InputPlugin.MODEL_ENTITIES.content.editionView], viewElement);

                return toWidgetEditable(viewElement, writer);
            }
        });
    }

    private createViewElement(writer: DowncastWriter, inputModel: ShortTextInput): ViewContainerElement {
        return writer.createContainerElement('span', {
            'type': inputModel.type,
            'id': inputModel.id,
            style: 'display: inline-block',
            'data-alias': inputModel.alias,
            'data-input-help': inputModel.help,
            'data-pattern': inputModel.pattern,
            'data-transform': inputModel.transform,
            'value': inputModel.value,
            'data-is-valid': inputModel.isValid,
            'data-error-message': '',
            'data-is-linked': inputModel.isLinked,
            'data-embedded-in': inputModel.embeddedIn,
        });
    }

    private getNameModelElement(editor: Editor, modelElement:Element): Node | null {
        for (const { item } of editor.model.createRangeIn(modelElement)) {
            if (item.is('element', InputPlugin.MODEL_ENTITIES.content.model)) {
                return modelElement.getChild(0)!;
            }
        }
        return null;
    }

    private getModel(child: Node, modelElement: Element): ShortTextInput {
        const valueInInput = this.utilsService.getTextContent(child);
        const helpNote = modelElement.getAttribute(InputPlugin.MODEL_ENTITIES.help.model);
        const id = modelElement.getAttribute(GlobalConstant.ATTRIBUTE_ID);
        const type = modelElement.getAttribute(InputPlugin.MODEL_ENTITIES.type.model);

        const isValid = modelElement.getAttribute(InputPlugin.MODEL_ENTITIES.isValid.model) === true;
        const transform = modelElement.getAttribute(InputPlugin.MODEL_ENTITIES.transform.model);
        const pattern = modelElement.getAttribute(InputPlugin.MODEL_ENTITIES.pattern.model);
        const alias = modelElement.getAttribute(InputPlugin.MODEL_ENTITIES.alias.model);
        const isLinked = modelElement.getAttribute(InputPlugin.MODEL_ENTITIES.isLinked.model) === true;
        const embeddedIn = modelElement.getAttribute(GlobalConstant.ATTRIBUTE_EMBEDDED_IN);

        return {
            id,
            help: helpNote,
            value: valueInInput,
            alias,
            pattern,
            isValid,
            type,
            transform,
            isLinked,
            embeddedIn
        } as ShortTextInput;
    }
}
