
<header cdkDragBoundary=".cdk-overlay-container" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle [cdkDragDisabled]="!isDraggableDialog"   class="mat-dialog-header" *ngIf="dialogTitle || displayCloseOption">
    <div mat-dialog-title class="mat-dialog-title">
        <div class="{{dialogTitleClass}}" *ngIf="dialogTitle">{{ dialogTitle }}</div>
    </div>
    <div *ngIf="displayCloseOption" class="mat-dialog-close">
        <ctbox-button buttonIcon="close" buttonColor="secondary" customClass="medium" (buttonClick)="clickCloseOption()"></ctbox-button>
    </div>
</header>
<mat-dialog-content cdkDragBoundary=".cdk-overlay-container" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle [cdkDragDisabled]="!isDraggableDialog || !isDraggableContent"   [ngClass]="{'no-actions': !displayButtonBar, 'single-button': (!dialogButton || !dialogCloseButon)}">
    <ng-template #dialogbody></ng-template>
</mat-dialog-content>
<mat-dialog-actions  cdkDragBoundary=".cdk-overlay-container" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle [cdkDragDisabled]="!isDraggableDialog"   *ngIf="displayButtonBar" class="mat-dialog-actions" [ngClass]="{'single-button': !dialogButton || !dialogCloseButon}">
    <ng-container *ngIf="isSecondaryButtonActive; then secondaryButtonEnable; else secondaryButtonDisable">
    </ng-container>

    <ng-container *ngIf="isPrimaryButtonActive; then primaryButtonEnable; else primaryButtonDisable">
    </ng-container>
</mat-dialog-actions>

<ng-template #primaryButtonEnable>
    <ctbox-button *ngIf="dialogButton" buttonType="primary" buttonColor="accent" [buttonText]="dialogButton" (buttonClick)="acceptClick()"></ctbox-button>
</ng-template>

<ng-template #primaryButtonDisable>
    <ctbox-button *ngIf="dialogButton" buttonType="primary" [buttonText]="dialogButton" [isDisabled]="true"></ctbox-button>
</ng-template>

<ng-template #secondaryButtonEnable>
    <ctbox-button *ngIf="dialogCloseButon" buttonType="secondary" [buttonText]="dialogCloseButon" (buttonClick)="cancelClick()"></ctbox-button>
</ng-template>

<ng-template #secondaryButtonDisable>
    <ctbox-button *ngIf="dialogCloseButon" buttonType="secondary" [buttonText]="dialogCloseButon" [isDisabled]="true"></ctbox-button>
</ng-template>

