import { Injectable } from "@angular/core";
import { Schema } from "ckeditor5";
import { GlobalConstant } from "../../models/base/global-constant";
import { HelpNotePlugin } from "../../plugins/help-note/help-note-plugin";
import { InputPlugin } from "../../plugins/input/input-plugin";
import { ALLOW_CONTENT_OF_VALUES, INHERIT_ALL_FROM_VALUES } from "../schema-values";

@Injectable({
    providedIn: 'root'
})
export class InputSchemaService {

    constructor() { }

    public defineSchema(schema: Schema): void {
        this.defineInputContainer(schema);
        this.defineInputContent(schema);
    }

    private defineInputContainer(schema): void {
        schema.register(InputPlugin.MODEL_ENTITIES.container.model, {
            inheritAllFrom: INHERIT_ALL_FROM_VALUES.INLINE_OBJECT,
            allowAttributes: [
                InputPlugin.MODEL_ENTITIES.type.model,
                InputPlugin.MODEL_ENTITIES.isValid.model,
                GlobalConstant.ATTRIBUTE_ID,
                InputPlugin.MODEL_ENTITIES.help.model,
                GlobalConstant.ATTRIBUTE_VALUE,
                InputPlugin.MODEL_ENTITIES.pattern.model,
                InputPlugin.MODEL_ENTITIES.transform.model,
                InputPlugin.MODEL_ENTITIES.alias.model,
                InputPlugin.MODEL_ENTITIES.isLinked.model,
                GlobalConstant.ATTRIBUTE_EMBEDDED_IN,
            ]
        });

        this.defineInputContainerValidations(schema);
    }

    private defineInputContent(schema: Schema): void {
        schema.register(InputPlugin.MODEL_ENTITIES.content.model, {
            allowIn: InputPlugin.MODEL_ENTITIES.container.model,
            allowChildren: ALLOW_CONTENT_OF_VALUES.TEXT,
            isLimit: true,
            allowAttributes: [
                InputPlugin.MODEL_ENTITIES.help.model
            ],
            disallowChildren: [
                HelpNotePlugin.MODEL_ENTITIES.container.model,
                HelpNotePlugin.PLUGIN_NAME

            ],
            disallowIn: [
                HelpNotePlugin.MODEL_ENTITIES.container.model,
                HelpNotePlugin.PLUGIN_NAME
            ]
        });
    }

    private defineInputContainerValidations(schema: any): void {
        schema.addChildCheck((context, childDefinition) => {
            if (context.endsWith(InputPlugin.MODEL_ENTITIES.content.model) && (childDefinition.name !== '$text')) {
                return false;
            }
            return;
        });
    }
}
