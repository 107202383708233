<div class="c-help">
    <div class="c-help-header">
        <h3 class="c-help-header-title c-help-documents">Vista de documento </h3>
        <p class="c-help-header-text">En <strong>contractBOX</strong> dispones de un amplio catálogo de opciones aplicables a la edición y revisión de los documentos que te facilitarán el trabajo de redacción y te aportarán fiabilidad y agilidad a la hora de redactar un escrito legal.</p>
    </div>
    <div class="c-help-content">
        <mat-tab-group mat-align-tabs="start" class="c-help documents">
            <mat-tab>
                <ng-template mat-tab-label>Acciones, alertas y notificaciones</ng-template>
                <div class="c-help-tab-body">
                    <div class="c-help-block">
                        <p class="c-help-summary">Nos dan información sobre el estado del documento y las acciones necesarias a realizar. También nos permite el cambio de estado del documento.</p>
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img">
                                    <ctbox-button buttonType="secondary" buttonIcon="circle" buttonColor="state" buttonText="Borrador" customClass="filled draft xsmall"></ctbox-button>
                                    <ctbox-button buttonIcon="expand_more" buttonColor="state"></ctbox-button>
                                </div>
                                <p><strong>Cambiar estado del documento:</strong> A medida que avances en el proceso de redacción, negociación y firma de un documento podrás cambiar el estado de ese documento desde aquí. El estado de los documentos te ayuda a identificar las tareas que tienes pendientes en ese documento.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonIcon="edit" buttonColor="secondary"></ctbox-button></div>
                                <p><strong>Editar nombre del documento:</strong> Cuando creas un documento en ContractBOX le asignarás un nombre de archivo que posteriormente podrás cambiar en este icono.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img">
                                    <div class="updates-button warning">
                                        <ctbox-button buttonIcon="warning" buttonColor="warning" customClass="filled warning"></ctbox-button>
                                        <ctbox-button buttonIcon="expand_more" buttonColor="secondary" customClass="border-left"></ctbox-button>
                                    </div>
                                </div>
                                <p><strong>Alertas en documento:</strong> Este símbolo aparece cuando hay alertas asociadas al documento, indicándote que ha sufrido cambios en parte o en su totalidad. Si pulsas en el icono podrás identificar y aplicar los cambios fácilmente.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-notification state="forbid"></ctbox-notification></div>
                                <p><strong>Permisos:</strong> Este símbolo indica que no tienes permisos de edición, ni podrás hacer acciones en este documento (editar, compartir, enviar a firma, etc…).</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>Texto</ng-template>
                <div class="c-help-tab-body">
                    <h4 class="c-help-subtitle">Edición del documento</h4>
                    <div class="c-help-block">
                        <h4 class="c-help-subtitle">Opciones de redacción: </h4>
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img wysiwyg"><span class="cke_button_icon  cke_button__textfield_icon">&nbsp;</span></div>
                                <p><strong>Insertar campo para rellenar:</strong> Para incluir casillas a rellenar con datos relativos al caso concreto.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img wysiwyg"><span class="cke_button_icon  cke_button__select_icon">&nbsp;</span></div>
                                <p><strong>Insertar desplegable:</strong> Para incluir combos desplegables con varias opciones en un mismo punto de una frase.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img wysiwyg"><span class="cke_button_icon  cke_button__checkbox_icon">&nbsp;</span></div>
                                <p><strong>Insertar texto opcional:</strong> Para incluir textos o párrafos optativos, que no necesariamente deben quedarse en la redacción final del documento.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img wysiwyg"><span class="cke_button_icon  cke_button__radio_icon">&nbsp;</span></div>
                                <p><strong>Insertar párrafos alternativos:</strong> Para dejar abierta la redacción de un párrafo o texto que puede presentar más de una opción de redacción.</p>
                            </li>
                        </ul>
                    </div>
                    <div class="c-help-block">
                        <h4 class="c-help-subtitle">Incorporación de cláusulas:</h4>
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img wysiwyg"><span class="cke_button_icon  cke_button__insertclauseandmodal_icon">&nbsp;</span></div>
                                <p><strong>Insertar cláusula nueva:</strong> Permite crear e insertar una cláusula en el documento.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img wysiwyg"><span class="cke_button_icon  cke_button__clausefromlibrary_icon">&nbsp;</span></div>
                                <p><strong>Insertar cláusula de la biblioteca:</strong> Permite añadir una cláusula ya existente de la biblioteca de cláusulas.</p>
                            </li>
                        </ul>
                    </div>
                    <div class="c-help-block">
                        <h4 class="c-help-subtitle"> Insertar espacio para firma:</h4>
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img wysiwyg"><span class="cke_button_icon cke_button__insertsignatureandmodal_icon">&nbsp;</span></div>
                                <p>Permite añadir un espacio de firma en el documento mejorando su formato, de forma que cuando se envíe posteriormente el documento a firma, se identifique claramente el lugar donde va a ir.</p>
                            </li>
                        </ul>
                    </div>
                    <div class="c-help-block">
                        <h4 class="c-help-subtitle"> Opciones de guardado:</h4>
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img">
                                    <div class="save-container">
                                        <ctbox-button buttonType="primary" buttonIcon="save" buttonText="Guardar" i18n-buttonText="@@Guardar"></ctbox-button>
                                        <ctbox-button buttonType="primary" buttonIcon="expand_more" customClass="mat-stroked-button border-left"></ctbox-button>
                                        <div class="mat-menu">
                                            <ctbox-button buttonType="mat-menu-item" buttonColor="secondary" buttonText="Guardar como"></ctbox-button>
                                            <ctbox-button buttonType="mat-menu-item" buttonColor="secondary" buttonText="Guardar como plantilla"></ctbox-button>
                                            <!-- Comentado Bloqueo Documentos -->
                                            <!-- <ctbox-button buttonType="mat-menu-item" buttonColor="secondary" buttonText="Guardar versión"></ctbox-button> -->
                                        </div>
                                    </div>
                                </div>
                                <ul class="c-help-list">
                                    <li><strong>Guardar:</strong> Permite guardar los cambios realizados en el documento. Se mantendrá tanto el nombre como la ubicación actual.</li>
                                    <li><strong>Guardar cómo:</strong> Permite guardar los cambios realizados en el documento. Se podrá elegir una nueva ubicación y se podrá cambiar el nombre del documento.</li>
                                    <li><strong>Guardar como plantilla:</strong> Permite guardar el documento como una plantilla para ser utilizado en la creación de más escritos legales.</li>
                                    <!-- Comentado Bloqueo Documentos -->
                                    <!-- <li><strong>Guardar versión:</strong> Permite guardar una versión del documento que se está consultando que se visualizará asociada a dicho documento.</li> -->
                                </ul>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img">
                                    <ctbox-button buttonType="secondary" buttonColor="primary" buttonText="Finalizar edición de campos"></ctbox-button>
                                </div>
                                <p><strong>Finalizar edición de campos:</strong> Indica que se han terminado de rellenar todos los campos necesarios en el documento.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonType="secondary" buttonIcon="add" buttonText="Doc. adicional"></ctbox-button></div>
                                <p><strong>Documento adicional:</strong> Para incluir un anexo o adenda al contrato que se podrá visualizar en la pestaña documentos relacionados del documento en el que se ha creado.</p>
                            </li>
                        </ul>
                    </div>
                    <h4 class="c-help-subtitle">Control de cambios y comentarios</h4>
                    <div class="c-help-block">
                        <p class="c-help-summary"> Revisar e introducir cambios en la redacción de un escrito es más ágil e intuitivo gracias a las herramientas de control de cambios, la posibilidad de incluir y contestar comentarios disponibles en el editor y al exclusivo módulo de compartir los documentos. Gracias a ContractBOX puedes: </p>
                    </div>
                    <div class="c-help-block">
                        <h4 class="c-help-subtitle">Control de cambios: </h4>
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img wysiwyg"><span class="cke_button_icon  cke_button__flite-acceptall_icon">&nbsp;</span></div>
                                <p><strong>Aceptar todos los cambios: </strong> pulsando este botón aceptas todos los cambios introducidos en la redacción del documento por otros intervinientes.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img wysiwyg"><span class="cke_button_icon  cke_button__flite-acceptone_icon">&nbsp;</span></div>
                                <p><strong>Aceptar el cambio: </strong> se acepta uno de los cambios introducido por otro interviniente.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img wysiwyg"><span class="cke_button_icon  cke_button__flite-rejectall_icon">&nbsp;</span></div>
                                <p><strong>Descartar todos los cambios: </strong> pulsando este botón rechazas todos los cambios introducidos en la redacción del documento por otros intervinientes.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img wysiwyg"><span class="cke_button_icon  cke_button__flite-rejectone_icon">&nbsp;</span></div>
                                <p><strong>Descartar el cambio: </strong> se rechaza uno de los cambios introducido por otro interviniente.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img wysiwyg"><span class="cke_button_icon  cke_button__previouschangeflitebutton_icon">&nbsp;</span></div>
                                <p><strong>Cambio anterior: </strong> permite navegar por los cambios introducidos en el texto en un punto anterior al actual.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img wysiwyg"><span class="cke_button_icon  cke_button__nextchangeflitebutton_icon">&nbsp;</span></div>
                                <p><strong>Cambio siguiente: </strong> permite navegar por los cambios introducidos en el texto en un punto posterior al actual.</p>
                            </li>

                            <li class="c-help-list-flexrow">
                                <div class="c-help-img wysiwyg"><span class="cke_button_icon  cke_button__flite-toggleshow_icon">&nbsp;</span></div>
                                <p><strong>Ocultar el seguimiento de cambios: </strong> ocultamos y desactivamos los formatos de la funcionalidad del control de cambios hasta un momento posterior.</p>
                            </li>
                        </ul>
                    </div>
                    <div class="c-help-block">
                        <h4 class="c-help-subtitle">Comentarios:</h4>
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img wysiwyg"><span class="cke_button_icon cke_button__annotate_icon">&nbsp;</span></div>
                                <ul class="c-help-list">
                                    <li><strong>Añadir comentario: </strong> para incluir notas de seguimiento asociadas si se quiere a cambios en la redacción del documento a revisar por otros intervinientes.</li>
                                    <li><strong>Editar comentario: </strong> Permite modificar el contenido de una nota de seguimiento escrita con anterioridad.</li>
                                    <li><strong>Borrar comentario: </strong> Para eliminar una nota de seguimiento que ya no es útil o es errónea.</li>
                                    <li><strong>Borrar todos los comentarios: </strong> Para eliminar todas las notas de seguimiento una vez completada la revisión.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>Documentos relacionados</ng-template>
                <div class="c-help-tab-body">
                    <div class="c-help-block">
                        <p class="c-help-summary">Podrá consultar los anexos, adendas etc… relacionados con un Contrato o documento principal, que tendrán su propio flujo independiente de revisión y firma. En esta pestaña dispondrá de la siguiente información: </p>
                        <table class="c-help-table" id="helpTable">
                            <caption>Documentos relacionados</caption>
                            <tr>
                                <th id="helpTableTh1">Nombre del fichero</th>
                                <th id="helpTableTh2">Última interacción con el documento</th>
                                <th id="helpTableTh3">Estado del documento</th>
                                <th id="helpTableTh4">Posibilidad de eliminar el documento adicional</th>
                            </tr>
                            <tr>
                                <th id="helpTableTh5" i18n="@@NombreDocumentoRelacionado">Documentos adicionales</th>
                                <th id="helpTableTh6" i18n="@@UltimaAccionColumnaRelacionado">Última Acción</th>
                                <th id="helpTableTh7" i18n="@@EstadoRelacionado">Estado</th>
                                <th id="helpTableTh8" i18n="@@AccionesDeDocumentRelacionadosColumna">Acciones</th>
                            </tr>
                            <tr>
                                <td><a class="link">Nombre</a></td>
                                <td>20/04/2023</td>
                                <td><ctbox-button buttonType="secondary" buttonIcon="circle" buttonColor="state" buttonText="Borrador" customClass="filled draft xsmall"></ctbox-button></td>
                                <td><ctbox-button buttonIcon="delete" buttonColor="delete"></ctbox-button></td>
                            </tr>
                        </table>
                    </div>
                </div>
            </mat-tab>
            <!-- Comentado Bloqueo Documentos -->
            <!-- <mat-tab>
                <ng-template mat-tab-label>Versiones</ng-template>
                <div class="c-help-tab-body">
                    <div class="c-help-block">
                        <p class="c-help-summary"> En la pestaña versiones del documento, podrás consultar las distintas versiones que se hayan ido guardando de un documento (con sus comentarios) a lo largo de su proceso de creación y negociación, seleccionando en el menú de versiones disponibles la que quieras consultar. </p>
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><img src="assets/images/documentos_versiones.png" alt="Aceptar todos"></div>
                                <ul class="c-help-list">
                                    <li>
                                        <p>Las versiones, aparecerán ordenadas por fecha de más reciente a más antigua, pudiendo cambiar el orden al contrario u ordenarlas por el nombre de la versión clicando en la barra superior del menú de versiones.</p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div class="c-help-block">
                        <h4 class="c-help-subtitle">Cuando hayas finalizado la lectura podrás elegir:</h4>
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonType="primary" buttonText="Guardar como"></ctbox-button></div>
                                <p><strong>Guardar como: </strong> para guardar la versión como un documento nuevo, en cuyo caso podrás elegir mantener el control de cambios y los comentarios en el nuevo documento o eliminarlos.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonType="secondary" buttonText="Eliminar versión" buttonColor="warn"></ctbox-button></div>
                                <p><strong>Eliminar versión: </strong> para eliminar la versión del menú de versiones disponibles del documento.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </mat-tab> -->
            <mat-tab>
                <ng-template mat-tab-label>Compartir</ng-template>
                <div class="c-help-tab-body">
                    <div class="c-help-block">
                        <p class="c-help-summary"> Podrás compartir los documentos en estado Borrador, En revisión y Completados, con otros intervinientes añadiendo desde aquí participantes en el documento para revisar o para trabajar de forma colaborativa: </p>
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonType="secondary" buttonIcon="add" buttonText="Añadir persona" i18n-buttonText="@@AñadirPersona"></ctbox-button></div>
                                <p><strong>Añadir persona:</strong> Permite añadir una persona a la lista de personas a compartir. Rellena los datos necesarios y comparte el documento con otros usuarios.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonIcon="edit" buttonColor="secondary"></ctbox-button></div>
                                <p><strong>Editar nombre y correo:</strong> Permite cambiar el nombre y el email de las personas añadidas a la lista de compartidos.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonIcon="visibility" buttonColor="info"></ctbox-button></div>
                                <p><strong>Visualizar/ dejar de visualizar documento:</strong> La persona seleccionada podrá ver o dejar de ver el documento compartido.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><mat-slide-toggle checked="true" disabled class="help"></mat-slide-toggle></div>
                                <p><strong>Compartir con:</strong> Permite dar o quitar los permisos de edición del documento y los comentarios a la persona seleccionada.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonIcon="delete" buttonColor="delete"></ctbox-button></div>
                                <p><strong>Eliminar participante:</strong> Permite dejar de compartir un documento con un usuario y eliminarlo de la lista de personas compartidas.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>Enviar a firma</ng-template>
                <div class="c-help-tab-body">
                    <div class="c-help-block">
                        <p class="c-help-summary">Podrás configurar todos los parámetros de la firma, identificando a las partes que han intervenido en el documento y enviando el documento a firmar de forma segura a través del email. </p>
                        <ul class="c-help-list">
                            <li><strong>Firmantes: </strong> Una vez incluido en el documento el espacio para firma a través de la funcionalidad descrita en la pestaña edición del documento, los huecos de las firmas aparecerán automáticamente en la pestaña de enviar a firma y podrás incluir los datos personales de los firmantes.</li>
                            <li><strong>Fecha límite: </strong> Se puede incluir una fecha tope para la firma del documento, de forma que, si llega la fecha límite y no se ha firmado el documento por todas las partes, se cancelará el proceso de envío a firma y el documento pasará a estado Completado.</li>
                            <li><strong>Mensaje adjunto: </strong> Permite incluir el asunto y el cuerpo del mensaje que le llegará a los firmantes del documento una vez lo hayas enviado a firma.</li>
                            <li><strong>Enviar a firma: </strong> Desde aquí podremos enviar el documento si está listo para ello.</li>
                            <li><strong>Guardar: </strong> Para guardar todos los cambios que hayamos realizado en esta pestaña antes de enviar.</li>
                        </ul>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>Descargar</ng-template>
                <div class="c-help-tab-body">
                    <div class="c-help-block">
                        <p class="c-help-summary">Puedes descargar el documento en formato .docx <ctbox-button buttonIcon="download" buttonColor="info"></ctbox-button></p>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>Historial</ng-template>
                <div class="c-help-tab-body">
                    <div class="c-help-block">
                        <p class="c-help-summary"> Nos permite consultar el histórico del documento, ordenado de lo más reciente a lo más antiguo con toda la trazabilidad, indicando: </p>
                        <ul class="c-help-list">
                            <li>Usuario y fecha de la persona que ha creado el documento <span class="user-label created" i18n="@@HistorialFechaCreacion">Creado por:</span></li>
                            <li>Usuario y fecha de la <span class="user-label modified" i18n="@@HistorialUltimaFechaModificacion">Última modificación:</span> </li>
                            <li>Usuario y fecha de la descarga realizada. </li>
                            <li>Usuario y fecha al Borrar un documento <strong>"Documento borrado”</strong>. </li>
                            <li>Usuario y fecha con la vinculación del documento con las plantillas o cláusula en los que se haya insertado <strong>"Plantilla o Cláusula que contiene este documento"</strong>.</li>
                        </ul>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
