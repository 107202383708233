import { Directive, OnDestroy } from "@angular/core";
import { BasePlugin } from "../base/base-plugin";
import { ToolbarButtonModel } from "../../models/base/toolbar-button-model";
import AddEditHelpNoteCommand from "../../commands/help-note/add-edit-help-note-command";
import DeleteHelpNoteCommand from "../../commands/help-note/delete-help-note-command";
import { UI_CLASSES } from "../../ui/styles/styles-constants";
import HelpNoteDialogFormView from "../../ui/help-note/help-note-dialog-form-view.directive";
import HelpNoteBalloonView from "../../ui/help-note/help-note-balloon-view.directive";
import { HelpNoteDataViewToModelConverterService } from "../../converters/help-note/help-note-data-view-to-model-converter.service";
import { HelpNoteModelToEditorViewConverterService } from "../../converters/help-note/help-note-model-to-editor-view-converter.service";
import { HelpNoteModelToDataViewConverterService } from "../../converters/help-note/help-note-model-to-data-view-converter.service";
import { ClickObserver, ContextualBalloon, Dialog, DomEventData, Editor, EventInfo, Node, ViewDocumentClickEvent, ViewElement } from "ckeditor5";
import { HelpNoteSchemaService } from "../../schema/help-note/help-note-schema.service";
import DoubleClickObserver, { ViewDocumentDoubleClickEvent } from "../../../utils/double-click-observer";
import { ContextEditorTypes } from "../../models/base/context-editor-types";
import { HelpNoteModel } from "../../models/help-note/help-note-model";
import { UserInterfaceService } from "../../ui/user-interface.service";
import { HelpNoteEditionViewUtilsService } from "../../utils/help-note/help-note-edition-view-utils.service";
import { GlobalConstant } from "../../models/base/global-constant";
import { CkeditorConstant } from "../../models/base/ckeditor-constant";

@Directive()
export class HelpNotePlugin extends BasePlugin implements OnDestroy {

    public static readonly PLUGIN_NAME = 'HelpNote';
    public static readonly TOOLBAR_ELEMENT_NAME = 'Help-Note';
    public static readonly ADD_EDIT_COMMAND_NAME = 'add-edit-help-note-pg';
    public static readonly DELETE_COMMAND_NAME = 'delete-help-note-pg';
    public static readonly VISUAL_SELECTION_MARKER_NAME = 'help-note-pg';
    public static readonly TOOLTIP_TEXT_CLASS = 'data-cke-tooltip-class';
    public static readonly TOOLTIP_POSITION_CLASS = 'ck-tooltip_multi-line';

    protected readonly insertHelpNoteMessage = $localize`:@@InsertarNotaAyudaPlugin:Insertar nota de ayuda`;

    public static readonly MODEL_ENTITIES = {
        'container':    {   model: 'help-note',   dataView: '',       editionView: 'help-note-icon'               },
        'content':      {   model: '',            dataView: '',       editionView: 'data-cke-tooltip-text'        },
        'textClass':    {   model: '',            dataView: '',       editionView: 'ck-tooltip_multi-line'        },
        'position':     {   model: '',            dataView: '',       editionView: 'data-cke-tooltip-position'    },
        'cite':         {   model: '',            dataView: 'nCl',    editionView: ''                             },
        'anchorClass':  {   model: 'anchor-nh',   dataView: 'nh',     editionView: ''                             },
    };

    private readonly ID_HELP_NOTE_PREFIX = 'ck-help-note-';

    protected commands = {
        [HelpNotePlugin.ADD_EDIT_COMMAND_NAME]: AddEditHelpNoteCommand,
        [HelpNotePlugin.DELETE_COMMAND_NAME]: DeleteHelpNoteCommand,
    };

    protected mappers = [
        HelpNotePlugin.MODEL_ENTITIES.container.editionView
    ];

    protected toolbarButton: ToolbarButtonModel = {
        icon: UI_CLASSES.SVG_ICONS.HELP_NOTE,
        pluginToolbarElementName: HelpNotePlugin.TOOLBAR_ELEMENT_NAME,
        tooltip: this.insertHelpNoteMessage,
        hasTooltip: true,
        hasText: true
    };

    private helpNoteDialogFormView: HelpNoteDialogFormView;
    private helpNoteBalloonView: HelpNoteBalloonView;

    private helpNoteSchemaService: HelpNoteSchemaService;

    private dataViewToModelConverter: HelpNoteDataViewToModelConverterService;
    private modelToDataViewConverter: HelpNoteModelToDataViewConverterService;
    private modelToEditorViewConverter: HelpNoteModelToEditorViewConverterService;

    private editionViewUtilsService: HelpNoteEditionViewUtilsService;

    private userInterfaceService: UserInterfaceService;

    constructor(editor: Editor) {
        super(editor);

        this.helpNoteSchemaService = new HelpNoteSchemaService();

        this.dataViewToModelConverter = new HelpNoteDataViewToModelConverterService();
        this.modelToDataViewConverter = new HelpNoteModelToDataViewConverterService();
        this.modelToEditorViewConverter = new HelpNoteModelToEditorViewConverterService();

        this.editionViewUtilsService = new HelpNoteEditionViewUtilsService();

        this.userInterfaceService = new UserInterfaceService();
    }

    public static get pluginToolbarElementName() {
        return HelpNotePlugin.TOOLBAR_ELEMENT_NAME;
    }

    protected defineSchema(): void {
        this.helpNoteSchemaService.defineSchema(this.schema);
    }

    protected defineConverters(): void {
        this.dataViewToModelConverter.configureConverter(this.conversion);
        this.modelToDataViewConverter.configureConverter(this.conversion);
        this.modelToEditorViewConverter.configureConverter(this.conversion);
    }

    protected editorInteractions(): void {
        this.defineObservers();
        this.balloon = this.editor.plugins.get(ContextualBalloon);
        const editor = this.editor;
        const viewDocument = editor.editing.view.document;
        this.listenTo<ViewDocumentClickEvent>(viewDocument, 'click', this.clickEventsHandler.bind(this));
        this.listenTo<ViewDocumentDoubleClickEvent>(viewDocument, 'dblclick', this.clickEventsHandler.bind(this), { priority: 'high' });
        this.editor.plugins.get('ClipboardPipeline').on('inputTransformation', this.handlePasteEvent.bind(this), { priority: 'high' });
        this.disableToolbarButton(HelpNotePlugin.ADD_EDIT_COMMAND_NAME);
    }

    protected override toolbarExecuteOperation(): void {
        const helpNoteModel: HelpNoteModel = { id: '', content: '' };
        this.showHelpNoteDialog(helpNoteModel);
    }

    protected override getPluginDataViewClass(): string {
        return HelpNotePlugin.MODEL_ENTITIES.cite.dataView;
    }

    protected override processElementsPaste(elements: Node[]): void {
        const view = this.editor.editing.view;

        view.change(writer => {
            elements.forEach(element => {
                if (element instanceof ViewElement) {
                    writer.setAttribute(GlobalConstant.ATTRIBUTE_ID, this.pluginUtils.generateId(this.ID_HELP_NOTE_PREFIX), element);
                }
            });
        });
    }

    private defineObservers(): void {
        this.editor.editing.view.addObserver(ClickObserver);
        this.editor.editing.view.addObserver(DoubleClickObserver);
    }

    private clickEventsHandler(evt: EventInfo, data: DomEventData): void {
        if (this.isReadOnlyOrRestrictedMode()) {
            return;
        }

        const parentElement = this.pluginUtils.getSelectedContainerWithClass(this.editor, HelpNotePlugin.MODEL_ENTITIES.container.editionView);

        if (!parentElement || !this.pluginUtils.isViewElementInteractableInTargetEditor(parentElement, HelpNotePlugin.contextEditor, ContextEditorTypes.CLAUSE)) {
            return;
        }

        const helpNoteModel = this.editionViewUtilsService.getModel(parentElement);

        if (evt.name == CkeditorConstant.CKEDITOR_EVENTS.click) {
            this.showHelpNoteBalloon(helpNoteModel);
        }

        if (evt.name == CkeditorConstant.CKEDITOR_EVENTS.doubleClick) {
            this.showEditHelpNoteDialog(helpNoteModel);
        }

        data.preventDefault();
        data.stopPropagation();
        evt.stop();
        evt.return = true;
    }

    private showHelpNoteBalloon(helpNoteModel?: HelpNoteModel, forceVisible: boolean = false): void {
        if (!this.helpNoteBalloonView) {
            this.addHelpNoteBalloon(helpNoteModel);
        }

        const helpNoteElement = this.pluginUtils.getSelectedElementWithClass(this.editor, HelpNotePlugin.MODEL_ENTITIES.container.editionView);

        if (!helpNoteElement) {
            this.pluginUtils.showFakeVisualSelection(this.editor, HelpNotePlugin.VISUAL_SELECTION_MARKER_NAME);
        }

        this.addHelpNoteBalloon(helpNoteModel);

        if (forceVisible) {
            this.balloon.showStack("main");
        }

        this.startUpdatingUI();
    }

    private showHelpNoteDialog(helpNoteModel?: HelpNoteModel): void {
        if (!this.helpNoteDialogFormView) {
            this.helpNoteDialogFormView = this.createHelpNoteDialog(helpNoteModel);
        }

        this.helpNoteDialogFormView!.resetFormStatus();
        this.helpNoteDialogFormView.helpNoteModel = helpNoteModel!;

        const cancelButtonView = this.helpNoteDialogFormView.cancelButtonView;
        const submitButtonView = this.helpNoteDialogFormView.submitButtonView;
        const actionsButtons = [
            {
                label: cancelButtonView.label,
                class: cancelButtonView.class,
                withText: cancelButtonView.withText,
                onExecute: () => this.cancelOperationHandler()
            },
            {
                label: submitButtonView.label,
                class: submitButtonView.class,
                withText: submitButtonView.withText,
                onExecute: () => this.addEditHelpNoteHandler()
            }
        ];

        const dialog = this.editor.plugins.get("Dialog");
        dialog.show({
            isModal: true,
            content: this.helpNoteDialogFormView,
            title: this.insertHelpNoteMessage,
            onHide() { },
            id: "",
            actionButtons: actionsButtons
        });
    }

    private createHelpNoteDialog(helpNoteModel?: HelpNoteModel): HelpNoteDialogFormView {
        const editor = this.editor;

        if (!this.helpNoteDialogFormView) {
            this.helpNoteDialogFormView = new HelpNoteDialogFormView(editor.locale, helpNoteModel);
        } else {
            this.helpNoteDialogFormView.resetFormStatus();
        }

        return this.helpNoteDialogFormView;
    }

    private addEditHelpNoteHandler(): void {
        if (!this.helpNoteDialogFormView.isValid()) {
            return;
        }

        const helpNoteModel: HelpNoteModel = this.helpNoteDialogFormView.getHelpNoteModel();
        this.editor.execute(HelpNotePlugin.ADD_EDIT_COMMAND_NAME, helpNoteModel);

        const dialog = this.editor.plugins.get("Dialog");
        this.hideUI();
        this.closeDialog(dialog);
    }

    private cancelOperationHandler(): void {
        const dialog = this.editor.plugins.get("Dialog");
        this.hideUI();
        this.closeDialog(dialog);
    }

    private showEditHelpNoteDialog(helpNoteModel?: HelpNoteModel, forceVisible: boolean = false): void {
        this.removeHelpNoteBalloon();

        const helpNoteElement = this.pluginUtils.getSelectedElementWithClass(this.editor, HelpNotePlugin.MODEL_ENTITIES.container.editionView);
        if (!helpNoteElement) {
            this.pluginUtils.showFakeVisualSelection(this.editor, HelpNotePlugin.VISUAL_SELECTION_MARKER_NAME);
        }

        this.showHelpNoteDialog(helpNoteModel);

        if (forceVisible) {
            this.balloon.showStack('main');
        }

        this.startUpdatingUI();
    }

    private addHelpNoteBalloon(helpNoteModel?: HelpNoteModel): void {
        if (!this.helpNoteBalloonView) {
            this.createHelpNoteBalloon();
        }

        if (this.userInterfaceService.isBalloonInPanel(this.balloon, this.helpNoteBalloonView)) {
            const isSameHelpNoteEditing = this.helpNoteBalloonView.id === helpNoteModel?.id;
            if (!isSameHelpNoteEditing) {
                this.helpNoteBalloonView.helpNoteModel = helpNoteModel!;
            }
            return;
        }

        this.helpNoteBalloonView!.resetFormStatus();
        this.helpNoteBalloonView.helpNoteModel = helpNoteModel!;

        this.balloon.add({
            view: this.helpNoteBalloonView!,
            position: this.pluginUtils.getBalloonPositionData(this.editor, HelpNotePlugin.MODEL_ENTITIES.container.editionView, HelpNotePlugin.VISUAL_SELECTION_MARKER_NAME)
        });
    }

    private createHelpNoteBalloon(): void {
        const editor = this.editor;
        this.helpNoteBalloonView = new HelpNoteBalloonView(editor.locale);

        this.helpNoteBalloonView.editButtonView.on('execute', () => {
            this.showEditHelpNoteDialog(this.helpNoteBalloonView.helpNoteModelToEdit);
        });

        this.helpNoteBalloonView.deleteButtonView.on('execute', () => {
            editor.execute(HelpNotePlugin.DELETE_COMMAND_NAME, this.helpNoteBalloonView.id);
            this.hideUI();
        });

        this.userInterfaceService.enableUserBalloonInteractions(this.editor, this.balloon, this.helpNoteBalloonView, HelpNotePlugin.VISUAL_SELECTION_MARKER_NAME, this);
    }

    private startUpdatingUI(): void {
        const editor = this.editor;

        let previousSelectedHelpNote = this.pluginUtils.getSelectedElementWithClass(editor, HelpNotePlugin.MODEL_ENTITIES.container.editionView);
        let previousSelectionParent = this.getSelectionParent();

        const update = () => {
            const selectedSignature = this.pluginUtils.getSelectedElementWithClass(editor, HelpNotePlugin.MODEL_ENTITIES.container.editionView);
            const selectionParent = this.getSelectionParent();


            if ((previousSelectedHelpNote && !selectedSignature) ||
                (!previousSelectedHelpNote && selectionParent !== previousSelectionParent)) {
                this.hideUI();
            } else if (this.isUIVisible()) {
                this.balloon.updatePosition(this.pluginUtils.getBalloonPositionData(this.editor, HelpNotePlugin.MODEL_ENTITIES.container.editionView, HelpNotePlugin.VISUAL_SELECTION_MARKER_NAME));
            }

            previousSelectedHelpNote = selectedSignature;
            previousSelectionParent = selectionParent;
        };

        this.listenTo(editor.ui, 'update', update);
        this.listenTo(this.balloon, 'change:visibleView', update);
    }

    private hideUI(): void {
        if (!this.isUIInPanel()) {
            return;
        }

        const editor = this.editor;
        this.userInterfaceService.removeBalloonObservers(this.editor, this.balloon, this);

        editor.editing.view.focus();

        this.removeHelpNoteBalloon();
        this.removeHelpNoteDialog();

        this.pluginUtils.hideFakeVisualSelection(this.editor, HelpNotePlugin.VISUAL_SELECTION_MARKER_NAME);
    }

    private removeHelpNoteDialog(): void {
        this.helpNoteDialogFormView?.resetFormStatus();
        this.editor.editing.view.focus();
    }

    private removeHelpNoteBalloon(): void {
        if (!this.userInterfaceService.isBalloonInPanel(this.balloon, this.helpNoteBalloonView)) {
            return;
        }

        this.balloon.remove(this.helpNoteBalloonView!);

        this.editor.editing.view.focus();
        this.pluginUtils.hideFakeVisualSelection(this.editor, HelpNotePlugin.VISUAL_SELECTION_MARKER_NAME);
    }

    private isUIInPanel(): boolean {
        return this.userInterfaceService.isBalloonInPanel(this.balloon, this.helpNoteBalloonView) ||
            this.userInterfaceService.isBalloonInPanel(this.balloon, this.helpNoteDialogFormView);
    }

    private isUIVisible(): boolean {
        return this.userInterfaceService.areActionsVisible(this.balloon, this.helpNoteDialogFormView) ||
            this.userInterfaceService.areActionsVisible(this.balloon, this.helpNoteBalloonView);
    }

    private closeDialog(dialog: Dialog): void {
        dialog.hide();
    }

    private generateId(currentId: string | null | undefined): string {
        return !!currentId ? currentId : `${this.ID_HELP_NOTE_PREFIX}${crypto.randomUUID()}`;
    }
}
