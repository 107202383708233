import { Component, Inject, Input, OnInit } from '@angular/core';
import { PublicLinksService } from 'src/app/core/shared/services/public-links/public-links.service';
import { CustomizeConfiguration } from 'src/config/customisation';
import { IItem } from 'src/app/shared/components/ctbox-generic-list/models';
import { UserInfoDTO } from 'src/app/api';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'ctbox-header',
    templateUrl: './ctbox-header.component.html',
    styleUrls: ['./ctbox-header.component.scss']
})

export class CtboxHeaderComponent implements OnInit {

    @Input() currentScreenSize: string;
    @Input() menuItems: IItem[];
    @Input() hasTrainingButton = true;
    @Input() hasLegalAlertsButton = false;
    @Input() hasChatButton = false;
    @Input() hasProductButton = true;
    @Input() showLoginButton = true;
    @Input() showButtons = true;
    @Input() condition: UserInfoDTO;
    @Input() hasBrand = false;

    public showAccessButton = true;
    public isLandingPage: boolean;
    public customize: string;
    public customizeIdName: string;
    public customizeProductName: string;
    public customizeclassProduct: string;
    public legalAlertsLink: string;

    public constructor(
        public publicLinks: PublicLinksService,
        private readonly customisation: CustomizeConfiguration
    ) {
        this.isLandingPage = this.publicLinks.isLandingPage();
        this.customizeIdName = this.customisation.customizeIdName;
        this.customizeProductName = this.customisation.customizeProductName;
        this.customizeclassProduct = this.customisation.customizeClassProduct;
    }

    public ngOnInit(): void {
        this.isLandingPage = this.publicLinks.isLandingPage();
        this.legalAlertsLink = this.publicLinks.legalAlertsStandard;
        if (this.customizeclassProduct === 'cmc-santander') {
            this.showAccessButton = false;
            this.legalAlertsLink = this.publicLinks.legalAlertsCmc;
        }
        this.hasChatButton = environment.showChatButton;
        this.hasBrand = environment.showBrand;
    }

    public goToLink() {
        if (this.isLandingPage) {
            this.publicLinks.goToLink(this.publicLinks.brandUrl);
        } else {
            this.publicLinks.goToHome();
        }
    }

    public openChat() {
        window.open(this.publicLinks.chatUrl);
      }
}
