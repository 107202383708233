export const branding = {
    brandName: 'LA LEY',
    brandNameCopyRight: 'LA LEY SOLUCIONES LEGALES, S.A',
    brandUrl: 'https://www.laley.es/',
    brandPhone: '91 602 01 82',
    brandContactClientMail: "clienteslaley@aranzadilaley.es",
    fundationUrl: 'https://www.aranzadilaley.es/fundacion/presentacion',
    trainingUrl: 'https://www.laley.es/formacion/index',
    smartecaUrl: 'http://www.laley.es/productos/plataformas-digitales/smarteca',
    advertisingUrl: 'http://www.laley.es/',
    mainOfficeUrl: 'https://www.laley.es/contacto/atencion-al-cliente',
    legalNoticeUrl: 'https://www.laley.es/lopd/avisos/aviso-legal',
    privacyNoticeUrl: 'https://www.laley.es/lopd/avisos/aviso-privacidad',
    cookiesNoticeUrl: 'https://www.laley.es/lopd/avisos/aviso-cookies',
    qualityEnviromentUrl: 'https://www.laley.es/lopd/avisos/calidad-y-medioambiente',
    shopUrl: 'https://tienda.wolterskluwer.es/',
    facebookUrl: 'https://www.facebook.com/Laleynext',
    twitterUrl: 'https://twitter.com/LaLeyNext',
    linkedinUrl: 'https://www.linkedin.com/showcase/laleynext',
    youtubeUrl: 'https://www.youtube.com/c/LALEYnext',
    aenorUrl: 'https://www.aenor.com',
    masfamiliaUrl: 'http://www.masfamilia.org',
    empleoygarantiajuvenilUrl: 'https://empleoygarantiajuvenil.camara.es/jovenes',
    trainingCatalogUrl: 'https://attendee.gototraining.com/68fc0/catalog/2440422545415966976',
    chat: 'https://areacliente.thomsonreuters.es/soportepremium',
    downloadFAQ: 'assets/pdf/FAQs_contractBOX_05.pdf'

}
