import { Injectable } from '@angular/core';
import { Conversion, Element, Text, UpcastConversionApi, ViewElement, Writer } from 'ckeditor5';
import { GlobalConstant } from '../../models/base/global-constant';
import { HelpNoteModel } from '../../models/help-note/help-note-model';
import { HelpNotePlugin } from '../../plugins/help-note/help-note-plugin';

@Injectable({
    providedIn: 'root'
})
export class HelpNoteDataViewToModelConverterService {

    private textChildPosition = 0;

    public configureConverter(conversion: Conversion): void {
        conversion.for("upcast").elementToElement({
            view: {
                name: GlobalConstant.LABEL_CITE,
                classes: [HelpNotePlugin.MODEL_ENTITIES.cite.dataView],
            },
            model: (viewElement: ViewElement, conversionApi: UpcastConversionApi) => {
                const modelHelpNote = this.getModel(viewElement);
                const modelElement = this.createModelElement(modelHelpNote, conversionApi.writer);
                return modelElement;
            },
        });
    }

    public getModel(viewElement: ViewElement): HelpNoteModel {
        const getAttribute = (attr: string) => viewElement.getAttribute(attr) ?? '';
        const content = viewElement.childCount > this.textChildPosition ? (viewElement.getChild(this.textChildPosition) as unknown as Text).data : '';
        const embeddedIn = viewElement.getAttribute(GlobalConstant.ATTRIBUTE_EMBEDDED_IN) ? viewElement.getAttribute(GlobalConstant.ATTRIBUTE_EMBEDDED_IN) : "undefined";
        return {
            id: getAttribute(GlobalConstant.ATTRIBUTE_ID),
            content: content,
            embeddedIn: embeddedIn
        }
    }

    private createModelElement(model: HelpNoteModel, writer: Writer): Element {
        return writer.createElement(HelpNotePlugin.MODEL_ENTITIES.container.model, {
            [GlobalConstant.ATTRIBUTE_ID]: model.id ? model.id: '',
            [HelpNotePlugin.MODEL_ENTITIES.content.model]: model.content ? model.content : '',
            [GlobalConstant.ATTRIBUTE_EMBEDDED_IN]: model.embeddedIn ? model.embeddedIn : "undefined"
        });
    }
}
