import { Injectable } from '@angular/core';
import { Editor, toWidget, Conversion, DowncastWriter, Element } from 'ckeditor5';
import { SignaturePlugin } from '../../plugins/signature/signature-plugin';
import { SignatureUtilsService } from '../../utils/signature/signature-utils.service';

@Injectable({
    providedIn: 'root'
})
export class SignatureModelToEditorViewConverterService {

    private utilsService: SignatureUtilsService;

    constructor() {
        this.utilsService = new SignatureUtilsService();
    }

    public configureConverters(editor: Editor) {
        const conversion = editor.conversion;
        this.signatureStructureConversion(editor);
        this.signatureRoleAttributeConversion(conversion)
    }

    private signatureStructureConversion(editor: Editor): void {
        const conversion = editor.conversion;
        conversion.for("editingDowncast").elementToStructure({
            model: SignaturePlugin.MODEL_ENTITIES.class.dataView,
            view: (modelItem: Element, { writer: viewWriter }) => {
                const widgetElement = this.utilsService.createSignatureView(editor, modelItem, viewWriter);
                return toWidget(widgetElement, viewWriter);
            },
        });
    }

    private signatureRoleAttributeConversion(conversion: Conversion): void {
        conversion.for('downcast').attributeToAttribute({
             model: SignaturePlugin.MODEL_ENTITIES.attributeRoleIsStored.model,
             view: SignaturePlugin.MODEL_ENTITIES.attributeRoleIsStored.editionView
        });
    }
}
