import { Directive, Inject } from "@angular/core";
import BaseView from "../base-view.directive";
import { ButtonView, Locale } from "ckeditor5";
import { HelpNoteModel } from "../../models/help-note/help-note-model";
import { UI_CLASSES } from "../styles/styles-constants";

@Directive()
export default class HelpNoteBalloonView extends BaseView {

    public editButtonView: ButtonView;

    public helpNoteModelToEdit: HelpNoteModel = {
        id: ''
    };

    private DELETE_OPTION = $localize`:@@PluginHelpNoteBalloonBotonBorrar:BORRAR`;
    private EDIT_OPTION = $localize`:@@PluginHelpNoteBalloonBotonEditar:EDITAR`;

    constructor(@Inject(Locale) locale?: Locale) {
        super(locale);

        this.editButtonView = this.createButton(this.EDIT_OPTION, null, UI_CLASSES.SVG_ICONS.EDIT);
        this.deleteButtonView = this.createButton(this.DELETE_OPTION, null, UI_CLASSES.SVG_ICONS.DELETE);

        this.items = this.createCollection([this.editButtonView, this.deleteButtonView]);

        this.setTemplateForm("ck-help-note-balloon");
    }

    public ngOnDestroy(): void {
        super.destroy();

        this.focusTracker.destroy();
        this.keystrokes.destroy();
    }

    public get id(): string {
        return this.helpNoteModelToEdit.id!;
    }

    public set helpNoteModel(value: HelpNoteModel) {
        this.helpNoteModelToEdit = value;
    }

    public resetFormStatus(): void {
        this.helpNoteModelToEdit = {
            id: ''
        };
    }
}