<div class="viewer">
    <div class="viewer-header comparation">
        <div class="viewer-info size-50" *ngIf="isPending(templateDocument)">
            <div class="viewer-title">
                <ctbox-button buttonIcon="info" buttonColor="info" [matTooltip]="infoTooltip"></ctbox-button>
                <div class="viewer-title-label" i18n="@@DocumentoPlantillaEditadaTitulo">Plantilla origen:</div>
                <div class="viewer-title-name">{{templateName}}</div>
            </div>
        </div>
        <div class="viewer-info" [ngClass]="{'size-50' : isPending(templateDocument)}">
            <div class="viewer-title" [ngClass]="{'pagination' : fromTemplateDocument}">
                <div class="viewer-title-label">{{templateDocumentType}}</div>
                <div class="viewer-title-name">{{templateDocumentName}}</div>
            </div>
            <div class="viewer-pagination" *ngIf="fromTemplateDocument">
                <ctbox-button buttonColor="secondary" buttonIcon="keyboard_double_arrow_left" (buttonClick)="navegateFirstDocument()"></ctbox-button>
                <ctbox-button buttonColor="secondary" buttonIcon="keyboard_arrow_left" (buttonClick)="navegatePreviousDocument()"></ctbox-button>
                <span class="viewer-pagination-number">{{indexDocument + 1}}/{{totalDocuments}}</span>
                <ctbox-button buttonColor="secondary" buttonIcon="keyboard_arrow_right" (buttonClick)="navegateNextDocument()"></ctbox-button>
                <ctbox-button buttonColor="secondary" buttonIcon="keyboard_double_arrow_right" (buttonClick)="navegateLastDocument()"></ctbox-button>
            </div>
        </div>
    </div>
    <div class="viewer-body comparation header">
        <div class="viewer-html wk-document size-50" *ngIf="isPending(templateDocument)">
            <app-html-comparer *ngIf="showComparer()" #templatesComparer [currentHtml]="templateOldVersion" [newHtml]="templateNewVersion" [alreadyInComparation]="false" [addScrollSm]="true" [replaceFilledInput]="false"></app-html-comparer>
            <app-html-viewer *ngIf="!showComparer()" #comparedViewer [html]="templateNewVersion" [addScrollSm]="true" [replaceFilledInput]="false"></app-html-viewer>
        </div>
        <div class="viewer-html" [ngClass]="{'size-50 ' : isPending(templateDocument)}">
            <ng-container *ngIf="isPending(templateDocument)">
                <app-wysiwyg-editor #editor class="wysiwyg wysiwyg-main" [dataEditor]="documentModified" [permissionEditor]="permissionConfig" [pluginConfiguration]="editorPluginConfig" [defaultPluginToRemove]="pluginsDisabled" [resetHasUnsavedData]="resetUnsavedChangesNotifier"
                    [isReadOnly]="isReadOnly" (editorReady)="editorReady()">
                </app-wysiwyg-editor>
            </ng-container>
            <ng-container *ngIf="!isPending(templateDocument)">
                <app-html-viewer #documentViewer className="overflow" [html]="documentModified" [showAsReadOnly]="true" class="viewer-editor"></app-html-viewer>
            </ng-container>
        </div>
    </div>
    <div class="viewer-footer">
        <div class="viewer-actions" *ngIf="isPending(templateDocument)">
            <ctbox-button buttonType="primary" buttonText="Finalizar cambios" i18n-buttonText="@@FinalizarCambios" [isDisabled]="!templateDocument?.document?.userPermissions?.canReviewTemplateChanges || !templateDocument?.document?.userPermissions?.canSave"
                (buttonClick)="acceptChanges()"></ctbox-button>
            <ctbox-button buttonType="primary" buttonColor="accent" buttonText="Guardar" i18n-buttonText="@@Guardar" [isDisabled]="!templateDocument?.document?.userPermissions?.canSave" (buttonClick)="save()"></ctbox-button>
        </div>
    </div>
</div>
