import { HttpHeaders } from '@angular/common/http';
import { ApiOperation, ApiProcessorService, ApiRequest } from './api-processor.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class SuggestService {

    private basePath = '/suggest';

    constructor(private readonly apiProcessorService: ApiProcessorService) {
    }

    public getSuggestions(query: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;

        const headers = new HttpHeaders({
            'Show-Spinner': 'false'
          });

          apiRequest.headers = headers;

        const params = '?query=' + query;
        apiRequest.endpointUrl = `${this.basePath}` + params;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }
}
