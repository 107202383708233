import { BaseSelectCommand } from "./base-select-command";
import { SelectModel } from "../../models/select/select-model";
import { InputPlugin } from "../../plugins/input/input-plugin";

export default class AddSelectCommand extends BaseSelectCommand {

    public override execute(model: SelectModel): void {
        const editor = this.editor;

        this.setModelId(model);

        editor.model.change(writer => {
            const elementModel = this.dataUtilsService.createSelectCompleteStructureModelWithOptions(writer, model);
            editor.model.insertObject( elementModel , null, null, {setSelection: 'after'} );
        } );
    }

    public override refresh(): void {
        super.refresh();

        if (!this.isEnabled) {
            this.disableCommandIfCursorInside(InputPlugin.MODEL_ENTITIES.content.model);
        }
    }
}
