import { Directive, Inject } from "@angular/core";
import BaseView from '../base-view.directive';
import { ButtonView, createLabeledTextarea, FocusTracker, LabeledFieldView, Locale, TextareaView } from "ckeditor5";
import { HelpNoteModel } from "../../models/help-note/help-note-model";
import FormRowView from "../../../utils/form-row-view.directive";
import { UI_CLASSES } from "../styles/styles-constants";

@Directive()
export default class HelpNoteDialogFormView extends BaseView {

    public textareaView: LabeledFieldView<TextareaView>;
    public cancelButtonView: ButtonView;
    public submitButtonView: ButtonView;

    public readonly focusTracker = new FocusTracker();

    private readonly labelHelpNote = $localize`:@@PluginHelpNoteEtiquetaTextoAyuda:Texto de la nota ayuda`;
    private readonly emptyMessageHelpNote = $localize`:@@PluginHelpNoteTextoNoVacio:El texto de la nota de ayuda no puede estar vacío.`;

    private readonly ID_HELP_NOTE_PREFIX = 'ck-help-note-';

    private validators: Array<HelpNoteFormValidatorCallback>;

    private helpNoteModelToEdit: HelpNoteModel = {
        id: ''
    };
    private isEditing = false;

    constructor(
        @Inject(Locale) locale?: Locale,
        @Inject(HelpNoteModel) defaultHelpNoteModel?: HelpNoteModel
    ) {
        super(locale);

        this.initializeHelpNoteModel(defaultHelpNoteModel);
        this.initializeValidators();
        this.initializeViews();
        this.initializeRows(locale);

        this.setTemplateForm("ck-dialog__form ck-dialog__form-help-note");
    }

    public get id(): string {
        return this.helpNoteModelToEdit.id!;
    }

    public get content(): string {
        return this.helpNoteModelToEdit.content!;
    }

    public set helpNoteModel(value: HelpNoteModel) {
        if (!value) {
            return;
        }

        this.helpNoteModelToEdit =  JSON.parse(JSON.stringify(value));
        this.isEditing = !!this.helpNoteModelToEdit.id;
        this.textareaView.fieldView.element.value = this.helpNoteModelToEdit.content;
    }

    public getHelpNoteModel(): HelpNoteModel {
        if (this.isInCreation()) {
            this.helpNoteModelToEdit.id = this.generateId(this.helpNoteModelToEdit.id);
        }
        this.helpNoteModelToEdit.content = this.textareaView.fieldView.element.value;

        return this.helpNoteModelToEdit;
    }

    public isValid(): boolean {
        this.textareaView.errorText = null;

        for (const validator of this.validators) {
            const errorText = validator(this);

            if (errorText) {
                this.textareaView.errorText = errorText;
                return false;
            }
        }

        return true;
    }

    public resetFormStatus(): void {
        this.helpNoteModelToEdit.content = '';
        this.helpNoteModelToEdit.id = '';

        this.textareaView.fieldView.element.value = '';
        this.textareaView.errorText = null;

        this.isEditing = false;
    }

    public override focus(): void {
        if (this.helpNoteModelToEdit.content !== "") {
            this.textareaView.focus();
        }
        const helpNoteElement = this.textareaView.fieldView.element as HTMLTextAreaElement;
        this.moveToFirstPositionElement(helpNoteElement);
    }

    public generateId(currentId: string | null | undefined): string {
        return !!currentId ? currentId : `${this.ID_HELP_NOTE_PREFIX}${crypto.randomUUID()}`;
    }

    private isInCreation(): boolean {
        return !this.isEditing;
    }

    private moveToFirstPositionElement(element: HTMLTextAreaElement): void {
        if (element) {
            element.setSelectionRange(0, 0);
        }
    }

    private initializeHelpNoteModel(defaultHelpNoteModel: HelpNoteModel): void {
        if (!!defaultHelpNoteModel) {
            this.helpNoteModelToEdit = JSON.parse(JSON.stringify(defaultHelpNoteModel));
        }
        this.isEditing = !!this.helpNoteModelToEdit.id;
    }

    private initializeValidators(): void {
        this.validators = [
            form => {
                if (form.textareaView.fieldView.element.value === '') {
                    return this.emptyMessageHelpNote;
                }

                return '';
            }
        ];
    }

    private initializeViews(): void {
        this.textareaView = this.createValueTextarea(this.labelHelpNote, this.helpNoteModelToEdit?.content);
        this.submitButtonView = this.createButton(this.submitButtonMessage, `ck-button-save ${UI_CLASSES.BUTTONS.PLUGIN}`);
        this.submitButtonView.type = 'submit';
        this.cancelButtonView = this.createButton(this.cancelButtonMessage, `ck-button-cancel ${UI_CLASSES.BUTTONS.PLUGIN}`);
    }

    private initializeRows(locale: Locale): void {

        const rowHelpText = new FormRowView(locale, {
            labelView: undefined,
            children: [
                this.textareaView,
            ],
            class: 'label-help-note-text'
        });

        this.items = this.createCollection([
            rowHelpText
        ]);
    }

    private createValueTextarea(label: string, defaultValue?: string): LabeledFieldView<TextareaView> {
        const labeledTextarea = new LabeledFieldView(
            this.locale,
            createLabeledTextarea
        );

        labeledTextarea.label = label;
        if (defaultValue) {
            labeledTextarea.fieldView.value = defaultValue;
        }

        return labeledTextarea;
    }
}

export type HelpNoteFormValidatorCallback = (form: HelpNoteDialogFormView) => string | undefined;
