import { SignatureModel } from '../../models/signature/signature-model';
import { BaseCommand } from '../base-command';
import { SignaturePlugin } from '../../plugins/signature/signature-plugin';
import { InputPlugin } from '../../plugins/input/input-plugin';
import { Writer, Element } from 'ckeditor5';
import { GlobalConstant } from '../../models/base/global-constant';

export default class EditSignatureCommand extends BaseCommand {

    public labelDataModel = SignaturePlugin.MODEL_ENTITIES.class.dataView;

    public override execute(signatureModel: SignatureModel): void {
        if (!signatureModel.id) {
            return;
        }

        const editor = this.editor;

        editor.model.change( (writer: Writer) => {
            const range = writer.createRangeIn(this.editor.model.document.getRoot()!);
            const oldSignatureElement = this.getElementWithId(range, signatureModel.id!)?.item as Element;
            if (oldSignatureElement) {
                writer.setAttribute(SignaturePlugin.MODEL_ENTITIES.attributeRoleIsStored.model, signatureModel.role, oldSignatureElement);
            }

             const signature = writer.createElement('signature', {
                [SignaturePlugin.MODEL_ENTITIES.attributeRoleIsStored.model]: signatureModel.role,
                [GlobalConstant.ATTRIBUTE_ID]: signatureModel.id
            });

            writer.insert( signature, oldSignatureElement,'after' );
            writer.remove(oldSignatureElement);
        });
    }

    public override refresh(): void {
        super.refresh();

        if (!this.isEnabled) {
            this.disableCommandIfCursorInside(InputPlugin.MODEL_ENTITIES.content.model);
        }
    }
}
