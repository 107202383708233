import { Injectable } from '@angular/core';
import { DowncastWriter, ViewContainerElement, ViewEditableElement, Element } from 'ckeditor5';
import { GlobalConstant } from '../../models/base/global-constant';
import { RepeatableFragmentPlugin } from '../../plugins/repeatable-fragment/repeatable-fragment-plugin';
import { UI_CLASSES } from '../../ui/styles/styles-constants';
import { BasePlugin } from '../../plugins/base/base-plugin';

@Injectable({
    providedIn: 'root'
})
export class RepeatableFragmentEditionViewUtilsService {

    constructor() { }

    public createRepeatableFragmentEditorView(modelItem: Element, viewWriter: DowncastWriter): ViewContainerElement {
        const fragmentCount = modelItem.getAttribute(RepeatableFragmentPlugin.MODEL_ENTITIES.fragmentCount.model);
        const isValid = modelItem.getAttribute(GlobalConstant.ATTRIBUTE_IS_VALID)?.toString();
        const id = modelItem.getAttribute(GlobalConstant.ATTRIBUTE_ID);

        const isValidClass = isValid === undefined || isValid === "true" ? RepeatableFragmentPlugin.ATTRIBUTE_REPEATABLE_FRAGMENT_IS_VALID_CLASS_EDITION_VIEW : RepeatableFragmentPlugin.ATTRIBUTE_REPEATABLE_FRAGMENT_IS_NOT_VALID_CLASS_EDITION_VIEW;

        const containerElement = viewWriter.createContainerElement(
            GlobalConstant.LABEL_DIV,
            {
                id,
                [RepeatableFragmentPlugin.MODEL_ENTITIES.fragmentCount.editionView] : fragmentCount,
                [GlobalConstant.ATTRIBUTE_IS_VALID] : isValid
            }
        );
        viewWriter.addClass([RepeatableFragmentPlugin.MODEL_ENTITIES.container.editionView, isValidClass, GlobalConstant.CONTAINER_CLASS_EDITION_VIEW], containerElement);
        return containerElement;
    }

    public createActionContainerEditionView(viewWriter: DowncastWriter): ViewContainerElement {
        const containerElement = viewWriter.createContainerElement(GlobalConstant.LABEL_DIV);
        viewWriter.addClass([RepeatableFragmentPlugin.MODEL_ENTITIES.actionContainer.editionView, GlobalConstant.CONTAINER_CLASS_EDITION_VIEW], containerElement);
        return containerElement;
    }

    public createActionEditionView(modelItem: Element, viewWriter: DowncastWriter): ViewContainerElement {
        const dataType = modelItem.getAttribute(RepeatableFragmentPlugin.MODEL_ENTITIES.dataType.model);
        const isDisabled = modelItem.getAttribute('disabled');

        const containerElement = viewWriter.createContainerElement(GlobalConstant.IMG, {
            src: dataType === 'add' ? UI_CLASSES.ROOT_ICONS.PLUS_ICON : UI_CLASSES.ROOT_ICONS.MINUS_ICON,
            class: [dataType === 'add' ? RepeatableFragmentPlugin.MODEL_ENTITIES.plusButton.editionView : RepeatableFragmentPlugin.MODEL_ENTITIES.minusButton.editionView]
        });

        if ((isDisabled === 'true') || this.isTemplateOrClauseContext()) {
            viewWriter.addClass('disabled', containerElement);
        } else {
            viewWriter.removeClass('disabled', containerElement);
        }

        return containerElement;
    }

    public createDescriptionActionEditionView(viewWriter: DowncastWriter): ViewContainerElement {
        return  viewWriter.createContainerElement(GlobalConstant.SPAN, { class: [RepeatableFragmentPlugin.MODEL_ENTITIES.descriptionAction.editionView] });
    }

    public createFragmentEditionView(viewWriter: DowncastWriter): ViewContainerElement {
        return viewWriter.createContainerElement(GlobalConstant.LABEL_DIV,
            {
                class: [RepeatableFragmentPlugin.MODEL_ENTITIES.fragment.editionView]
            });
    }

    public createContentElementEditionView(modelItem: Element, viewWriter: DowncastWriter): ViewEditableElement {
        const dataPosition = modelItem.getAttribute(RepeatableFragmentPlugin.MODEL_ENTITIES.fragmentPosition.model);

        return viewWriter.createEditableElement(GlobalConstant.LABEL_DIV,
            {
                class: [RepeatableFragmentPlugin.MODEL_ENTITIES.content.editionView],
                [RepeatableFragmentPlugin.MODEL_ENTITIES.fragmentPosition.model]: dataPosition
            });
    }

    public createDescriptionElementEditionView(viewWriter: DowncastWriter): ViewEditableElement {
        return viewWriter.createEditableElement(GlobalConstant.LABEL_DIV,
            {
                class: [RepeatableFragmentPlugin.MODEL_ENTITIES.description.editionView]
            });
    }

    private isTemplateOrClauseContext(): boolean {
        return BasePlugin.contextEditor === GlobalConstant.TEMPLATE || BasePlugin.contextEditor === GlobalConstant.CLAUSE;
    }
}
