import { GlobalConstant } from './../../models/base/global-constant';
import { Editor, Writer, Element } from 'ckeditor5';
import { PluginUtilsService } from '../../../utils/plugin-utils.service';
import { RepeatableFragmentCommand } from './repeatable-fragment-command';
import { RepeatableFragmentPlugin } from '../../plugins/repeatable-fragment/repeatable-fragment-plugin';
import { RepeatableFragmentElements } from '../../models/repeatable-fragment/repeatable-fragment-elements';
import { InputPlugin } from '../../plugins/input/input-plugin';
import { ElementPositionOffset } from '../../models/base/element-position-offset';

export default class AddRepeatableFragmentCommand extends RepeatableFragmentCommand {

    private readonly addParagraphsMessageDescriptionAction = $localize`:@@AñadirMasParrafosIguales:Añadir más párrafos iguales`;
    private readonly addParagraphsMessageDescription = $localize`:@@DescripcionPorDefecto:(Descripción)`;

    private readonly pluginUtils: PluginUtilsService;

    private CHILD_FRAGMENT_PARAGRAPH = 0;

    constructor(editor: Editor) {
        super(editor);
        this.pluginUtils = new PluginUtilsService();
    }

    public override execute(): void {
        const editor = this.editor;

        editor.model.change((writer: Writer) => {
            const { id, name } = this.generateIdentifiers();
            const elements = this.createElements(writer, id, name);
            this.appendElements(writer, elements);

            editor.model.insertObject(elements.container, null, null, { setSelection: 'after' });

            this.setCursorPointerOnElement(editor, writer, elements.contentElement.getChild(this.CHILD_FRAGMENT_PARAGRAPH) as Element, ElementPositionOffset.END);
        });
    }

    public override refresh(): void {
        super.refresh();

        if (!this.isEnabled) {
            this.disableCommandIfCursorInside(InputPlugin.MODEL_ENTITIES.content.model);
        }
    }

    private generateIdentifiers(): { id: string, name: string } {
        const guid = this.pluginUtils.generateUUID();
        const id = `${RepeatableFragmentPlugin.ID_REPEATABLE_FRAGMENT_PREFIX}${guid}`;
        const name = id;
        return { id, name };
    }

    private createElements(writer: Writer, id: string, name: string): RepeatableFragmentElements {
        const container = this.createContainer(writer, id, name);
        const deleteAction = this.createDeleteAction(writer, id, name);
        const addAction = this.createAddAction(writer, id, name);
        const descriptionAction = this.createDescriptionAction(writer, id, name);
        const fragmentGroup = this.createFragmentGroup(writer, id, name);
        const description = this.createDescription(writer);
        const contentElement = this.createContentElement(writer);
        const paragraphDescription = this.createParagraph(writer);
        const paragraphContent = this.createParagraph(writer);

        return {
            container,
            deleteAction,
            addAction,
            descriptionAction,
            fragmentGroup,
            description,
            contentElement,
            paragraphDescription,
            paragraphContent
        };
    }

    private appendElements(writer: Writer, elements: RepeatableFragmentElements): void {
        writer.append(elements.deleteAction, elements.container);
        writer.append(elements.addAction, elements.container);

        const descriptionActionText = writer.createText(this.addParagraphsMessageDescriptionAction);
        writer.append(descriptionActionText, elements.descriptionAction);

        writer.append(elements.descriptionAction, elements.container);

        const descriptionText = writer.createText(this.addParagraphsMessageDescription);
        writer.append(descriptionText, elements.paragraphDescription);

        writer.append(elements.paragraphDescription, elements.description);
        writer.append(elements.description, elements.fragmentGroup);

        const contentText = writer.createText('');
        writer.append(contentText, elements.paragraphContent);

        writer.append(elements.paragraphContent, elements.contentElement);
        writer.append(elements.contentElement, elements.fragmentGroup);

        writer.append(elements.fragmentGroup, elements.container);
    }

    private createContainer(writer: Writer, id: string, name: string): Element {
        return writer.createElement(RepeatableFragmentPlugin.MODEL_ENTITIES.container.model, {
            id,
            name,
            [GlobalConstant.ATTRIBUTE_EMBEDDED_IN]: undefined,
            [GlobalConstant.ATTRIBUTE_IS_VALID]: true,
            [RepeatableFragmentPlugin.MODEL_ENTITIES.fragmentCount.model]: 1
        });
    }

    private createDeleteAction(writer: Writer, id: string, name: string): Element {
        return writer.createElement(RepeatableFragmentPlugin.MODEL_ENTITIES.action.model, {
            id, name, [RepeatableFragmentPlugin.MODEL_ENTITIES.dataType.model]: GlobalConstant.DELETE, [GlobalConstant.ATTRIBUTE_DISABLED]: 'true'
        });
    }

    private createAddAction(writer: Writer, id: string, name: string): Element {
        return writer.createElement(RepeatableFragmentPlugin.MODEL_ENTITIES.action.model, {
            id, name, [RepeatableFragmentPlugin.MODEL_ENTITIES.dataType.model]: GlobalConstant.ADD
        });
    }

    private createDescriptionAction(writer: Writer, id: string, name: string): Element {
        return writer.createElement(RepeatableFragmentPlugin.MODEL_ENTITIES.descriptionAction.model, {
            id, name, [RepeatableFragmentPlugin.MODEL_ENTITIES.dataType.model]: GlobalConstant.DESCRIPTION
        });
    }

    private createFragmentGroup(writer: Writer, id: string, name: string): Element {
        return writer.createElement(RepeatableFragmentPlugin.MODEL_ENTITIES.fragment.model, { id, name });
    }

    private createDescription(writer: Writer): Element {
        return writer.createElement(RepeatableFragmentPlugin.MODEL_ENTITIES.description.model);
    }

    private createContentElement(writer: Writer): Element {
        return writer.createElement(RepeatableFragmentPlugin.MODEL_ENTITIES.content.model, {
            [RepeatableFragmentPlugin.MODEL_ENTITIES.fragmentPosition.model]: 1
        });
    }

    private createParagraph(writer: Writer): Element {
        return writer.createElement(GlobalConstant.MODEL_PARAGRAPH);
    }
}
