<form [formGroup]="parentGroup" class="suggest">
    <div class="suggest-content">
        <mat-form-field class="suggest-input">
            <input
                #formInput
                matInput
                formControlName="inputSuggest"
                i18n-placeholder="@@SuggestPlaceholder"
                placeholder="Introduce y selecciona la normativa que deseas alertar. Ej: art. 22 del Código de Comercio"
                (keyup)="onKeyUp($event)">
        </mat-form-field>
        <div class="suggest-result">
            <ul *ngIf="anySuggestion()" class="suggest-result-list">
                <li *ngFor="let suggestion of suggestions" class="suggest-result-item">
                    <a (click)="onSuggestedLegalAlertClick(suggestion)" class="suggest-result-link">
                        {{ suggestion.title }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</form>
